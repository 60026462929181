import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Payload from '../../offer/configuration/model.payload';
import FourCornerDistributionMixin from '../configuration/mixin.fourCornerDistribution';



var PayloadObjectsHolder = function (parent, payloadType) {

    var self = this;

    //var accessoryType = config.ACCESSORY_TYPES.BODY;
    
    //#region Private members
    var _payloadObjects = [],
        _payloadType = payloadType === undefined || payloadType === '' ? config.PAYLOAD_TYPES.SIMPLE : payloadType,
        _parent = parent;
        
    //#endregion Private members

    //#region Getters and setters
    function getPayloadObjects() {
        return _payloadObjects;
    }

    function getSimplePayloadObject() {
        return _payloadObjects.filter(function (payloadItem) {
            return payloadItem.getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;
        })[0] || null;
    }

    function getDetailedPayloadItems() {
        return _payloadObjects.filter(function (payloadItem) {
            return payloadItem.getPayloadType() === config.PAYLOAD_TYPES.DETAILED;
        });
    }

    function setPayloadObjects(newValue) {
        _payloadObjects = newValue;
    }
    function add(payloadObject) {
        //item.Number = MyBase.Count + 1
        //AddHandler item.PayloadObjectChanged, AddressOf OnPayloadObjectChanged
        //AddHandler item.PayloadMassChanged, AddressOf OnPayloadMassChanged

        //MyBase.Add(item)

        //RaiseEvent PayloadObjectsChanged()
        //#unfinished, need to be sure this raiseevent and adding handlers stuff is catered for somewhere
        payloadObject.setParent(_parent);

        payloadObject.setNumber(_payloadObjects.length);
        _payloadObjects.push(payloadObject);
    }

    function remove(payloadObject) {
        /*
            item.Clear(False)
        MyBase.Remove(item)

        Dim i As Integer = 1

        For Each payloadObject As FileLoadingPayloadObject In Me

            Dim updateDescription As Boolean = False

            If payloadObject.Description = payloadObject.DefaultDescription Then
                updateDescription = True
            End If

            payloadObject.Number = i

            If updateDescription Then
                payloadObject.Description = payloadObject.DefaultDescription
            End If

            i += 1

        Next
        */
        
        var indexOfElementToRemove = _payloadObjects.map(function (item) { return item.getNumber(); }).indexOf(payloadObject.getNumber());
        //delete _payloadObjects[indexOfElementToRemove];
        _payloadObjects.splice(indexOfElementToRemove, 1);
        var i = 0;
        _payloadObjects.forEach(function (item) {
            item.setNumber(i);
            i++;
        });

    }

    function removePayloadById(payloadId) {

        var payload = getPayloadById(payloadId);
        if (payload !== null) {
            remove(payload);
        }

    }

    function clearAllPayload() {
        var simplePayload = getSimplePayloadObject();
        simplePayload.setSpecifyMassOverride(false);
        simplePayload.setPayload(0);
        _payloadType = config.PAYLOAD_TYPES.SIMPLE;
        var detailedPayloads = getDetailedPayloadItems();
        for (var i = detailedPayloads.length - 1; i >= 0; i--) {
            remove(detailedPayloads[i]);
        }
    }

    function getPayloadById(payloadId) {
        var foundPayload = null;
        _payloadObjects.forEach(function (payload) {
            if (payload.getId() === payloadId) {
                foundPayload = payload;
            }
        });
        return foundPayload;
    }

    function getPayloadBySourceDatabaseId(sourceDatabaseId, optionalArraySeqNumber) {
        var foundPayload = null;
        var sourceDatabaseIdInstanceCounter = 1;
        _payloadObjects.forEach(function (payload) {
            if (payload.getSourceDatabaseId() === sourceDatabaseId) {
                if (optionalArraySeqNumber === undefined || (sourceDatabaseIdInstanceCounter === optionalArraySeqNumber)) {
                    foundPayload = payload;
                }
                sourceDatabaseIdInstanceCounter++;
            }
        });
        return foundPayload;
    }

    function getSimplePayloadId() {
        var foundPayload = null;
        _payloadObjects.forEach(function (payload) {
            if (payload.getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && foundPayload === null) {
                foundPayload = payload;
            }
        });
        return foundPayload === null ? null : foundPayload.getId();
    }

    function getTotalMass() {
        //Dim total As Double = 0

        //For Each payloadObject As FileLoadingPayloadObject In Me
        //total += payloadObject.Mass
        //Next

        //Return total

        var totalMass = 0;

        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                totalMass += getSimplePayloadObject().getMass();
            }
        } else {
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    totalMass += payloadObject.getMass();
                }
            });
        }

        

        return totalMass;
    }

    function getAveragePayloadHorizontalCOG() {
        /*
        If _PayloadType = FileLoading.PayloadTypes.SinglePointload And
            Me.Count = 1 Then

            Return Me(0).ActualHorizontalCOGFromFrontOfDeck

        Else

            Dim totalCOGs As Long

            For Each payloadObject As FileLoadingPayloadObject In Me
                totalCOGs += (payloadObject.Mass * payloadObject.ActualHorizontalCOGFromFrontOfDeck)
            Next

            If TotalMass = 0 Then
                Return 0
            Else
                Return totalCOGs / TotalMass
            End If

        End If
        
        */
        //#unfinished,  currently we are hardcoding support for singlePoint load
        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                return simplePayload.getActualHorizontalCOGFromFrontOfDeck();
            }else {
                return 0;
            }
            
        }else {
            var totalCOGs = 0;
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    totalCOGs += payloadObject.getMass() * payloadObject.getActualHorizontalCOGFromFrontOfDeck();
                }
            });

            if(getTotalMass() === 0) {
                return 0;
            }else {
                return totalCOGs/getTotalMass();
            }
        }
        
    }

    function getAveragePayloadHorizontalCOGPercent(payloadDeckLength) {
        return (getAveragePayloadHorizontalCOG()/payloadDeckLength) * 100;
    }

    function getAveragePayloadVerticalCOG() {
        /*
        If _PayloadType = FileLoading.PayloadTypes.SinglePointload And
            Me.Count = 1 Then

            Return Me(0).ActualVerticalCOGFromBottomOfDeck

        Else

            Dim totalCOGs As Long

            For Each payloadObject As FileLoadingPayloadObject In Me
                totalCOGs += (payloadObject.Mass * payloadObject.ActualVerticalCOGFromBottomOfDeck)
            Next

            If TotalMass = 0 Then
                Return 0
            Else
                Return totalCOGs / TotalMass
            End If

        End If
        
        */
        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                return simplePayload.getActualVerticalCOGFromBottomOfDeck();
            } else {
                return 0;
            }

        } else {
            var totalCOGs = 0;
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    totalCOGs += payloadObject.getMass() * payloadObject.getActualVerticalCOGFromBottomOfDeck();
                }
            });

            if (getTotalMass() === 0) {
                return 0;
            } else {
                return totalCOGs / getTotalMass();
            }
        }

    }

    function getAveragePayloadLateralCOG() {
        
        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                return simplePayload.getActualLateralCOGFromSideOfDeck();
            } else {
                return 0;
            }

        } else {
            var totalCOGs = 0;
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    totalCOGs += payloadObject.getMass() * payloadObject.getActualLateralCOGFromSideOfDeck();
                }
            });

            if (getTotalMass() === 0) {
                return 0;
            } else {
                return totalCOGs / getTotalMass();
            }
        }

    }
    
    function getFrontAxleLoad(distanceFromStartOfObjectToWheelbaseTheoreticalEnd, distanceFromStartOfObjectToPayloadDeckStart, wheelbaseTheoretical) {
        /*
        Return ApplicationGlobals.TurningMomentsFront(TotalMass,
                                                        distanceFromStartOfObjectToWheelbaseTheoreticalEnd -
                                                        (distanceFromStartOfObjectToPayloadDeckStart + AveragePayloadHorizontalCOG),
                                                        wheelbaseTheoretical)
        */
        return utils.getTurningMomentsFront(getTotalMass(),
                                            distanceFromStartOfObjectToWheelbaseTheoreticalEnd - (distanceFromStartOfObjectToPayloadDeckStart + getAveragePayloadHorizontalCOG()),
                                            wheelbaseTheoretical);
    }

    function getRearAxleLoad(distanceFromStartOfObjectToWheelbaseTheoreticalEnd, distanceFromStartOfObjectToPayloadDeckStart, wheelbaseTheoretical) {
        /*
        Get

        Return TotalLoad - FrontAxleLoad(distanceFromStartOfObjectToWheelbaseTheoreticalEnd,
                                            distanceFromStartOfObjectToPayloadDeckStart,
                                            wheelbaseTheoretical)
        */
        return getTotalLoad() - getFrontAxleLoad(distanceFromStartOfObjectToWheelbaseTheoreticalEnd, distanceFromStartOfObjectToPayloadDeckStart, wheelbaseTheoretical);
    }

    function getTotalLoad() {
        return getTotalMass();
    }

    function getMaxHeightOfPayloadObjects() {
        /*
        Dim maxHeight As Double = 0

        For Each payloadObject As FileLoadingPayloadObject In Me
            maxHeight = Math.Max(maxHeight, payloadObject.StartZ + payloadObject.Height)
        Next

        Return maxHeight
        */

        
        var maxHeight = 0;

        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                maxHeight += simplePayload.getStartY() + simplePayload.getHeight();
            }

        } else {
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    maxHeight = Math.max(maxHeight, payloadObject.getStartY() + payloadObject.getHeight());
                }
            });
        }

        
        return maxHeight;
    }

    function getMaxWidthOfPayloadObjects() {
        


        var maxWidth = 0;

        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                maxWidth += simplePayload.getStartZ() + simplePayload.getWidth();
            }

        } else {
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    maxWidth = Math.max(maxWidth, payloadObject.getStartZ() + payloadObject.getWidth());
                }
            });
        }


        return maxWidth;
    }

    function getMaxLengthOfPayloadObjects() {

        var maxLength = 0;

        if (_payloadType === config.PAYLOAD_TYPES.SIMPLE) {
            var simplePayload = getSimplePayloadObject();
            if (simplePayload !== null) {
                maxLength += simplePayload.getStartX() + simplePayload.getLength();
            }

        } else {
            _payloadObjects.forEach(function (payloadObject) {
                if (payloadObject.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                    maxLength = Math.max(maxLength, payloadObject.getStartX() + payloadObject.getLength());
                }
            });
        }


        return maxLength;

        /*
        Dim maxLength As Double = 0

        For Each payloadObject As FileLoadingPayloadObject In Me
            maxLength = Math.Max(maxLength, payloadObject.StartX + payloadObject.Length)
        Next

        Return maxLength
        */
    }

    function getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal) {

        var bottomPoint = -999999;
        var topViewCentreLine = topviewCentreLine;
        getDetailedPayloadItems().forEach(function (payload) {
            bottomPoint = Math.max(bottomPoint, (topViewCentreLine - leftOfBodyWidthExternal) - payload.getStartZ());
        });
        return bottomPoint;

    }

    function getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal) {

        var topPoint = -999999;
        var topViewCentreLine = topviewCentreLine;
        getDetailedPayloadItems().forEach(function (payload) {
            topPoint = Math.max(topPoint, -((topViewCentreLine - leftOfBodyWidthExternal) - payload.getStartZ()) + payload.getWidth());
        });
        return topPoint;

    }

    function getSimplePayloadBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal) {

        var bottomPoint = 0;
        var topViewCentreLine = topviewCentreLine;
        let payload = getSimplePayloadObject();
        if(payload) {
            bottomPoint = Math.max(bottomPoint, (topViewCentreLine - leftOfBodyWidthExternal) - payload.getStartZ());
        }
        return bottomPoint;

    }

    function getSimplePayloadTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal) {

        var topPoint = 0;
        var topViewCentreLine = topviewCentreLine;
        let payload = getSimplePayloadObject();
        if(payload) {
            topPoint = Math.max(topPoint, -((topViewCentreLine - leftOfBodyWidthExternal) - payload.getStartZ()) + payload.getWidth());
        }
        return topPoint;

    }

    function getPayloadWidth(topviewCentreLine, leftOfBodyWidthExternal, sideWallLeftThickness) {
        
        var totalWidth = 0;
        if(_payloadType === config.PAYLOAD_TYPES.DETAILED) {
            getDetailedPayloadItems().forEach(function (payload) {
                totalWidth = Math.max(totalWidth, payload.getWidth());
            }); 
            totalWidth = Math.max(totalWidth,  Math.max(topviewCentreLine, getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal + sideWallLeftThickness)) +  
                                    Math.max(topviewCentreLine, getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal + sideWallLeftThickness)));
        } else {
            
            totalWidth = Math.max(totalWidth,  Math.max(topviewCentreLine, getSimplePayloadBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal + sideWallLeftThickness)) +  Math.max(topviewCentreLine, getSimplePayloadTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfBodyWidthExternal + sideWallLeftThickness)));
        }
        
        return totalWidth;
    }

    function getDistanceFromWheelbaseTheoreticalEndForChassis(payload, distanceFromStartOfChassisToWheelbaseTheoreticalEnd, frontOfChassisToPayloadDeckStart) {
        return (distanceFromStartOfChassisToWheelbaseTheoreticalEnd) - (frontOfChassisToPayloadDeckStart + payload.getActualHorizontalCOGFromFrontOfDeck());
    }
    
    function initialiseSimplePayload(source, length, width, height, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, cOGType, verticalCOGType, lateralCOGType) {
        //this is only used when dealing with the vehicle or body payload values from app data so in this instance verticalCOGOverride is defaulted to false and verticalCOGConfirmed is the value coming back from appData
        //so verticaCOGConfirmed parameter is supplied by caller as the verticalCOGOverride

        /*
        id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob,
                        status, number, startX, startY, startZ, length, width, height, mass, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, verticalCOGConfirmed, lateralCOGConfirmed,
                        specifyMassOverride, payload, unitOfMeasure, density, payloadType, sourceDatabaseId, updateCounter, cached
        */
        var newPayloadObject = new Payload(globals.nextId(), _parent, '', '', source, 0, 0, config.ACCESSORY_TYPES.PAYLOAD, undefined, undefined, 0, config.ITEM_EDIT_LEVEL_OPTIONS.FULL_EDIT, '', 0, 0, 0, 0, length, width, height, 0, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, verticalCOGOverride, lateralCOGOverride, false, 0, config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS, 0, config.PAYLOAD_TYPES.SIMPLE, verticalCOGType || cOGType, lateralCOGType || cOGType, config.PAYLOAD_WEIGHT_TYPES.WEIGHT, 0, 0);
        
        
        _payloadObjects.push(newPayloadObject);
        _payloadType = config.PAYLOAD_TYPES.SIMPLE;
        return newPayloadObject;
    }
    //#endregion Getters and setters

    function getPayloadSaveObjects() {
        var tempArray = [];
        _payloadObjects.forEach(function (payload) {
            tempArray.push(payload.returnObjectToBeSaved(true));
        });
        return tempArray;
    }

    function addPayloads(payloadsArray) {
        payloadsArray.forEach(function (payloadAsJSON) {
            /*
            this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
            */
            var defaultCOGType = payloadAsJSON.COGType;
            //this is for setting up payloads from user data side so verticalCOGOverride is used here as expected and verticalCOGConfirmed is defaulted to false as the value should/will never be used
            //add(new Payload(_parent, 0, payload.Description, payload.StartX, payload.StartY, payload.StartZ, payload.Length, payload.Width, payload.Height, payload.Mass, payload.COGType, payload.HorizontalCOG, payload.HorizontalCOGOverride, payload.VerticalCOG, payload.VerticalCOGOverride, false, payload.SpecifyMassOverride));
            var tempPayload = new Payload(globals.nextId(), payloadAsJSON.Parent, payloadAsJSON.Name || '', payloadAsJSON.Description, payloadAsJSON.Source, 0, 0, config.ACCESSORY_TYPES.PAYLOAD, payloadAsJSON.GraphicBlob, payloadAsJSON.TopViewGraphicBlob, 0, config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
            payloadAsJSON.Status || '', 0, payloadAsJSON.StartX, payloadAsJSON.StartY, payloadAsJSON.StartZ, payloadAsJSON.Length, payloadAsJSON.Width, payloadAsJSON.Height, payloadAsJSON.Mass, payloadAsJSON.COGType,
            payloadAsJSON.HorizontalCOG, payloadAsJSON.HorizontalCOGOverride, payloadAsJSON.VerticalCOG, payloadAsJSON.VerticalCOGOverride, payloadAsJSON.LateralCOG, payloadAsJSON.LateralCOGOverride, false, false, payloadAsJSON.SpecifyMassOverride,
            payloadAsJSON.Payload, payloadAsJSON.UnitOfMeasure, payloadAsJSON.Density, payloadAsJSON.PayloadType || config.PAYLOAD_TYPES.SIMPLE, payloadAsJSON.VerticalCOGType || defaultCOGType, payloadAsJSON.LateralCOGType || defaultCOGType, payloadAsJSON.WeightType,
            payloadAsJSON.SourceDatabaseId, payloadAsJSON.UpdateCounter);

            if(payloadAsJSON.PayloadType && payloadAsJSON.PayloadType === config.PAYLOAD_TYPES.DETAILED) {
                if(payloadAsJSON.DecompressedGraphicBlob && utils.isValidDecompressedGraphicFile(payloadAsJSON.DecompressedGraphicBlob)) {
                    tempPayload.setDecompressedGraphicBlob(payloadAsJSON.DecompressedGraphicBlob);
                }
                if(payloadAsJSON.DecompressedTopViewGraphicBlob && utils.isValidDecompressedGraphicFile(payloadAsJSON.DecompressedTopViewGraphicBlob)) {
                    tempPayload.setDecompressedTopViewGraphicBlob(payloadAsJSON.DecompressedTopViewGraphicBlob);
                }
            }

            add(tempPayload);
        });
    }

    function getPayloadType() {
        return _payloadType;
    }

    function setPayloadType(newValue) {
        _payloadType = newValue === undefined || newValue === '' ? config.PAYLOAD_TYPES.SIMPLE : newValue;
    }
    //#region Initialize
    
    //#endregion Initialize



    //#region Public members
    self.getPayloadObjects = getPayloadObjects;
    self.setPayloadObjects = setPayloadObjects;
    self.add = add;
    self.remove = remove;
    self.getTotalMass = getTotalMass;
    self.getAveragePayloadHorizontalCOG = getAveragePayloadHorizontalCOG;
    self.getAveragePayloadVerticalCOG = getAveragePayloadVerticalCOG;
    self.getMaxHeightOfPayloadObjects = getMaxHeightOfPayloadObjects;
    self.getFrontAxleLoad = getFrontAxleLoad;
    self.getRearAxleLoad = getRearAxleLoad;
    self.getTotalLoad = getTotalLoad;
    self.initialiseSimplePayload = initialiseSimplePayload;
    self.getPayloadSaveObjects = getPayloadSaveObjects;
    self.addPayloads = addPayloads;
    self.getPayloadById = getPayloadById;
    self.getSimplePayloadId = getSimplePayloadId;
    self.getPayloadType = getPayloadType;
    self.setPayloadType = setPayloadType;
    self.getSimplePayloadObject = getSimplePayloadObject;
    self.getDetailedPayloadItems = getDetailedPayloadItems;
    self.removePayloadById = removePayloadById;
    self.getPayloadBySourceDatabaseId = getPayloadBySourceDatabaseId;
    self.getMaxLengthOfPayloadObjects = getMaxLengthOfPayloadObjects;
    self.getMaxWidthOfPayloadObjects = getMaxWidthOfPayloadObjects;
    self.getAveragePayloadLateralCOG = getAveragePayloadLateralCOG;
    self.getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine = getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine;
    self.getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine = getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine;
    self.getDistanceFromWheelbaseTheoreticalEndForChassis = getDistanceFromWheelbaseTheoreticalEndForChassis;
    self.clearAllPayload = clearAllPayload;
    self.getAveragePayloadHorizontalCOGPercent = getAveragePayloadHorizontalCOGPercent;
    self.getPayloadWidth = getPayloadWidth;
    //#endregion Public members
    FourCornerDistributionMixin.call(self);
};



export default PayloadObjectsHolder;

