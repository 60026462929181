import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';
import FourCornerDistributionMixin from '../configuration/mixin.fourCornerDistribution';


    
function Crane(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel, subframeHeight, length, width, height, mass, massOverride, cabGapOverride, 
                cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, status, lateralCOG, lateralCOGOverride, fromLeftOfVehicleRearWidth, aboveBelowChassis,
                verticalCOGType, lateralCOGType, verticalCOGConfirmed, lateralCOGConfirmed, sourceDatabaseId, updateCounter, cached,
    costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, isPinned, fromAxle1Front, fromAxle1FrontOverride) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    this._subframeHeight = subframeHeight;
    this._length = length;
    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
        //this._cabGapOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
        //this._cabGapOverride = cabGapOverride;
    }
    this._cabGapOverride = cabGapOverride;
    this._horizontalCOG = horizontalCOG;
    
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    this._fromLeftOfVehicleRearWidth = fromLeftOfVehicleRearWidth || 0;
    this._aboveBelowChassis = aboveBelowChassis;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    
    this._status = status;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;
    this._fromAxle1Front = fromAxle1Front || 0;
    this._fromAxle1FrontOverride = fromAxle1FrontOverride || false;
    this._fromCentreOfVehicle = 0;
    // this._fromCentreOfVehicleOverride = false;
    return this;
}
    
Crane.prototype.getSubframeHeight = function () {
    return this._subframeHeight;
};
Crane.prototype.setSubframeHeight = function (newValue) {
    this._subframeHeight = newValue;
};
Crane.prototype.getLength = function () {
    return this._length;
};
Crane.prototype.setLength = function (newValue) {
    this._length = newValue;
};
Crane.prototype.getWidth = function () {
    return this._width;
};
Crane.prototype.setWidth = function (newValue) {
    this._width = newValue;
};
Crane.prototype.getHeight = function () {
    return this._height;
};
Crane.prototype.setHeight = function (newValue) {
    this._height = newValue;
};
Crane.prototype.getMass = function () {
    return this._mass;
};
Crane.prototype.setMass = function (newValue) {
    this._mass = newValue;
};
Crane.prototype.getMassOverride = function () {
    return this._massOverride;
};
Crane.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
};
Crane.prototype.getCabGapOverride = function () {
    return this._cabGapOverride;
};
Crane.prototype.setCabGapOverride = function (newValue) {
    this._cabGapOverride = newValue;
};
Crane.prototype.getCOGType = function () {
    return this._cOGType;
};
Crane.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
};
Crane.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
};
Crane.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
};
Crane.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
};
Crane.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
};
Crane.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
};
Crane.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
};
Crane.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
};
Crane.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
};
Crane.prototype.getLateralCOG = function () {
    return this._lateralCOG;
};
Crane.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
};
Crane.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
};
Crane.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
};
Crane.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
};
Crane.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
};
Crane.prototype.getTotalLoad = function () {
    return this._mass;
};
Crane.prototype.getStatus = function () {
    return this._status;
};
Crane.prototype.setStatus = function (newValue) {
    this._status = newValue;
};
Crane.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
};
Crane.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
};
Crane.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
};
Crane.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
};
Crane.prototype.getFromLeftOfVehicleRearWidth = function () {
    return this._fromLeftOfVehicleRearWidth;
};
Crane.prototype.setFromLeftOfVehicleRearWidth = function (newValue) {
    this._fromLeftOfVehicleRearWidth = newValue;
};
Crane.prototype.getAboveBelowChassis = function () {
    return this._aboveBelowChassis;
};
Crane.prototype.setAboveBelowChassis = function (newValue) {
    this._aboveBelowChassis = newValue;
};
Crane.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
};
Crane.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
};
Crane.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
};
Crane.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
};
Crane.prototype.getIsPinned = function () {
    return this._isPinned;
};
Crane.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
};
Crane.prototype.getFromAxle1Front = function () {
    return this._fromAxle1Front;
}
Crane.prototype.setFromAxle1Front = function (newValue) {
    this._fromAxle1Front = newValue;
}
Crane.prototype.getFromAxle1FrontOverride = function () {
    return this._fromAxle1FrontOverride;
}
Crane.prototype.setFromAxle1FrontOverride = function (newValue) {
    this._fromAxle1FrontOverride = newValue;
}
Crane.prototype.getFromCentreOfVehicle = function () {
    return this._fromCentreOfVehicle;
}
Crane.prototype.setFromCentreOfVehicle = function (newValue) {
    this._fromCentreOfVehicle = newValue;
}
// Crane.prototype.getFromCentreOfVehicleOverride = function () {
//     return this._fromCentreOfVehicleOverride;
// }
// Crane.prototype.setFromCentreOfVehicleOverride = function (newValue) {
//     this._fromCentreOfVehicleOverride = newValue;
// }
Crane.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    } else {
        return this._horizontalCOG * this._length / 100;
    }
};
Crane.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * (this._height + this._subframeHeight) / 100;
    }
};
Crane.prototype.getActualLateralCOG = function () {
    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this._lateralCOG * this._width / 100;
    }
};
Crane.prototype.getActualHeight = function () {
    return this._subframeHeight + this._height
};

/**
 * Get the crane JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Crane.prototype.getCraneObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        "SubframeHeight": this._subframeHeight,
        "Length": this._length,
        "Width": this._width,
        "Height": this._height,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "CabGapOverride": this._cabGapOverride,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "GraphicBlob": this._graphicBlob,
        "Status": this._status,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType,
        "Axle1FrontToStartOfCrane": this._fromAxle1Front,
        "Axle1FrontToStartOfCraneOverride": this._fromAxle1FrontOverride
        //"FromLeftOfVehicleRearWidth": includePositionValues === true ? this._fromLeftOfVehicleRearWidth : 0,
        //"AboveBelowChassis": includePositionValues === true ? this._aboveBelowChassis : 0
    };

    if (includePositionValues === true) {
        saveObject["FromLeftOfVehicleRearWidth"] = this._fromLeftOfVehicleRearWidth;
        saveObject["AboveBelowChassis"] = this._aboveBelowChassis;
    }

    return saveObject;
};

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Crane.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getCraneObjectToBeSaved(includePositionValues), includePositionValues);
};

Crane.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newCrane = new Crane(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._subframeHeight,
        this._length,
        this._width,
        this._height,
        this._mass,
        this._massOverride,
        this._cabGapOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._status,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfVehicleRearWidth,
        this._aboveBelowChassis,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType(),
        this._isPinned,
        this._fromAxle1Front,
        this._fromAxle1FrontOverride
    );

    newCrane.setAccessLevel(this.getAccessLevel());
    newCrane.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newCrane.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    newCrane.setFromCentreOfVehicle(this.getFromCentreOfVehicle());

    return newCrane;
};

Crane.prototype.update = function (crane) {
    this.updateBase(crane);
    this.setSubframeHeight(crane.getSubframeHeight());
    this.setLength(crane.getLength());
    this.setWidth(crane.getWidth());
    this.setHeight(crane.getHeight());
    this.setMass(crane.getMass());
    this.setMassOverride(crane.getMassOverride());
    this.setCabGapOverride(crane.getCabGapOverride());
    this.setCOGType(crane.getCOGType());
    this.setHorizontalCOG(crane.getHorizontalCOG());
    this.setHorizontalCOGOverride(crane.getHorizontalCOGOverride());
    this.setVerticalCOG(crane.getVerticalCOG());
    this.setVerticalCOGOverride(crane.getVerticalCOGOverride());
    this.setStatus(crane.getStatus());
    this.setLateralCOG(crane.getLateralCOG());
    this.setLateralCOGOverride(crane.getLateralCOGOverride());
    this.setFromLeftOfVehicleRearWidth(crane.getFromLeftOfVehicleRearWidth());
    this.setAboveBelowChassis(crane.getAboveBelowChassis());
    this.setVerticalCOGType(crane.getVerticalCOGType());
    this.setLateralCOGType(crane.getLateralCOGType());
    this.setVerticalCOGConfirmed(crane.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(crane.getLateralCOGConfirmed());
    this.setFromCentreOfVehicle(crane.getFromCentreOfVehicle());
};

utils.extend(Accessory, Crane);
FourCornerDistributionMixin.call(Crane.prototype);

export default Crane;

