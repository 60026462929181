import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';
import FourCornerDistributionMixin from '../configuration/mixin.fourCornerDistribution';



function Hooklift(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel,
    length, width, height, mass, massOverride, cabGapOverride, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, status, lateralCOG, lateralCOGOverride,
    fromLeftOfVehicleRearWidth, verticalCOGType, lateralCOGType, verticalCOGConfirmed, lateralCOGConfirmed, type, sourceDatabaseId, updateCounter, cached, costingListPrice,
    costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, aFrame, hookOffset, centreOfRoller, platformHeight, isPinned, fromAxle1Front, fromAxle1FrontOverride) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, type, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);


    this._length = length;
    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    this._fromLeftOfVehicleRearWidth = fromLeftOfVehicleRearWidth || 0;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._status = status;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    //this._massOverride = massOverride;
    this._aFrame = aFrame;
    this._hookOffset = hookOffset;
    this._centreOfRoller = centreOfRoller;
    this._platformHeight = platformHeight;
    this._cabGapOverride = cabGapOverride;
    this._isPinned = isPinned;
    this._fromAxle1Front = fromAxle1Front || 0;
    this._fromAxle1FrontOverride = fromAxle1FrontOverride || false;
    this._fromCentreOfVehicle = 0;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;
}


Hooklift.prototype.getLength = function () {
    return this._length;
}
Hooklift.prototype.setLength = function (newValue) {
    this._length = newValue;
}
Hooklift.prototype.getWidth = function () {
    return this._width;
}
Hooklift.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
Hooklift.prototype.getHeight = function () {
    return this._height;
}
Hooklift.prototype.setHeight = function (newValue) {
    this._height = newValue;
}
Hooklift.prototype.getMass = function () {
    return this._mass;
}
Hooklift.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
Hooklift.prototype.getMassOverride = function () {
    return this._massOverride;
}
Hooklift.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Hooklift.prototype.getCabGapOverride = function () {
    return this._cabGapOverride;
};
Hooklift.prototype.setCabGapOverride = function (newValue) {
    this._cabGapOverride = newValue;
};
Hooklift.prototype.getCOGType = function () {
    return this._cOGType;
}
Hooklift.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
Hooklift.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
Hooklift.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
Hooklift.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
Hooklift.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
Hooklift.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Hooklift.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Hooklift.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Hooklift.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Hooklift.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Hooklift.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Hooklift.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Hooklift.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Hooklift.prototype.getFromLeftOfVehicleRearWidth = function () {
    return this._fromLeftOfVehicleRearWidth;
}
Hooklift.prototype.setFromLeftOfVehicleRearWidth = function (newValue) {
    this._fromLeftOfVehicleRearWidth = newValue;
}
Hooklift.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Hooklift.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Hooklift.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Hooklift.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Hooklift.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Hooklift.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Hooklift.prototype.getTotalLoad = function () {
    return this._mass;
}
Hooklift.prototype.getStatus = function () {
    return this._status;
}
Hooklift.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Hooklift.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Hooklift.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Hooklift.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Hooklift.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Hooklift.prototype.getAFrame = function () {
    return this._aFrame;
}
Hooklift.prototype.setAFrame = function (newValue) {
    this._aFrame = newValue;
}
Hooklift.prototype.getHookOffset = function () {
    return this._hookOffset;
}
Hooklift.prototype.setHookOffset = function (newValue) {
    this._hookOffset = newValue;
}
Hooklift.prototype.getCentreOfRoller = function () {
    return this._centreOfRoller;
}
Hooklift.prototype.setCentreOfRoller = function (newValue) {
    this._centreOfRoller = newValue;
}
Hooklift.prototype.getPlatformHeight = function () {
    return this._platformHeight;
}
Hooklift.prototype.setPlatformHeight = function (newValue) {
    this._platformHeight = newValue;
}
Hooklift.prototype.getIsPinned = function () {
    return this._isPinned;
}
Hooklift.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
}
Hooklift.prototype.getFromAxle1Front = function () {
    return this._fromAxle1Front;
}
Hooklift.prototype.setFromAxle1Front = function (newValue) {
    this._fromAxle1Front = newValue;
}
Hooklift.prototype.getFromAxle1FrontOverride = function () {
    return this._fromAxle1FrontOverride;
}
Hooklift.prototype.setFromAxle1FrontOverride = function (newValue) {
    this._fromAxle1FrontOverride = newValue;
}
Hooklift.prototype.getFromCentreOfVehicle = function () {
    return this._fromCentreOfVehicle;
}
Hooklift.prototype.setFromCentreOfVehicle = function (newValue) {
    this._fromCentreOfVehicle = newValue;
}
Hooklift.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    } else {
        return this._horizontalCOG * this._length / 100;
    }
}
Hooklift.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * this._height / 100;
    }
}
Hooklift.prototype.getActualLateralCOG = function () {

    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return (this._lateralCOG * this._width / 100);
    }
}
Hooklift.prototype.getActualHeight = function () {
    return this._height
}

/**
 * Get the hitch JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Hooklift.prototype.getHookliftObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        "Length": this._length,
        "Width": this._width,
        "Height": this._height,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "CabGapOverride": this._cabGapOverride,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "Status": this._status,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        //"Type": this._accessorySubType.toUpperCase(),
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType,
        "AFrame": this._aFrame,
        "HookOffset": this._hookOffset,
        "CentreOfRoller": this._centreOfRoller,
        "PlatformHeight": this._platformHeight,
        "Axle1FrontToStartOfHooklift": this._fromAxle1Front,
        "Axle1FrontToStartOfHookliftOverride": this._fromAxle1FrontOverride
    };

    if (includePositionValues === true) {
        saveObject["FromLeftOfVehicleRearWidth"] = this._fromLeftOfVehicleRearWidth;
    }

    return saveObject;
}

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Hooklift.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getHookliftObjectToBeSaved(includePositionValues), includePositionValues);
}

Hooklift.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newHooklift = new Hooklift(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._length,
        this._width,
        this._height,
        this._mass,
        this._massOverride,
        this._cabGapOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._status,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfVehicleRearWidth,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this._type,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType(),
        this.getAFrame(),
        this.getHookOffset(),
        this.getCentreOfRoller(),
        this.getPlatformHeight(),
        this._isPinned,
        this._fromAxle1Front,
        this._fromAxle1FrontOverride);

    newHooklift.setAccessLevel(this.getAccessLevel());
    newHooklift.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newHooklift.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    newHooklift.setFromCentreOfVehicle(this.getFromCentreOfVehicle());

    return newHooklift;
}

Hooklift.prototype.update = function (hooklift) {
    this.updateBase(hooklift);
    this.setLength(hooklift.getLength());
    this.setWidth(hooklift.getWidth());
    this.setHeight(hooklift.getHeight());
    this.setMass(hooklift.getMass());
    this.setMassOverride(hooklift.getMassOverride());
    this.setCOGType(hooklift.getCOGType());
    this.setHorizontalCOG(hooklift.getHorizontalCOG());
    this.setHorizontalCOGOverride(hooklift.getHorizontalCOGOverride());
    this.setVerticalCOG(hooklift.getVerticalCOG());
    this.setVerticalCOGOverride(hooklift.getVerticalCOGOverride());
    this.setStatus(hooklift.getStatus());
    this.setLateralCOG(hooklift.getLateralCOG());
    this.setLateralCOGOverride(hooklift.getLateralCOGOverride());
    this.setFromLeftOfVehicleRearWidth(hooklift.getFromLeftOfVehicleRearWidth());
    this.setVerticalCOGType(hooklift.getVerticalCOGType());
    this.setLateralCOGType(hooklift.getLateralCOGType());
    this.setVerticalCOGConfirmed(hooklift.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(hooklift.getLateralCOGConfirmed());
    this.setAccessorySubType(hooklift.getAccessorySubType());
    this.setAFrame(hooklift.getAFrame());
    this.setHookOffset(hooklift.getHookOffset());
    this.setCentreOfRoller(hooklift.getCentreOfRoller());
    this.setPlatformHeight(hooklift.getPlatformHeight());
    this.setFromCentreOfVehicle(hooklift.getFromCentreOfVehicle());
}

utils.extend(Accessory, Hooklift);
FourCornerDistributionMixin.call(Hooklift.prototype);

export default Hooklift;

