import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '../../../services';
import Body from './model.body';
import Crane from './model.crane';
import Taillift from './model.taillift';
import Fridge from './model.fridge';
import FifthWheel from './model.fifthWheel';
import Other from './model.other';
import BodyUpright from './model.bodyUpright';
import Hitch from './model.hitch';
import Hooklift from './model.hooklift';
// import { Body, Crane, Taillift, Fridge, FifthWheel, Other, BodyUpright, Hitch, Hooklift} from '../../offer';



function AccessoryHolder(parentChassis) {

    var self = this;

    var _accessories = [], _parentChassisObject = parentChassis;

    function getAccessories() {
        return _accessories;
    }
    function setAccessories(newValue) {
        _accessories = newValue;
    }


    function add(accessory) {
        _accessories.push(accessory);
    }

    function remove(accessory) {
        var indexOfElementToRemove = _accessories.map(function (item) { return item.getId(); }).indexOf(accessory.getId());
        _accessories.splice(indexOfElementToRemove, 1);
    }

    function removeAccessoryById(accessoryId) {

        var accessory = getAccessoryById(accessoryId);
        if (accessory !== null) {
            remove(accessory);
        }

    }

    function getAccessoryById(accessoryId) {
        var foundAccessory = null;
        _accessories.forEach(function (accessory) {
            if (accessory.getId() === accessoryId) {
                foundAccessory = accessory;
            }
        });
        return foundAccessory;
    }

    function getAccessoryBySourceDatabaseId(accessoryType, sourceDatabaseId, optionalArraySeqNumber) {
        var foundAccessory = null;
        var sourceDatabaseIdInstanceCounter = 1;
        _accessories.forEach(function (accessory) {
            if (accessory.getSourceDatabaseId() === sourceDatabaseId && accessory.getAccessoryType() === accessoryType) {
                if ((optionalArraySeqNumber === undefined && sourceDatabaseIdInstanceCounter === 1) || (sourceDatabaseIdInstanceCounter === optionalArraySeqNumber)) {
                    foundAccessory = accessory;
                }
                sourceDatabaseIdInstanceCounter++;
            }
        });
        return foundAccessory;
    }

    function addAccessories(accessories, chassisHeight, wheelbaseTheoreticalEnd, distanceFromWheelbaseTheoreticalEndToRearMostDrivenAxle ) {
        if (accessories.Bodies) {
            if (accessories.Bodies && accessories.Bodies.length > 0) {
                accessories.Bodies.forEach(function (body) {
                    addBody(body);

                });
            }
        } else if (accessories.Body) {
            addBody(accessories.Body);
        }


        if (accessories.Cranes && accessories.Cranes.length > 0) {
            accessories.Cranes.forEach(function (crane) {
                addCrane(crane);
            });
        } else if (accessories.Crane) {
            addCrane(accessories.Crane);
        }
        if (accessories.Others && accessories.Others.length > 0) {
            accessories.Others.forEach(function (other) {
                addOther(other)
            });
        }
        if (accessories.Fridges && accessories.Fridges.length > 0) {
            accessories.Fridges.forEach(function (fridge) {
                addFridge(fridge);
            });
        } else if (accessories.Fridge) {
            addFridge(accessories.Fridge);
        }
        if (accessories.Taillifts && accessories.Taillifts.length > 0) {
            accessories.Taillifts.forEach(function (taillift) {
                addTaillift(taillift);
            });
        } else if (accessories.Taillift) {
            addTaillift(accessories.Taillift);
        }
        if (accessories.FifthWheels && accessories.FifthWheels.length > 0) {
            accessories.FifthWheels.forEach(function (fifthWheel) {
                addFifthWheel(fifthWheel);
            });
        }
        if (accessories.Hitches && accessories.Hitches.length > 0) {
            accessories.Hitches.forEach(function (hitch) {
                addHitch(hitch);
            });
        }
        if (accessories.Hooklifts && accessories.Hooklifts.length > 0) {
            accessories.Hooklifts.forEach(function (hooklift) {
                addHooklift(hooklift);
            });
        } else if (accessories.Hooklift) {
            addHooklift(accessories.Hooklift);
        }

        function addBody(bodyAsJSON) {

            var cogTypeDefaultValue = bodyAsJSON.COGType || bodyAsJSON.HorizontalCOGType;
            var payloadCOGTypeDefaultValue = bodyAsJSON.PayloadCOGType || bodyAsJSON.PayloadHorizontalCOGType;
            var rampPlatesCOGTypeDefaultValue = bodyAsJSON.RampPlatesCOGType;

            var defaultEditLevel = globals.getDefaultEditLevel(bodyAsJSON);

            var tempBody = new Body(globals.nextId(), parentChassis.getChassisObjectName(), bodyAsJSON.Name, bodyAsJSON.Description, bodyAsJSON.Source, bodyAsJSON.CabGap,
                chassisHeight, config.ACCESSORY_TYPES.BODY, bodyAsJSON.Graphic, bodyAsJSON.TopViewGraphicBlob, bodyAsJSON.SourceDatabaseId, 0, defaultEditLevel, bodyAsJSON.Type,
                bodyAsJSON.SubframeHeight, bodyAsJSON.SubstructureHeight, bodyAsJSON.HeadboardHeight, bodyAsJSON.TailboardHeight,
                bodyAsJSON.HeightType, bodyAsJSON.Height, bodyAsJSON.LengthType, bodyAsJSON.Length,
                bodyAsJSON.HingePoint, bodyAsJSON.Width, bodyAsJSON.WidthType, bodyAsJSON.Mass, bodyAsJSON.MassOverride, bodyAsJSON.CabGapOverride, bodyAsJSON.MassType,
                bodyAsJSON.HeadboardThickness, bodyAsJSON.TailboardThickness, bodyAsJSON.RoofThickness, bodyAsJSON.SidewallRight,
                bodyAsJSON.SidewallLeft, bodyAsJSON.Material, bodyAsJSON.FloorType, bodyAsJSON.HorizontalCOGType, bodyAsJSON.HorizontalCOG || bodyAsJSON.COG, bodyAsJSON.HorizontalCOGOverride,
                bodyAsJSON.PayloadHorizontalCOGType || bodyAsJSON.PayloadCOGType, bodyAsJSON.PayloadHorizontalCOG || bodyAsJSON.PayloadCOG, bodyAsJSON.TarpCoverMassType, bodyAsJSON.TarpCoverMass,
                bodyAsJSON.VerticalCOG, bodyAsJSON.VerticalCOGOverride, bodyAsJSON.PayloadVerticalCOG, bodyAsJSON.RampLength, bodyAsJSON.RampAngle, bodyAsJSON.RampPlatesProtrusion,
                bodyAsJSON.RampPlatesHeight, bodyAsJSON.RampPlatesMass, bodyAsJSON.RampPlatesCOGType, bodyAsJSON.RampPlatesHorizontalCOG, bodyAsJSON.RampPlatesHorizontalCOGOverride, bodyAsJSON.RampPlatesVerticalCOG, bodyAsJSON.RampPlatesVerticalCOGOverride,
                bodyAsJSON.SubframeLength, bodyAsJSON.PayloadVerticalCOGOverride, bodyAsJSON.LateralCOG, bodyAsJSON.LateralCOGOverride, bodyAsJSON.PayloadLateralCOG, bodyAsJSON.PayloadLateralCOGOverride,
                bodyAsJSON.RampPlatesLateralCOG, bodyAsJSON.RampPlatesLateralCOGOverride, bodyAsJSON.BottomOfBodyToTopOfChassis, bodyAsJSON.LeftCompartmentWidth, bodyAsJSON.RightCompartmentWidth,
                bodyAsJSON.VerticalCOGType || cogTypeDefaultValue, bodyAsJSON.LateralCOGType || cogTypeDefaultValue, bodyAsJSON.PayloadVerticalCOGType || payloadCOGTypeDefaultValue, bodyAsJSON.PayloadLateralCOGType || payloadCOGTypeDefaultValue, bodyAsJSON.RampPlatesVerticalCOGType || rampPlatesCOGTypeDefaultValue, bodyAsJSON.RampPlatesLateralCOGType || rampPlatesCOGTypeDefaultValue,
                false, false, false, false, false, false,
                bodyAsJSON.UpdateCounter, undefined, bodyAsJSON.SideDoors, bodyAsJSON.SideDoorType, bodyAsJSON.FrontToDoor, bodyAsJSON.WidthPerDoor, bodyAsJSON.DoorHeight, null,
                bodyAsJSON.CostingListPrice, bodyAsJSON.CostingListPriceOverride, bodyAsJSON.CostingDiscount, bodyAsJSON.CostingPeriod, bodyAsJSON.CostingInterestRate, bodyAsJSON.CostingResidualRate, bodyAsJSON.CostingResidualRateType,
                bodyAsJSON.AboveBelowChassis, bodyAsJSON.AboveBelowChassisOverride, bodyAsJSON.SubType, bodyAsJSON.Axle1FrontToStartOfBody || 0, bodyAsJSON.Axle1FrontToStartOfBodyOverride || false
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if (tempBody.getType() === config.BODY_TYPES.TIMBER) {
                if (bodyAsJSON.Uprights && bodyAsJSON.Uprights.length > 0) {
                    bodyAsJSON.Uprights.forEach(function (upright) {
                        tempBody.getBodyUprightHolder().add(new BodyUpright(globals.nextId(), upright.Number, upright.InsideFrontToUpright, upright.Width, upright.Height));
                    });
                }
            }
            if(bodyAsJSON.DecompressedGraphicBlob) {
                tempBody.setDecompressedGraphicBlob(bodyAsJSON.DecompressedGraphicBlob);
            }
            if(bodyAsJSON.DecompressedTopViewGraphicBlob) {
                tempBody.setDecompressedTopViewGraphicBlob(bodyAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempBody);
        }

        function addCrane(craneAsJSON) {

            var defaultCOGType = craneAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(craneAsJSON);

            var tempCrane = new Crane(globals.nextId(), parentChassis.getChassisObjectName(), craneAsJSON.Name, craneAsJSON.Description, craneAsJSON.Source, craneAsJSON.CabGap,
                chassisHeight, config.ACCESSORY_TYPES.CRANE, craneAsJSON.GraphicBlob, craneAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, craneAsJSON.SubframeHeight, craneAsJSON.Length, craneAsJSON.Width, craneAsJSON.Height, craneAsJSON.Mass, craneAsJSON.MassOverride, craneAsJSON.CabGapOverride || false, craneAsJSON.COGType,
                craneAsJSON.HorizontalCOG, craneAsJSON.HorizontalCOGOverride || false, craneAsJSON.VerticalCOG, craneAsJSON.VerticalCOGOverride || false, craneAsJSON.Status, craneAsJSON.LateralCOG, craneAsJSON.LateralCOGOverride, craneAsJSON.FromLeftOfVehicleRearWidth || 0, craneAsJSON.AboveBelowChassis || 0,
                craneAsJSON.VerticalCOGType || defaultCOGType, craneAsJSON.LateralCOGType || defaultCOGType,
                false, false, craneAsJSON.SourceDatabaseId, craneAsJSON.UpdateCounter, null, craneAsJSON.CostingListPrice, craneAsJSON.CostingListPriceOverride, craneAsJSON.CostingDiscount, craneAsJSON.CostingPeriod, craneAsJSON.CostingInterestRate, craneAsJSON.CostingResidualRate, craneAsJSON.CostingResidualRateType, undefined,
                craneAsJSON.Axle1FrontToStartOfCrane || 0, craneAsJSON.Axle1FrontToStartOfCraneOverride || false
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(craneAsJSON.DecompressedGraphicBlob) {
                tempCrane.setDecompressedGraphicBlob(craneAsJSON.DecompressedGraphicBlob);
            }
            if(craneAsJSON.DecompressedTopViewGraphicBlob) {
                tempCrane.setDecompressedTopViewGraphicBlob(craneAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempCrane);
        }

        function addOther(otherAsJSON) {

            var defaultCOGType = otherAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(otherAsJSON);

            var tempOther = new Other(globals.nextId(), parentChassis.getChassisObjectName(), otherAsJSON.Name, otherAsJSON.Description, otherAsJSON.Source, otherAsJSON.CabGap,
                otherAsJSON.VerticalGap, config.ACCESSORY_TYPES.OTHER, otherAsJSON.GraphicBlob, otherAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, otherAsJSON.Length, otherAsJSON.Width, otherAsJSON.Height, otherAsJSON.Mass, otherAsJSON.MassOverride, otherAsJSON.COGType,
                otherAsJSON.HorizontalCOG, otherAsJSON.HorizontalCOGOverride || false, otherAsJSON.VerticalCOG, otherAsJSON.VerticalCOGOverride || false, otherAsJSON.Status, otherAsJSON.LateralCOG, otherAsJSON.LateralCOGOverride || false, otherAsJSON.FromLeftOfVehicleRearWidth || 0,
                otherAsJSON.VerticalCOGType || defaultCOGType, otherAsJSON.LateralCOGType || defaultCOGType,
                false, false, otherAsJSON.Type, otherAsJSON.SourceDatabaseId, otherAsJSON.UpdateCounter, null, otherAsJSON.CostingListPrice, otherAsJSON.CostingListPriceOverride, otherAsJSON.CostingDiscount, otherAsJSON.CostingPeriod, otherAsJSON.CostingInterestRate, otherAsJSON.CostingResidualRate, otherAsJSON.CostingResidualRateType
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(otherAsJSON.DecompressedGraphicBlob) {
                tempOther.setDecompressedGraphicBlob(otherAsJSON.DecompressedGraphicBlob);
            }
            if(otherAsJSON.DecompressedTopViewGraphicBlob) {
                tempOther.setDecompressedTopViewGraphicBlob(otherAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempOther);
        }

        function addFridge(fridgeAsJSON) {

            var defaultCOGType = fridgeAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(fridgeAsJSON);

            var tempFridge = new Fridge(globals.nextId(), parentChassis.getChassisObjectName(), fridgeAsJSON.Name, fridgeAsJSON.Description, fridgeAsJSON.Source, getBodyGap(),
                chassisHeight, config.ACCESSORY_TYPES.FRIDGE, fridgeAsJSON.GraphicBlob, fridgeAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, fridgeAsJSON.Width, fridgeAsJSON.Height, fridgeAsJSON.Mass, fridgeAsJSON.MassOverride, fridgeAsJSON.COGType,
                fridgeAsJSON.HorizontalCOG, fridgeAsJSON.HorizontalCOGOverride || false, fridgeAsJSON.VerticalCOG, fridgeAsJSON.VerticalCOGOverride || false, fridgeAsJSON.Status, fridgeAsJSON.MountingPosition || 0, fridgeAsJSON.Protrusion,
                fridgeAsJSON.LateralCOG, fridgeAsJSON.LateralCOGOverride || false, fridgeAsJSON.FromLeftOfBodyWidthExternal || 0,
                fridgeAsJSON.VerticalCOGType || defaultCOGType, fridgeAsJSON.LateralCOGType || defaultCOGType,
                false, false,
                fridgeAsJSON.SourceDatabaseId, fridgeAsJSON.UpdateCounter, null, fridgeAsJSON.CostingListPrice, fridgeAsJSON.CostingListPriceOverride, fridgeAsJSON.CostingDiscount, fridgeAsJSON.CostingPeriod, fridgeAsJSON.CostingInterestRate, fridgeAsJSON.CostingResidualRate, fridgeAsJSON.CostingResidualRateType,
                fridgeAsJSON.FromFrontOfBody || 0
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(fridgeAsJSON.DecompressedGraphicBlob) {
                tempFridge.setDecompressedGraphicBlob(fridgeAsJSON.DecompressedGraphicBlob);
            }
            if(fridgeAsJSON.DecompressedTopViewGraphicBlob) {
                tempFridge.setDecompressedTopViewGraphicBlob(fridgeAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempFridge);
        }

        function addTaillift(tailliftAsJSON) {

            var hGap, vGap;
            if (parentChassis.getType !== undefined && parentChassis.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                hGap = parentChassis.getOverallLength() - parentChassis.getBumperToBackOfCab();
            } else {
                hGap = getBodyGap() + getBody().getActualLength();
            }
            vGap = 0;

            var defaultCOGType = tailliftAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(tailliftAsJSON);

            var tempTaillift = new Taillift(globals.nextId(), parentChassis.getChassisObjectName(), tailliftAsJSON.Name, tailliftAsJSON.Description, tailliftAsJSON.Source, hGap,
                vGap, config.ACCESSORY_TYPES.TAILLIFT, tailliftAsJSON.GraphicBlob, tailliftAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, tailliftAsJSON.Width, tailliftAsJSON.Height, tailliftAsJSON.Mass, tailliftAsJSON.MassOverride, tailliftAsJSON.COGType,
                tailliftAsJSON.HorizontalCOG, tailliftAsJSON.HorizontalCOGOverride || false, tailliftAsJSON.VerticalCOG, tailliftAsJSON.VerticalCOGOverride || false, tailliftAsJSON.LateralCOG, tailliftAsJSON.LateralCOGOverride || false,
                tailliftAsJSON.FromLeftOfBodyWidthExternal || 0, tailliftAsJSON.Status, tailliftAsJSON.Type, tailliftAsJSON.RearClearance || 0, tailliftAsJSON.InternalMountingLength, tailliftAsJSON.RearProtrusion, tailliftAsJSON.AboveOrBelowFloor || tailliftAsJSON.AboveOrBelowSubframe || 0, tailliftAsJSON.MechanismHeight,
                tailliftAsJSON.VerticalCOGType || defaultCOGType, tailliftAsJSON.LateralCOGType || defaultCOGType,
                false, false, tailliftAsJSON.SourceDatabaseId, tailliftAsJSON.UpdateCounter, null, tailliftAsJSON.CostingListPrice, tailliftAsJSON.CostingListPriceOverride, tailliftAsJSON.CostingDiscount, tailliftAsJSON.CostingPeriod, tailliftAsJSON.CostingInterestRate, tailliftAsJSON.CostingResidualRate, tailliftAsJSON.CostingResidualRateType
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(tailliftAsJSON.DecompressedGraphicBlob) {
                tempTaillift.setDecompressedGraphicBlob(tailliftAsJSON.DecompressedGraphicBlob);
            }
            if(tailliftAsJSON.DecompressedTopViewGraphicBlob) {
                tempTaillift.setDecompressedTopViewGraphicBlob(tailliftAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempTaillift);
        }

        function addFifthWheel(fifthWheelAsJSON) {

            var defaultCOGType = fifthWheelAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(fifthWheelAsJSON);

            var tempFifthWheel = new FifthWheel(globals.nextId(), parentChassis.getChassisObjectName(), fifthWheelAsJSON.Name, fifthWheelAsJSON.Description, fifthWheelAsJSON.Source, 0,
                0, config.ACCESSORY_TYPES.FIFTH_WHEEL, fifthWheelAsJSON.GraphicBlob, fifthWheelAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, fifthWheelAsJSON.Offset || 0, fifthWheelAsJSON.FrontOverhang, fifthWheelAsJSON.Height, fifthWheelAsJSON.Length, fifthWheelAsJSON.SubframeHeight,
                fifthWheelAsJSON.Width, fifthWheelAsJSON.Mass, fifthWheelAsJSON.MassOverride, fifthWheelAsJSON.COGType, fifthWheelAsJSON.HorizontalCOG, fifthWheelAsJSON.HorizontalCOGOverride || false, fifthWheelAsJSON.VerticalCOG,
                fifthWheelAsJSON.VerticalCOGOverride || false, fifthWheelAsJSON.Status, fifthWheelAsJSON.LateralCOG, fifthWheelAsJSON.LateralCOGOverride, fifthWheelAsJSON.FromLeftOfVehicleRearWidth, fifthWheelAsJSON.VerticalCOGType || defaultCOGType, fifthWheelAsJSON.LateralCOGType || defaultCOGType,
                false, false, fifthWheelAsJSON.SourceDatabaseId, fifthWheelAsJSON.UpdateCounter, null, fifthWheelAsJSON.CostingListPrice, fifthWheelAsJSON.CostingListPriceOverride, fifthWheelAsJSON.CostingDiscount, fifthWheelAsJSON.CostingPeriod, fifthWheelAsJSON.CostingInterestRate, fifthWheelAsJSON.CostingResidualRate, fifthWheelAsJSON.CostingResidualRateType
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(fifthWheelAsJSON.DecompressedGraphicBlob) {
                tempFifthWheel.setDecompressedGraphicBlob(fifthWheelAsJSON.DecompressedGraphicBlob);
            }
            if(fifthWheelAsJSON.DecompressedTopViewGraphicBlob) {
                tempFifthWheel.setDecompressedTopViewGraphicBlob(fifthWheelAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempFifthWheel);
        }

        function addHitch(hitchAsJSON) {

            var defaultCOGType = hitchAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(hitchAsJSON);

            var tempHitch = new Hitch(globals.nextId(), parentChassis.getChassisObjectName(), hitchAsJSON.Name, hitchAsJSON.Description, hitchAsJSON.Source, wheelbaseTheoreticalEnd + (distanceFromWheelbaseTheoreticalEndToRearMostDrivenAxle || 0), 0, config.ACCESSORY_TYPES.HITCH, hitchAsJSON.GraphicBlob, hitchAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, hitchAsJSON.Type, hitchAsJSON.Status, hitchAsJSON.Offset || 0,
                hitchAsJSON.Height, hitchAsJSON.HeightFromGround || 0, hitchAsJSON.CouplingHeight, hitchAsJSON.Width, hitchAsJSON.Mass, hitchAsJSON.MassOverride || false, hitchAsJSON.MountingLength, hitchAsJSON.CouplingOffset, hitchAsJSON.FunnelLength,
                hitchAsJSON.COGType, hitchAsJSON.HorizontalCOG, hitchAsJSON.HorizontalCOGOverride || false, hitchAsJSON.VerticalCOG, hitchAsJSON.VerticalCOGOverride || false, hitchAsJSON.LateralCOG, hitchAsJSON.LateralCOGOverride || false, hitchAsJSON.FromLeftOfVehicleRearWidth || 0,
                hitchAsJSON.VerticalCOGType || defaultCOGType, hitchAsJSON.LateralCOGType || defaultCOGType,
                false, false,
                hitchAsJSON.SourceDatabaseId, hitchAsJSON.UpdateCounter, null, hitchAsJSON.CostingListPrice, hitchAsJSON.CostingListPriceOverride, hitchAsJSON.CostingDiscount, hitchAsJSON.CostingPeriod, hitchAsJSON.CostingInterestRate, hitchAsJSON.CostingResidualRate, hitchAsJSON.CostingResidualRateType);
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(hitchAsJSON.DecompressedGraphicBlob) {
                tempHitch.setDecompressedGraphicBlob(hitchAsJSON.DecompressedGraphicBlob);
            }
            if(hitchAsJSON.DecompressedTopViewGraphicBlob) {
                tempHitch.setDecompressedTopViewGraphicBlob(hitchAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempHitch);
        }

        function addHooklift(hookliftAsJSON) {

            var defaultCOGType = hookliftAsJSON.COGType;

            var defaultEditLevel = globals.getDefaultEditLevel(hookliftAsJSON);

            var tempHooklift = new Hooklift(globals.nextId(), parentChassis.getChassisObjectName(), hookliftAsJSON.Name, hookliftAsJSON.Description, hookliftAsJSON.Source, hookliftAsJSON.CabGap, hookliftAsJSON.VerticalGap, config.ACCESSORY_TYPES.HOOKLIFT, hookliftAsJSON.GraphicBlob, hookliftAsJSON.TopViewGraphicBlob, 0, defaultEditLevel, hookliftAsJSON.Length, hookliftAsJSON.Width, hookliftAsJSON.Height,
                hookliftAsJSON.Mass, hookliftAsJSON.MassOverride, hookliftAsJSON.CabGapOverride, hookliftAsJSON.COGType, hookliftAsJSON.HorizontalCOG, hookliftAsJSON.HorizontalCOGOverride || false, hookliftAsJSON.VerticalCOG, hookliftAsJSON.VerticalCOGOverride || false, hookliftAsJSON.Status,
                hookliftAsJSON.LateralCOG, hookliftAsJSON.LateralCOGOverride || false, hookliftAsJSON.FromLeftOfVehicleRearWidth || 0, hookliftAsJSON.VerticalCOGType || defaultCOGType, hookliftAsJSON.LateralCOGType || defaultCOGType, false, false, hookliftAsJSON.Type, hookliftAsJSON.SourceDatabaseId,
                hookliftAsJSON.UpdateCounter, null, hookliftAsJSON.CostingListPrice, hookliftAsJSON.CostingListPriceOverride, hookliftAsJSON.CostingDiscount, hookliftAsJSON.CostingPeriod, hookliftAsJSON.CostingInterestRate, hookliftAsJSON.CostingResidualRate, hookliftAsJSON.CostingResidualRateType,
                hookliftAsJSON.AFrame, hookliftAsJSON.HookOffset, hookliftAsJSON.CentreOfRoller, hookliftAsJSON.PlatformHeight, undefined, hookliftAsJSON.Axle1FrontToStartOfHooklift || 0, hookliftAsJSON.Axle1FrontToStartOfHookliftOverride || false
            );
            //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
            if(hookliftAsJSON.DecompressedGraphicBlob) {
                tempHooklift.setDecompressedGraphicBlob(hookliftAsJSON.DecompressedGraphicBlob);
            }
            if(hookliftAsJSON.DecompressedTopViewGraphicBlob) {
                tempHooklift.setDecompressedTopViewGraphicBlob(hookliftAsJSON.DecompressedTopViewGraphicBlob);
            }
            add(tempHooklift);
        }
    }

    function getAccessoryHeight(chassisHeightRear) {
        /*Dim totalHeight As Double = 0

        For Each accessory As FileLoadingAccessory In Me

        If TypeOf (accessory) Is FileLoadingAccessoryBody Then

        Dim x As FileLoadingAccessoryBody = accessory

        'totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.SubstructureHeight + x.HeadboardHeight)
        'totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.SubstructureHeight + x.TailboardHeight)

        'If x.BodyType = BodyTemplateTypes.Timber Then
        '    For Each upright As FileLoadingAccessoryBodyUpright In x.Uprights
        '        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.SubstructureHeight + upright.Height)
        '    Next
        'End If

        'If x.HeightType = BodyMeasurementTypes.External Then
        '    totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.Height)
        'Else
        '    totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.SubstructureHeight + x.Height + x.RoofThickness)
        'End If
        totalHeight = chassisHeightRear + x.ActualExternalHeight

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then

        Dim x As FileLoadingAccessoryCrane = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrewCab Then

        Dim x As FileLoadingAccessoryCrewCab = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryFairing Then
        'the fairing has no impact on height as it may not go above the height of the body
        ElseIf TypeOf (accessory) Is FileLoadingAccessoryFifthWheel Then

        Dim x As FileLoadingAccessoryFifthWheel = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then
        'the fridge has no impact on height as it may not go above the height of the body
        ElseIf TypeOf (accessory) Is FileLoadingAccessoryFuelTank Then

        Dim x As FileLoadingAccessoryFuelTank = accessory

        If x.Type = FuelTankTypes.BehindCab Then
        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.Height)
        Else
        'the side mounted fuel tank has no impact on height as it may not go above the height of the chassis
        End If

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryHeadboard Then

        Dim x As FileLoadingAccessoryHeadboard = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.SubframeHeight + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then
        'the hitch has no impact on the height calculation as it is limited to between
        'ground clearnace rear and chassis height rear
        ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then

        Dim x As FileLoadingAccessoryTaillift = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.VerticalGap + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar Then
        'the bullbar has no impact on height as it may not go above the height of the body
        ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

        Dim x As FileLoadingAccessoryRollover = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.Height)

        ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

        Dim x As FileLoadingAccessoryOther = accessory

        totalHeight = Math.Max(totalHeight, chassisHeightRear + x.VerticalGap + x.Height)

        End If

        Next

        Return totalHeight*/
        //#unfinished, currently just using the body, crane, other, fridge, taillift to calculate the height as we have no other accessories, code above needs to be ported later on
        var totalHeight = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (accessory.getType() === config.BODY_TYPES.SERVICE /*|| accessory.getType() === config.BODY_TYPES.COMPACTOR || accessory.getType() === config.BODY_TYPES.BEAVERTAIL || accessory.getType() === config.BODY_TYPES.OTHER*/) {
                    totalHeight = Math.max(totalHeight, chassisHeightRear - accessory.getBottomOfBodyToTopOfChassis() + accessory.getActualExternalHeight());
                } else if (accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    var tempHooklift = getHooklift();
                    totalHeight = Math.max(totalHeight, chassisHeightRear + accessory.getAboveBelowChassis() + tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight() + accessory.getActualExternalHeight());
                } else {
                    totalHeight = Math.max(totalHeight, chassisHeightRear + accessory.getAboveBelowChassis() + accessory.getActualExternalHeight());
                }

            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
                totalHeight = Math.max(totalHeight, chassisHeightRear + accessory.getSubframeHeight() + accessory.getHeight() + accessory.getAboveBelowChassis());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                totalHeight = Math.max(totalHeight, chassisHeightRear + accessory.getVerticalGap() + accessory.getHeight());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                let tempBody = getBody();
                totalHeight = Math.max(totalHeight, chassisHeightRear + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - accessory.getMountingPosition());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                totalHeight = Math.max(totalHeight, (chassisHeightRear /*+ accessory.getVerticalGap()*/ + accessory.getAboveOrBelowFloor() - accessory.getMechanismHeight()) + (accessory.getMechanismHeight() + accessory.getPlatformHeight()));
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                var fifthWheelEffectiveHeight;
                if (accessory.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                    fifthWheelEffectiveHeight = accessory.getSubframeHeight() + accessory.getHeight();
                } else {
                    fifthWheelEffectiveHeight = accessory.getHeight();
                }
                totalHeight = Math.max(totalHeight, chassisHeightRear + fifthWheelEffectiveHeight);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
                //the hitch has no impact
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
                totalHeight = Math.max(totalHeight, chassisHeightRear + accessory.getVerticalGap() + accessory.getHeight());
            }

        });
        return totalHeight;
    }

    function getAccessoryWidth(topviewCentreLine, leftOfVehicleRearWidth, leftOfBdoyWidthExternal) {
        //Dim totalWidth As Double = 0

        //For Each accessory As FileLoadingAccessory In Me

        //If TypeOf (accessory) Is FileLoadingAccessoryBody Then

        //Dim x As FileLoadingAccessoryBody = accessory

        //totalWidth = Math.Max(totalWidth, x.ActualWidth)

        //End If

        //Next

        //Return totalWidth
        var totalWidth = 0;

        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                totalWidth = Math.max(totalWidth, accessory.getActualWidth());
            } else {
                totalWidth = Math.max(totalWidth, accessory.getWidth());
            }
        });
        totalWidth = Math.max(totalWidth, Math.max(topviewCentreLine, getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfVehicleRearWidth, leftOfBdoyWidthExternal)) + Math.max(topviewCentreLine, getAccessoryTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfVehicleRearWidth, leftOfBdoyWidthExternal)));
        return totalWidth;
    }

    function count(includingBody) {
        var counter = 0;

        _accessories.forEach(function (accessory){
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (includingBody === true) {
                    counter += 1;
                }
            }else {
                counter += 1
            }
        });





        return counter;
    }

    function item(index, includingBody) {

        var counter = -1;
        var accessoryToReturn = null;

        _accessories.forEach(function (accessory){
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (includingBody === true) {
                    counter += 1;
                }
            } else {
                counter += 1;
            }

            if( index === counter ) {
                accessoryToReturn = accessory;
            }
        });

        return accessoryToReturn;

    }

    function getVehicleFrontAxleOtherAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap)  {


        if (getCount(false) === 2) {

            var accessory = item(1, false);

            return accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart,
                wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);

        } else {

            return getVehicleFrontAxleTotalAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) -
                getAccessory1().getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(getAccessory1(), frontOverhang, axle1FrontToWheelbaseTheoreticalStart,
                    wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            // return getVehicleFrontAxleTotalAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) -
            //     0;

        }


    }

    function getVehicleRearAxleOtherAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        if (getCount(false) === 2) {

            var accessory = item(1, false);

            return accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart,
                wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical)

        } else {

            return getVehicleRearAxleTotalAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) -
                getAccessory1().getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(getAccessory1(), frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical,
                    bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
        }

    }

    function getVehicleTotalOtherAccessories() {
        if( getCount(false) === 2) {

            var accessory = item(1, false);

            return accessory.getTotalLoad();

        } else {

            return getTotalTotalAccessories() - getAccessory1().getTotalLoad();

        }
    }

    function getVehicleFrontAxleTotalAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {


        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                total += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            }
        });

        return total;

    }

    function getVehicleFrontAxleTotalOtherAccessoriesCombined(accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {


        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                total += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                total += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                total += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            }
        });

        return total;

    }

    function getVehicleFrontLeftAxleTotalOtherAccessoriesCombined(trackWidthFront, accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            let includeInTotal = false;
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                includeInTotal = true;
            }
            if(includeInTotal) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getFrontLeftAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthFront), trackWidthFront);
            }
            
        });

        return total;

    }

    function getVehicleFrontRightAxleTotalOtherAccessoriesCombined(trackWidthFront, accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            let includeInTotal = false;
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                includeInTotal = true;
            }
            if(includeInTotal) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getFrontRightAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthFront), trackWidthFront);
            }
            
        });

        return total;

    }


    function getVehicleRearAxleTotalOtherAccessoriesCombined(accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {


        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                total += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                total += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                total += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            }
        });

        return total;

    }

    function getVehicleRearLeftAxleTotalOtherAccessoriesCombined(trackWidthRear, accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            let includeInTotal = false;
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                includeInTotal = true;
            }
            if(includeInTotal) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getRearLeftAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthRear), trackWidthRear);
            }
            
        });

        return total;

    }

    function getVehicleRearRightAxleTotalOtherAccessoriesCombined(trackWidthRear, accessoriesToExclude, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        var otherCounter = 0, fuelTankCounter = 0;
        _accessories.forEach(function (accessory) {
            if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                otherCounter++;
            } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                fuelTankCounter++;
            }
            let includeInTotal = false;
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.OTHER && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FUELTANK && !accessoriesToExclude[accessory.getAccessoryType()]) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < otherCounter)) {
                includeInTotal = true;
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK && (!accessoriesToExclude[accessory.getAccessoryType()] || accessoriesToExclude[accessory.getAccessoryType()] < fuelTankCounter)) {
                includeInTotal = true;
            }
            if(includeInTotal) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getRearRightAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthRear), trackWidthRear);
            }
            
        });

        return total;

    }

    function getTrailerFrontAxleTotalAccessories(distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                total += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
            }
        });

        return total;
        /*
        
        Dim total As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If Not TypeOf (accessory) Is FileLoadingAccessoryBody Then

                total += accessory.FrontAxleLoad(GetDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, _
                                                                                                    distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, _
                                                                                                    distanceFromStartOfTrailerToLastAxle), _
                                                    wheelbaseTheoretical)
            End If

        Next

        Return total
        */
    }

    function getTrailerRearAxleTotalAccessories(distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                total += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
            }
        });

        return total;
        /*
        Dim total As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If Not TypeOf (accessory) Is FileLoadingAccessoryBody Then

                total += accessory.RearAxleLoad(GetDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, _
                                                                                                    distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, _
                                                                                                    distanceFromStartOfTrailerToLastAxle), _
                                                wheelbaseTheoretical)
            End If

        Next

        Return total
        */
    }

    function getTrailerRearLeftAxleTotalAccessories(trackWidth, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getRearLeftAxleLoad) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
                total += accessory.getRearLeftAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForTrailer(accessory, trackWidth), trackWidth);
            }
        });

        return total;
        
    }

    function getTrailerRearRightAxleTotalAccessories(trackWidth, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getRearRightAxleLoad) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
                total += accessory.getRearRightAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForTrailer(accessory, trackWidth), trackWidth);
            }
        });

        return total;
        
    }

    function getTrailerFrontLeftAxleTotalAccessories(trackWidth, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getFrontLeftAxleLoad) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
                total += accessory.getFrontLeftAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForTrailer(accessory, trackWidth), trackWidth);
            }
        });

        return total;
        
    }

    function getTrailerFrontRightAxleTotalAccessories(trackWidth, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getFrontRightAxleLoad) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
                total += accessory.getFrontRightAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForTrailer(accessory, trackWidth), trackWidth);
            }
        });

        return total;
        
    }

    function getVehicleRearAxleTotalAccessories(frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {



        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                total += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
            }
        });

        return total;
        /*
        Public ReadOnly Property VehicleRearAxleTotalAccessories(ByVal frontOverhang As Double, _
                                                            ByVal axle1FrontToWheelbaseTheoreticalStart As Double, _
                                                            ByVal wheelbasetheoretical As Double, _
                                                            ByVal bumperToBackOfCab As Double, _
                                                            ByVal bodyGap As Double) As Double
    Get

        Dim total As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If Not TypeOf (accessory) Is FileLoadingAccessoryBody Then

                total += accessory.RearAxleLoad(GetDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, _
                                                                                                    frontOverhang, _
                                                                                                    axle1FrontToWheelbaseTheoreticalStart, _
                                                                                                    wheelbasetheoretical, _
                                                                                                    bumperToBackOfCab, _
                                                                                                    bodyGap), _
                                                wheelbasetheoretical)
            End If

        Next

        Return total

    End Get
End Property


        
        */
    }


    function getVehicleFrontLeftAxleTotalAccessories(trackWidthFront, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getFrontLeftAxleLoad) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getFrontLeftAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthFront), trackWidthFront);
            }
        });

        return total;
        
    }

    function getVehicleFrontRightAxleTotalAccessories(trackWidthFront, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getFrontRightAxleLoad) {
                let frontAxleLoad = accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getRearRightAxleLoad(frontAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthFront), trackWidthFront);
            }
        });

        return total;
        
    }

    function getVehicleRearLeftAxleTotalAccessories(trackWidthRear, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getRearLeftAxleLoad) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getRearLeftAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthRear), trackWidthRear);
            }
        });

        return total;
        
    }

    function getVehicleRearRightAxleTotalAccessories(trackWidthRear, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {

        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getRearRightAxleLoad) {
                let rearAxleLoad = accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap), wheelbaseTheoretical);
                total += accessory.getRearRightAxleLoad(rearAxleLoad, getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, trackWidthRear), trackWidthRear);
            }
        });

        return total;
        
    }


    function getTotalTotalAccessories() {


        var total = 0;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                total += accessory.getTotalLoad();
            }
        });

        return total;
        /*
        Public ReadOnly Property VehicleTotalTotalAccessories() As Double
    Get

        Dim total As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If Not TypeOf (accessory) Is FileLoadingAccessoryBody Then
                total += accessory.TotalLoad
            End If

        Next

        Return total

    End Get
End Property
        
        */
    }

    function getTrailerFrontAxleTotalRemainingAccessories(accessoriesToExclude, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {
        var totalToExclude = 0;

        accessoriesToExclude.forEach(function (accessory) {
            totalToExclude += accessory.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
        });
        var tempBody = getBody();
        var bodyFrontAxleLoad = 0;
        if (tempBody !== null) {
            bodyFrontAxleLoad = tempBody.getFrontAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
        }

        return (getTrailerFrontAxleTotalAccessories(distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) + bodyFrontAxleLoad) - totalToExclude;
    }

    function getTrailerRearAxleTotalRemainingAccessories(accessoriesToExclude, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) {
        var totalToExclude = 0;

        accessoriesToExclude.forEach(function (accessory) {
            totalToExclude += accessory.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
        });

        var tempBody = getBody();
        var bodyRearAxleLoad = 0;
        if (tempBody !== null) {
            bodyRearAxleLoad = tempBody.getRearAxleLoad(getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle), wheelbaseTheoretical);
        }

        return (getTrailerRearAxleTotalAccessories(distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle, wheelbaseTheoretical) + bodyRearAxleLoad) - totalToExclude;
    }

    function getTrailerTotalTotalRemainingAccessories(accessoriesToExclude) {
        var totalToExclude = 0;

        accessoriesToExclude.forEach(function (accessory) {
            totalToExclude += accessory.getTotalLoad();
        });

        var tempBody = getBody();
        var bodyTotalAxleLoad = 0;
        if (tempBody !== null) {
            bodyTotalAxleLoad = tempBody.getTotalLoad();
        }


        return (getTotalTotalAccessories() + bodyTotalAxleLoad) - totalToExclude;
    }

    function getAccessoryTopMostPoint(chassisHeight) {
        //#unfinished, currently only porting code relating to VAN body, CRANE, OTHER, FRIDGE, TAILLIFT rest to be ported later

        var topPoint = 0;

        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (accessory.getType() === config.BODY_TYPES.DROPSIDE || accessory.getType() === config.BODY_TYPES.FLATDECK || accessory.getType() === config.BODY_TYPES.TIPPER) {
                    topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getHeadboardHeight());
                    topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getTailboardHeight());
                } else if (accessory.getType() === config.BODY_TYPES.TIMBER) {
                    topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getHeadboardHeight());
                    topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getTailboardHeight());
                    getBody().getBodyUprightHolder().getBodyUprights().forEach(function (upright) {
                        topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + getBody().getSubframeHeight() + getBody().getSubstructureHeight() + upright.getHeight());
                    });
                } else if (accessory.getType() === config.BODY_TYPES.HOOKLIFT) {
                    if (accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                        var tempHooklift = getHooklift();
                        topPoint = Math.max(topPoint, chassisHeight + tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight() + accessory.getAboveBelowChassis() + accessory.getActualExternalHeight());
                    } else {
                        topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getHeadboardHeight());
                        topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getSubframeHeight() + accessory.getSubstructureHeight() + accessory.getTailboardHeight());
                    }
                    
                } else if (accessory.getType() === config.BODY_TYPES.SERVICE /*|| accessory.getType() === config.BODY_TYPES.COMPACTOR || accessory.getType() === config.BODY_TYPES.BEAVERTAIL || accessory.getType() === config.BODY_TYPES.OTHER*/) {
                    topPoint = Math.max(topPoint, chassisHeight - accessory.getBottomOfBodyToTopOfChassis() + accessory.getActualExternalHeight());
                } else {
                    topPoint = Math.max(topPoint, chassisHeight + accessory.getAboveBelowChassis() + accessory.getActualExternalHeight());
                }



            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
                topPoint = Math.max(topPoint, chassisHeight + accessory.getActualHeight() + accessory.getAboveBelowChassis());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                topPoint = Math.max(topPoint, chassisHeight + accessory.getVerticalGap() + accessory.getHeight());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                let tempBody = getBody();
                topPoint = Math.max(topPoint, chassisHeight + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - accessory.getMountingPosition());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                if (accessory.getType() === config.TAILLIFT_TYPES.COLUMN_LOWER_BOX || accessory.getType() === config.TAILLIFT_TYPES.CANTILEVER) {
                    if (_parentChassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && _parentChassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        topPoint = Math.max(topPoint, _parentChassisObject.getFloorHeight() + accessory.getAboveOrBelowFloor() + accessory.getPlatformHeight());
                    } else {
                        topPoint = Math.max(topPoint, chassisHeight + getBody().getSubframeHeight() + getBody().getSubstructureHeight() + accessory.getAboveOrBelowFloor() + accessory.getPlatformHeight());
                    }


                }
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                //the fifth has no impact on the height calculation
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
                //the hitch has no impact on the height calculation
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
                topPoint = Math.max(topPoint, chassisHeight + accessory.getActualHeight() + accessory.getVerticalGap());
            }
        });

        return topPoint;
        /*
        Dim topPoint As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If TypeOf (accessory) Is FileLoadingAccessoryBody Then

                Dim x As FileLoadingAccessoryBody = accessory

                If x.BodyType = BodyTemplateTypes.Dropside Or _
                    x.BodyType = BodyTemplateTypes.Flatdeck Or _
                    x.BodyType = BodyTemplateTypes.Tipper Then
                    topPoint = Math.Max(topPoint, chassisHeight + x.SubframeHeight + x.SubstructureHeight + x.HeadboardHeight)
                    topPoint = Math.Max(topPoint, chassisHeight + x.SubframeHeight + x.SubstructureHeight + x.TailboardHeight)
                ElseIf x.BodyType = BodyTemplateTypes.Timber Then
                    topPoint = Math.Max(topPoint, chassisHeight + x.SubframeHeight + x.SubstructureHeight + x.HeadboardHeight)
                    topPoint = Math.Max(topPoint, chassisHeight + x.SubframeHeight + x.SubstructureHeight + x.TailboardHeight)
                    For Each upright As FileLoadingAccessoryBodyUpright In x.Uprights
                        topPoint = Math.Max(topPoint, chassisHeight + x.SubframeHeight + x.SubstructureHeight + upright.Height)
                    Next
                Else
                    topPoint = Math.Max(topPoint, chassisHeight + x.ActualExternalHeight)
                End If

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then

                Dim x As FileLoadingAccessoryCrane = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.ActualHeight)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrewCab Then

                Dim x As FileLoadingAccessoryCrewCab = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.ActualHeight)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFairing Then
                'the fairing has no impact on the height calculation as it cannot be higher than the body
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFifthWheel Then
                'the fifth wheel has no impact on the height  calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then
                'the fridge has no impact on the height calculation as it cannot be higher than the body
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFuelTank Then

                Dim x As FileLoadingAccessoryFuelTank = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.ActualHeight)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHeadboard Then

                Dim x As FileLoadingAccessoryHeadboard = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.ActualHeight)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then
                'the hitch has no impact on the height calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then

                Dim x As FileLoadingAccessoryTaillift = accessory

                If x.Type = TailliftTypes.ColumnLowerBox Or _
                    x.Type = TailliftTypes.Cantilever Then

                    topPoint = Math.Max(topPoint, chassisHeight + BodySubframeHeight + x.Height)

                End If

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar Then
                'the bullbar has no impact on the height calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

                Dim x As FileLoadingAccessoryRollover = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.Height)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

                Dim x As FileLoadingAccessoryOther = accessory

                topPoint = Math.Max(topPoint, chassisHeight + x.VerticalGap + x.Height)

            End If

        Next

        Return topPoint
        */
    }

    function getAccessoryLeftMostPoint(bumperToBackOfCab, frontOverhang, passedInIncludeBullbar) {

        var includeBullbar = passedInIncludeBullbar !== undefined ? passedInIncludeBullbar : true;

        var leftPoint = 999999;

        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    var tempHooklift = getHooklift();
                    leftPoint = Math.min(leftPoint, bumperToBackOfCab + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap());
                } else {
                    leftPoint = Math.min(leftPoint, bumperToBackOfCab + accessory.getHorizontalGap());
                }
                
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
                leftPoint = Math.min(leftPoint, bumperToBackOfCab + accessory.getHorizontalGap());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                //'fridge has been excluded for trailers as it should not affect trailer length

                if (parentChassis.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    leftPoint = Math.min(leftPoint, bumperToBackOfCab + getBodyGap() - accessory.getProtrusion() - accessory.getFromFrontOfBody());
                }

            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                if (accessory.getAccessorySubType() === config.ACCESSORY_TYPES.BULLBAR) {
                    if (includeBullbar) {
                        leftPoint = Math.min(leftPoint, bumperToBackOfCab + accessory.getHorizontalGap());
                    }
                } else {
                    leftPoint = Math.min(leftPoint, bumperToBackOfCab + accessory.getHorizontalGap());
                }

            }
        });
        return leftPoint;
        //#unfinisehd, currently only supports body, crane, frdige and other... taillift, fifthwheel, hitch have no effect here 

        /*
            Dim leftPoint As Double = 99999

        For Each accessory As FileLoadingAccessory In Me

            If TypeOf (accessory) Is FileLoadingAccessoryBody Then
                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + accessory.HorizontalGap)
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then
                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + accessory.HorizontalGap)
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrewCab Then
                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + accessory.HorizontalGap)
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFairing Then

                Dim x As FileLoadingAccessoryFairing = accessory

                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + BodyGap - x.Protrusion)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFifthWheel Then
                'the fifth wheel has no impact on the left most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then

                'fridge has been excluded for trailers as it should not affect trailer length
                If accessory.Parent = ApplicationGlobals.ParentTypes.Vehicle Then
                    Dim x As FileLoadingAccessoryFridge = accessory

                    leftPoint = Math.Min(leftPoint, bumperToBackOfCab + BodyGap - x.Protrusion)
                End If

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFuelTank Then
                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + accessory.HorizontalGap)
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHeadboard Then
                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + accessory.HorizontalGap)
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then
                'the hitch has no impact on the left most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then
                'the taillift has no impact on the left most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar And includeBullbar Then

                Dim x As FileLoadingAccessoryBullbar = accessory

                leftPoint = Math.Min(leftPoint, x.Protrusion * -1)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

                Dim x As FileLoadingAccessoryRollover = accessory

                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + x.HorizontalGap - x.Protrusion)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

                Dim x As FileLoadingAccessoryOther = accessory

                leftPoint = Math.Min(leftPoint, bumperToBackOfCab + x.HorizontalGap)

            End If

        Next

        Return leftPoint
        */
    }

    function getRightMostAccessory(bumperToBackOfCab) {

        var currentRightMostAccessory = null;
        var currentRightMostAccessoryPoint = bumperToBackOfCab;


        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
                if (accessory.getHorizontalGap() + accessory.getOffset() + accessory.getFunnelLength() > currentRightMostAccessoryPoint) {
                    currentRightMostAccessory = accessory;
                    currentRightMostAccessoryPoint = accessory.getHorizontalGap() + accessory.getOffset() + accessory.getFunnelLength();
                }
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                if (bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getFromBackOfBody() + accessory.getPlatformProtrusion() > currentRightMostAccessoryPoint) {
                    currentRightMostAccessory = accessory;
                    currentRightMostAccessoryPoint = bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getFromBackOfBody() + accessory.getPlatformProtrusion();
                }
            }else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                if (bumperToBackOfCab + getBodyGap() + accessory.getFromFrontOfBody() > currentRightMostAccessoryPoint) {
                    currentRightMostAccessory = accessory;
                    currentRightMostAccessoryPoint = bumperToBackOfCab + getBodyGap() + accessory.getFromFrontOfBody();
                }
            } else {
                if (bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getLength() > currentRightMostAccessoryPoint) {
                    currentRightMostAccessory = accessory;
                    currentRightMostAccessoryPoint = bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getLength();
                }
            }

        });


        return currentRightMostAccessory;
    }

    function getAccessoryRightMostPoint(bumperToBackOfcab, frontOverhang, wheelbaseTheoreticalEnd, ignoreItemsForBumperToBackOfRearMostEquipment) {

        var rightPoint = 0;
        var bBC = bumperToBackOfcab;
        var wbte = wheelbaseTheoreticalEnd;

        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (accessory.getType() === config.BODY_TYPES.HOOKLIFT) {
                    if (accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                        var tempHooklift = getHooklift();
                        rightPoint = Math.max(rightPoint, bBC + getBodyGap() + accessory.getActualLength());
                    } else {
                        rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + Math.max(accessory.getActualLength(), accessory.getSubframeLength()) + accessory.getRampLength() + accessory.getRampPlatesProtrusion());
                    }
                } else {
                    rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + accessory.getActualLength() + accessory.getRampLength() + accessory.getRampPlatesProtrusion());
                }

            }else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
                rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + accessory.getLength());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                if (ignoreItemsForBumperToBackOfRearMostEquipment === undefined || ignoreItemsForBumperToBackOfRearMostEquipment === false) {
                    rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + accessory.getLength());
                }
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                rightPoint = Math.max(rightPoint, bBC + getBodyGap() + (-accessory.getFromFrontOfBody()));
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + accessory.getFromBackOfBody() + accessory.getPlatformProtrusion());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                if (wbte !== undefined && wbte > 0) {
                    if (accessory.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                        rightPoint = Math.max(rightPoint, wbte - accessory.getOffset() + (accessory.getLength() - accessory.getFrontOverhang()));
                    } else {
                        rightPoint = Math.max(rightPoint, wbte - accessory.getOffset() + (config.fifthWheelSubframeBaseLength / 2));
                    }
                }
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
                if (ignoreItemsForBumperToBackOfRearMostEquipment === undefined || ignoreItemsForBumperToBackOfRearMostEquipment === false) {
                    rightPoint = Math.max(rightPoint, accessory.getHorizontalGap() + accessory.getOffset() + accessory.getFunnelLength());
                }
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
                if (ignoreItemsForBumperToBackOfRearMostEquipment === undefined || ignoreItemsForBumperToBackOfRearMostEquipment === false) {
                    rightPoint = Math.max(rightPoint, bBC + accessory.getHorizontalGap() + accessory.getLength());
                }
            }
        });


        return rightPoint;

        //#unfinished, only supports body, crane, other, fridge, taillift so far

        /*
        Dim rightPoint As Double = 0

        For Each accessory As FileLoadingAccessory In Me

            If TypeOf (accessory) Is FileLoadingAccessoryBody Then

                Dim x As FileLoadingAccessoryBody = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.ActualLength)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then

                Dim x As FileLoadingAccessoryCrane = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.Length)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrewCab Then

                Dim x As FileLoadingAccessoryCrewCab = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.Length)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFairing Then
                'the fairing has no impact on the right most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFifthWheel Then
                'the fifth wheel has no impact on the right most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then
                'the fridge has no impact on the right most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFuelTank Then

                Dim x As FileLoadingAccessoryFuelTank = accessory

                If x.Type = FuelTankTypes.BehindCab Then
                    rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                            x.Length)
                Else
                    rightPoint = Math.Max(rightPoint, frontOverhang + x.HorizontalGap + _
                                            x.Length)
                End If

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHeadboard Then

                Dim x As FileLoadingAccessoryHeadboard = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.Length)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then
                'the hitch has no impact on the right most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then

                Dim x As FileLoadingAccessoryTaillift = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.RearProtrusion)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar Then
                'the bullbar has no impact on the right most calculation 
            ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

                Dim x As FileLoadingAccessoryRollover = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + _
                                        x.Length)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

                Dim x As FileLoadingAccessoryOther = accessory

                rightPoint = Math.Max(rightPoint, bumperToBackOfCab + x.HorizontalGap + x.Length)

            End If

        Next

        Return rightPoint
        */
    }

    function getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfVehicleRearWidth, leftOfBdoyWidthExternal) {

        var bottomPoint = -999999;
        var topViewCentreLine = topviewCentreLine;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                bottomPoint = Math.max(bottomPoint, accessory.getActualWidth()/2);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                bottomPoint = Math.max(bottomPoint, (topViewCentreLine - leftOfBdoyWidthExternal) - accessory.getFromLeftOfBodyWidthExternal());
            } else {
                var accessoryBottomPointFromCentreLine = leftOfVehicleRearWidth + accessory.getFromLeftOfVehicleRearWidth();
                //if (accessoryBottomPointFromCentreLine < 0) {
                //    accessoryBottomPointFromCentreLine = topViewCentreLine - accessoryBottomPointFromCentreLine;
                //} 
                accessoryBottomPointFromCentreLine = topViewCentreLine - accessoryBottomPointFromCentreLine;
                //bottomPoint = Math.max(bottomPoint, (topViewCentreLine - leftOfVehicleRearWidth) - accessory.getFromLeftOfVehicleRearWidth());
                bottomPoint = Math.max(bottomPoint, accessoryBottomPointFromCentreLine);
            }
        });
        return bottomPoint;

    }

    function getAccessoryTopMostPointDistanceFromTopViewCentreLine(topviewCentreLine, leftOfVehicleRearWidth, leftOfBdoyWidthExternal) {

        var topPoint = -999999;
        var topViewCentreLine = topviewCentreLine;
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                topPoint = Math.max(topPoint, accessory.getActualWidth() / 2);
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                topPoint = Math.max(topPoint, -((topViewCentreLine -leftOfBdoyWidthExternal) - accessory.getFromLeftOfBodyWidthExternal()) + accessory.getWidth());
            } else {
                var accessoryBottomPointFromCentreLine = leftOfVehicleRearWidth + accessory.getFromLeftOfVehicleRearWidth();
                //if (accessoryBottomPointFromCentreLine < 0) {
                accessoryBottomPointFromCentreLine = topViewCentreLine - accessoryBottomPointFromCentreLine;
                //}
                var accessoryTopPointFromCentreLine = accessory.getWidth() - accessoryBottomPointFromCentreLine;
                //topPoint = Math.max(topPoint, -((topViewCentreLine - leftOfVehicleRearWidth) - accessory.getFromLeftOfVehicleRearWidth()) + accessory.getWidth());
                topPoint = Math.max(topPoint, accessoryTopPointFromCentreLine);
            }
        });
        return topPoint;

    }

    function getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory, frontOverhang, bumperToBackOfCab, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical) {
        //# unfinished, parts need to be ported as accessory support added

        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                return (bumperToBackOfCab + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart);
            } else {
                return (bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart);
            }
            
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
            return ((bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
            return ((bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
            return ((bumperToBackOfCab + getBodyGap() + accessory.getActualHorizontalCOG() - accessory.getProtrusion() - accessory.getFromFrontOfBody()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            if (_parentChassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && _parentChassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                return ((_parentChassisObject.getOverallLength() + accessory.getFromBackOfBody() + accessory.getActualHorizontalCOG() - accessory.getMechanismLength()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
            } else {
                return ((bumperToBackOfCab + getBodyGap() + getBody().getActualLength() + accessory.getFromBackOfBody() + accessory.getActualHorizontalCOG() - accessory.getMechanismLength()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
            }

        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
            /*
                Dim fifthwheel As FileLoadingAccessoryFifthWheel = DirectCast(accessory, FileLoadingAccessoryFifthWheel)
        mass = fifthwheel.Mass

        Return wheelbaseTheoretical - fifthwheel.HorizontalGap - fifthwheel.FrontOverhang + fifthwheel.ActualHorizontalCOG
            */
            //#unfinished, this strays from SL version due to HTML5 ignoring HorizontalGap accessory attribute for Fifth Wheel
            return wheelbaseTheoretical - (accessory.getOffset() + (accessory.getLength() / 2)) + accessory.getActualHorizontalCOG();

        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {

            return wheelbaseTheoretical + (accessory.getHorizontalGap() - frontOverhang - axle1FrontToWheelbaseTheoreticalStart - wheelbaseTheoretical) + (accessory.getOffset() - (accessory.getMountingLength() + accessory.getCouplingOffset())) + accessory.getActualHorizontalCOG();

        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            return ((bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart));
        }

        return 0;
        /*
        If TypeOf accessory Is FileLoadingAccessoryBody Then

        Dim body As FileLoadingAccessoryBody = DirectCast(accessory, FileLoadingAccessoryBody)
        mass = body.ActualMass

        Return ((bumperToBackOfCab + body.HorizontalGap + body.ActualHorizontalCOG) -
                    (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryCrane Then

        Dim crane As FileLoadingAccessoryCrane = DirectCast(accessory, FileLoadingAccessoryCrane)
        mass = crane.Mass

        Return ((bumperToBackOfCab + crane.HorizontalGap + crane.ActualHorizontalCOG) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryCrewCab Then

        Dim crewCab As FileLoadingAccessoryCrewCab = DirectCast(accessory, FileLoadingAccessoryCrewCab)
        mass = crewCab.Mass

        Return ((bumperToBackOfCab + crewCab.HorizontalGap + crewCab.ActualHorizontalCOG) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryFairing Then

        Dim fairing As FileLoadingAccessoryFairing = DirectCast(accessory, FileLoadingAccessoryFairing)
        mass = fairing.Mass

        Return ((bumperToBackOfCab + Body.HorizontalGap + fairing.ActualHorizontalCOG - fairing.Protrusion) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryFifthWheel Then

        Dim fifthwheel As FileLoadingAccessoryFifthWheel = DirectCast(accessory, FileLoadingAccessoryFifthWheel)
        mass = fifthwheel.Mass

        Return wheelbaseTheoretical - fifthwheel.HorizontalGap - fifthwheel.FrontOverhang + fifthwheel.ActualHorizontalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryFridge Then

        Dim fridge As FileLoadingAccessoryFridge = DirectCast(accessory, FileLoadingAccessoryFridge)
        mass = fridge.Mass

        Return ((bumperToBackOfCab + Body.HorizontalGap + fridge.ActualHorizontalCOG - fridge.Protrusion) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryFuelTank Then

        Dim fuelTank As FileLoadingAccessoryFuelTank = DirectCast(accessory, FileLoadingAccessoryFuelTank)
        mass = fuelTank.Mass

        If fuelTank.Type = FuelTankTypes.BehindCab Then
            Return ((bumperToBackOfCab + fuelTank.HorizontalGap + fuelTank.ActualHorizontalCOG) -
                                (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))
        Else
            Return fuelTank.HorizontalGap
        End If

    ElseIf TypeOf accessory Is FileLoadingAccessoryHeadboard Then

        Dim headBoard As FileLoadingAccessoryHeadboard = DirectCast(accessory, FileLoadingAccessoryHeadboard)
        mass = headBoard.Mass

        Return ((bumperToBackOfCab + headBoard.HorizontalGap + headBoard.ActualHorizontalCOG) -
                            (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf accessory Is FileLoadingAccessoryHitch Then

        Dim hitch As FileLoadingAccessoryHitch = DirectCast(accessory, FileLoadingAccessoryHitch)
        mass = hitch.Mass

        Return (wheelbaseTheoretical + hitch.HorizontalGap)

    ElseIf TypeOf accessory Is FileLoadingAccessoryTaillift Then

        Dim taillift As FileLoadingAccessoryTaillift = DirectCast(accessory, FileLoadingAccessoryTaillift)
        mass = taillift.Mass

        Return ((bumperToBackOfCab + Body.HorizontalGap + Body.ActualLength + taillift.ActualHorizontalCOG - taillift.InternalMountingLength) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar Then

        Dim bullbar As FileLoadingAccessoryBullbar = accessory
        mass = bullbar.Mass

        Return -(bullbar.Protrusion - bullbar.ActualHorizontalCOG + frontOverhang + axle1FrontToWheelbaseTheoreticalStart)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

        Dim rollover As FileLoadingAccessoryRollover = accessory
        mass = rollover.Mass

        Return (bumperToBackOfCab + rollover.HorizontalGap - rollover.Protrusion + rollover.ActualHorizontalCOG) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart)

    ElseIf TypeOf accessory Is FileLoadingAccessoryOther Then

        Dim other As FileLoadingAccessoryOther = DirectCast(accessory, FileLoadingAccessoryOther)
        mass = other.Mass

        Return ((bumperToBackOfCab + other.HorizontalGap + other.ActualHorizontalCOG) -
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart))

    End If

    Return 0
        
        */
    }

    function getBodyGap() {
        var tempBody = getBody();
        
        if (tempBody !== null) {
            var hookOffset = 0, hookliftGap = 0;
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                if (tempHooklift !== null) {
                    hookOffset = tempHooklift.getHookOffset();
                    hookliftGap = tempHooklift.getHorizontalGap();
                }
            }
            return tempBody.getHorizontalGap() + hookOffset + hookliftGap;
        } else {
            return 0;
        }
    }

    function getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, frontOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, bumperToBackOfCab, bodyGap) {
        //#unfinished, only part for body, crane, other, fridge, taillift ported so far
        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY || accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (bumperToBackOfCab + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG());
            } else {
                return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG());
            }
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
            return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (bumperToBackOfCab + bodyGap - accessory.getProtrusion() - accessory.getFromFrontOfBody() + accessory.getActualHorizontalCOG());
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            if (_parentChassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && _parentChassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (_parentChassisObject.getOverallLength() + accessory.getFromBackOfBody() - accessory.getMechanismLength() + accessory.getActualHorizontalCOG());
            } else {
                var tempBody = getBody();
                return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (bumperToBackOfCab + getBodyGap() + tempBody.getActualLength() + accessory.getFromBackOfBody() - accessory.getMechanismLength() + accessory.getActualHorizontalCOG());
            }
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
            //#unfinished, strays from SL due to not using Horizontal gap attribute
            if (accessory.getOffset() < 0) {
                return (Math.abs(accessory.getOffset()) - accessory.getFrontOverhang()) + accessory.getActualHorizontalCOG();
            } else {
                return (accessory.getOffset() + accessory.getFrontOverhang()) - accessory.getActualHorizontalCOG();
            }

        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
            return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical + (accessory.getHorizontalGap() - frontOverhang - axle1FrontToWheelbaseTheoreticalStart - wheelbaseTheoretical) + accessory.getOffset() - accessory.getMountingLength() - accessory.getCouplingOffset() + accessory.getActualHorizontalCOG());
        }


        return 0;
        /*
        Public Function GetDistanceFromWheelbaseTheoreticalEndForVehicle(ByVal accessory As FileLoadingAccessory, _
                                                                        ByVal frontOverhang As Double, _
                                                                        ByVal axle1FrontToWheelbaseTheoreticalStart As Double, _
                                                                        ByVal wheelbaseTheoretical As Double, _
                                                                        ByVal bumperToBackOfCab As Double, _
                                                                        ByVal bodyGap As Double) As Double

            If TypeOf (accessory) Is FileLoadingAccessoryBody Then

                Dim x As FileLoadingAccessoryBody = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - _
                        (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then

                Dim x As FileLoadingAccessoryCrane = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrewCab Then

                Dim x As FileLoadingAccessoryCrewCab = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - _
                        (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFairing Then

                Dim x As FileLoadingAccessoryFairing = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + bodyGap - x.Protrusion + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFifthWheel Then

                Dim x As FileLoadingAccessoryFifthWheel = accessory

                Return x.HorizontalGap - x.FrontOverhang + x.ActualHorizontalCOG

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then

                Dim x As FileLoadingAccessoryFridge = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + bodyGap - x.Protrusion + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryFuelTank Then

                Dim x As FileLoadingAccessoryFuelTank = accessory

                If x.Type = FuelTankTypes.BehindCab Then
                    Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                            (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)
                Else
                    Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                            (frontOverhang + x.HorizontalGap + x.ActualHorizontalCOG)
                End If

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHeadboard Then

                Dim x As FileLoadingAccessoryHeadboard = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then

                Dim x As FileLoadingAccessoryHitch = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) - _
                        (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical + x.HorizontalGap)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then

                Dim x As FileLoadingAccessoryTaillift = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + x.HorizontalGap - x.InternalMountingLength + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryBullbar Then

                Dim x As FileLoadingAccessoryBullbar = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) +
                        (x.Protrusion - x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryRollover Then

                Dim x As FileLoadingAccessoryRollover = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + x.HorizontalGap - x.Protrusion + x.ActualHorizontalCOG)

            ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

                Dim x As FileLoadingAccessoryOther = accessory

                Return (frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical) -
                        (bumperToBackOfCab + x.HorizontalGap + x.ActualHorizontalCOG)

            Else
                Return 0
            End If

        End Function
            */
    }

    function getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalEnd, distanceFromStartOfTrailerToLastAxle) {

        //unfinished, only supporting other, crane, body taillift, fridge

        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY || accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG());
            } else {
                return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (accessory.getHorizontalGap() + accessory.getActualHorizontalCOG());
            }
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
            return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (getBodyGap() - accessory.getProtrusion() - accessory.getFromFrontOfBody() + accessory.getActualHorizontalCOG());
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            var tempBody = getBody();
            return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (getBodyGap() + tempBody.getActualLength() + accessory.getFromBackOfBody() - accessory.getMechanismLength() + accessory.getActualHorizontalCOG());
        }


        return 0;

        /*
            If TypeOf (accessory) Is FileLoadingAccessoryBody Then

        Dim x As FileLoadingAccessoryBody = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (x.HorizontalGap + x.ActualHorizontalCOG)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryCrane Then

        Dim x As FileLoadingAccessoryCrane = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (x.HorizontalGap + x.ActualHorizontalCOG)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryFridge Then

        Dim x As FileLoadingAccessoryFridge = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (BodyGap - x.Protrusion + x.ActualHorizontalCOG)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryHitch Then

        Dim x As FileLoadingAccessoryHitch = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - _
                (distanceFromStartOfTrailerToLastAxle + x.HorizontalGap)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryTaillift Then

        Dim x As FileLoadingAccessoryTaillift = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd -
                (x.HorizontalGap - x.InternalMountingLength + x.ActualHorizontalCOG)

    ElseIf TypeOf (accessory) Is FileLoadingAccessoryOther Then

        Dim x As FileLoadingAccessoryOther = accessory

        Return distanceFromStartOfTrailerToWheelbaseTheoreticalEnd - (x.HorizontalGap + x.ActualHorizontalCOG)

    Else
        Return 0
    End If
        
        */
    }

    function getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, distanceFromStartOfTrailerToWheelbaseTheoreticalStart) {

        //unfinished, only supporting other, crane, body taillift, fridge

        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY || accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                return (tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - distanceFromStartOfTrailerToWheelbaseTheoreticalStart;
            } else {
                return (accessory.getHorizontalGap() + accessory.getActualHorizontalCOG()) - distanceFromStartOfTrailerToWheelbaseTheoreticalStart;
            }
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
            return (getBodyGap() - accessory.getProtrusion() - accessory.getFromFrontOfBody() + accessory.getActualHorizontalCOG()) - distanceFromStartOfTrailerToWheelbaseTheoreticalStart;
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            var tempBody = getBody();
            return (getBodyGap() + tempBody.getActualLength() + accessory.getFromBackOfBody() - accessory.getMechanismLength() + accessory.getActualHorizontalCOG()) - distanceFromStartOfTrailerToWheelbaseTheoreticalStart;
        }


        return 0;


    }


    function getDistanceFromGroundForChassis(accessory, chassisHeight, subframeHeight) {
        //#unfinished, only supports body, crane, other, fridge, taillift, hitch, fifthwheel so far ... 

        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (accessory.getType() === config.BODY_TYPES.SERVICE /*|| accessory.getType() === config.BODY_TYPES.COMPACTOR || accessory.getType() === config.BODY_TYPES.BEAVERTAIL || accessory.getType() === config.BODY_TYPES.OTHER*/) {
                return chassisHeight - accessory.getBottomOfBodyToTopOfChassis() + accessory.getActualVerticalCOG();
            } else if (accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = getHooklift();
                return chassisHeight + tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight() + accessory.getAboveBelowChassis() + accessory.getActualVerticalCOG();
            } else {
                return chassisHeight + accessory.getAboveBelowChassis() + accessory.getActualVerticalCOG();
            }

        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
            return chassisHeight + accessory.getAboveBelowChassis() + accessory.getActualVerticalCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
            return chassisHeight + accessory.getVerticalGap() + accessory.getActualVerticalCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
            return chassisHeight + getBody().getAboveBelowChassis() + getBody().getActualExternalHeight() - accessory.getMountingPosition() - accessory.getHeight() + accessory.getActualVerticalCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            if (_parentChassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && _parentChassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                return _parentChassisObject.getFloorHeight() + accessory.getAboveOrBelowFloor() - accessory.getMechanismHeight() + accessory.getActualVerticalCOG();
            } else {
                return chassisHeight + + getBody().getAboveBelowChassis() + getBody().getSubframeHeight() + getBody().getSubstructureHeight() + accessory.getAboveOrBelowFloor() - accessory.getMechanismHeight() + accessory.getActualVerticalCOG();
            }
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
            return chassisHeight + accessory.getActualVerticalCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
            return accessory.getHeightFromGround() - accessory.getCouplingHeight() + accessory.getActualVerticalCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            return chassisHeight + accessory.getVerticalGap() + accessory.getActualVerticalCOG();
        } else {
            return 0;
        } 

        /*
        
        If TypeOf accessory Is FileLoadingAccessoryCrewCab Then

        Dim x As FileLoadingAccessoryCrewCab = accessory

        Return chassisHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryCrane Then

        Dim x As FileLoadingAccessoryCrane = accessory

        Return chassisHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryFuelTank Then

        Dim x As FileLoadingAccessoryFuelTank = accessory

        If x.Type = FuelTankTypes.BehindCab Then
            Return chassisHeight + x.ActualVerticalCOG
        Else
            Return chassisHeight - 50 - x.Height + x.ActualVerticalCOG
        End If

    ElseIf TypeOf accessory Is FileLoadingAccessoryFifthWheel Then

        Dim x As FileLoadingAccessoryFifthWheel = accessory

        Return chassisHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryHeadboard Then

        Dim x As FileLoadingAccessoryHeadboard = accessory

        Return chassisHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryRollover Then

        Dim x As FileLoadingAccessoryRollover = accessory

        Return chassisHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryBullbar Then

        Dim x As FileLoadingAccessoryBullbar = accessory

        Return x.HeightFromGround + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryOther Then

        Dim x As FileLoadingAccessoryOther = accessory

        Return chassisHeight + x.VerticalGap + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryTaillift Then

        Dim x As FileLoadingAccessoryTaillift = accessory

        Return chassisHeight + subFrameHeight + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryFridge Then

        Dim x As FileLoadingAccessoryFridge = accessory

        Return chassisHeight + Body.ActualExternalHeight - x.MountingPosition - x.Height + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryFairing Then

        Dim x As FileLoadingAccessoryFairing = accessory

        Return chassisHeight + Body.ActualExternalHeight - x.Height + x.ActualVerticalCOG

    ElseIf TypeOf accessory Is FileLoadingAccessoryBody Then

        Dim x As FileLoadingAccessoryBody = accessory

        Return chassisHeight + x.ActualVerticalCOG

    Else
        Return 0
    End If
        */
    }

    function getDistanceFromCentreOfChassis(accessory, offsetToLeft, centreLine) {
        //return fromLeftOf + accessory.getActualLateralCOG();



        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            //return (offsetToLeft + accessory.getActualLateralCOG()) - centreLine;
            return (-(accessory.getActualWidth() / 2) + accessory.getActualLateralCOG());
            //return (accessory.getActualLateralCOG()) - centreLine;
            //return -centreLine + offsetToLeft + accessory.getActualLateralCOG();
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
            return (offsetToLeft + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG()) - centreLine;
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
            return (offsetToLeft + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG()) - centreLine - accessory.getWidth() / 2;
        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            return (offsetToLeft + accessory.getFromLeftOfBodyWidthExternal() + accessory.getActualLateralCOG()) - centreLine;
        }  else {
            return 0;
        }
    }

    function getDistanceFromRightMostOfGivenTrackWidthForVehicle(accessory, givenTrackWidth) {
        return getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, givenTrackWidth);
    }

    function getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, givenTrackWidth) {
        // return getDistanceFromCentreOfChassis(accessory, offsetToLeft, centreLine) + (givenTrackWidth/2);
        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            //return (offsetToLeft + accessory.getActualLateralCOG()) - centreLine;
            return (-(accessory.getActualWidth() / 2) + accessory.getActualLateralCOG()) + (givenTrackWidth/2);
            //return (accessory.getActualLateralCOG()) - centreLine;
            //return -centreLine + offsetToLeft + accessory.getActualLateralCOG();
        } else if(accessory.getFromCentreOfVehicle) {
            return givenTrackWidth - ((accessory.getFromCentreOfVehicle() - (accessory.getWidth()/2)) + accessory.getActualLateralCOG() + (givenTrackWidth/2));
        } else if(accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH || accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
            return givenTrackWidth/2;
        }
        return 0;
        // if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
        //     //return (offsetToLeft + accessory.getActualLateralCOG()) - centreLine;
            
        //     //return (accessory.getActualLateralCOG()) - centreLine;
        //     //return -centreLine + offsetToLeft + accessory.getActualLateralCOG();
        // } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
        //     return (offsetToLeft + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG()) - centreLine;
        // } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
        //     return (offsetToLeft + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG()) - centreLine - accessory.getWidth() / 2;
        // } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
        //     return (offsetToLeft + accessory.getFromLeftOfBodyWidthExternal() + accessory.getActualLateralCOG()) - centreLine;
        // }  else {
        //     return 0;
        // }
        
    }

    function getDistanceFromRightMostOfGivenTrackWidthForTrailer(accessory, givenTrackWidth) {
        return getDistanceFromRightMostOfGivenTrackWidthForChassis(accessory, givenTrackWidth);
        
    }

    function getEndOfEquipmentToEndOfChassis(accessory, overallLengthExcludingAccessories, bumperToBackOfCab) {
        return overallLengthExcludingAccessories - (bumperToBackOfCab + accessory.getHorizontalGap() + accessory.getLength());
    }


    function getAccessory1() {
        //'order of priority for accessories is
        //' - Rollover
        //' - Bullbar
        //' - Subframe
        //' - Crane
        //' - Headboard
        //' - Fridge
        //' - Taillift
        //' - Fairing
        //' - CrewCab
        //' - FuelTank
        //' - FifthWheel
        //' - Hitch
        //' - Other

        //'if there is a rollover, return it first
        if (getAccessoryCount(config.ACCESSORY_TYPES.ROLLOVER) === 1) {
            return getRollover();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.BULLBAR) === 1) {
            return getBullbar();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CRANE) === 1) {
            return getCrane();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HEADBOARD) === 1) {
            return getHeadboard();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FRIDGE) === 1) {
            return getFridge();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.TAILLIFT) === 1) {
            return getTaillift();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FAIRING) === 1) {
            return getFairing();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CREWCAB) === 1) {
            return getCrewCab();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FUELTANK) >= 1) {
            return getFuelTank(1);
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FIFTH_WHEEL) === 1) {
            return getFifthWheel();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HITCH) === 1) {
            return getHitch();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HOOKLIFT) === 1) {
            return getHooklift();
        }else if (getAccessoryCount(config.ACCESSORY_TYPES.OTHER) >= 1) {
            return getOther(1);
        } else {
            return null;
        }


    }

    function getAccessory2() {
        var tempAccessory1 = getAccessory1();

        //'if there is a rollover, return it first
        if (getAccessoryCount(config.ACCESSORY_TYPES.ROLLOVER, tempAccessory1) === 1) {
            return getRollover()
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.BULLBAR, tempAccessory1) === 1) {
            return getBullbar();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CRANE, tempAccessory1) === 1) {
            return getCrane();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HEADBOARD, tempAccessory1) === 1) {
            return getHeadboard();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FRIDGE, tempAccessory1) === 1) {
            return getFridge();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.TAILLIFT, tempAccessory1) === 1) {
            return getTaillift();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FAIRING, tempAccessory1) === 1) {
            return getFairing();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CREWCAB, tempAccessory1) === 1) {
            return getCrewCab();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FUELTANK, tempAccessory1) >= 1) {
            if (tempAccessory1.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
                return getFuelTank(2);
            } else {
                return getFuelTank(1);
            }
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FIFTH_WHEEL, tempAccessory1) === 1) {
            return getFifthWheel();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HITCH, tempAccessory1) === 1) {
            return getHitch();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HOOKLIFT, tempAccessory1) === 1) {
            return getHooklift();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.OTHER, tempAccessory1) >= 1) {
            if (tempAccessory1.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                return getOther(2);
            } else {
                return getOther(1);
            }
        } else {
            return null;
        }


    }

    function getNextAccessory(previousAccessories) {

        if (getAccessoryCount(config.ACCESSORY_TYPES.ROLLOVER) === 1 && !previousAccessories[config.ACCESSORY_TYPES.ROLLOVER]) {
            previousAccessories[config.ACCESSORY_TYPES.ROLLOVER] = true;
            return getRollover()
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.BULLBAR) === 1 && !previousAccessories[config.ACCESSORY_TYPES.BULLBAR]) {
            previousAccessories[config.ACCESSORY_TYPES.BULLBAR] = true;
            return getBullbar();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CRANE) === 1 && !previousAccessories[config.ACCESSORY_TYPES.CRANE]) {
            previousAccessories[config.ACCESSORY_TYPES.CRANE] = true;
            return getCrane();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HEADBOARD) === 1 && !previousAccessories[config.ACCESSORY_TYPES.HEADBOARD]) {
            previousAccessories[config.ACCESSORY_TYPES.HEADBOARD] = true;
            return getHeadboard();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FRIDGE) === 1 && !previousAccessories[config.ACCESSORY_TYPES.FRIDGE]) {
            previousAccessories[config.ACCESSORY_TYPES.FRIDGE] = true;
            return getFridge();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.TAILLIFT) === 1 && !previousAccessories[config.ACCESSORY_TYPES.TAILLIFT]) {
            previousAccessories[config.ACCESSORY_TYPES.TAILLIFT] = true;
            return getTaillift();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FAIRING) === 1 && !previousAccessories[config.ACCESSORY_TYPES.FAIRING]) {
            previousAccessories[config.ACCESSORY_TYPES.FAIRING] = true;
            return getFairing();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.CREWCAB) === 1 && !previousAccessories[config.ACCESSORY_TYPES.CREWCAB]) {
            previousAccessories[config.ACCESSORY_TYPES.CREWCAB] = true;
            return getCrewCab();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FUELTANK) >= 1 && (!previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '1'] || !previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '2'])) {
            if (getAccessoryCount(config.ACCESSORY_TYPES.FUELTANK) >= 1 && !previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '1']) {
                previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '1'] = true;
                return getFuelTank(1);
            } else if (getAccessoryCount(config.ACCESSORY_TYPES.FUELTANK) >= 2 && !previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '2']) {
                previousAccessories[config.ACCESSORY_TYPES.FUELTANK + '2'] = true;
                return getFuelTank(2);
            } 
            //if (tempAccessory1.getAccessoryType() === config.ACCESSORY_TYPES.FUELTANK) {
            //    return getFuelTank(2);
            //} else {
            //    return getFuelTank(1);
            //}
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.FIFTH_WHEEL) === 1 && !previousAccessories[config.ACCESSORY_TYPES.FIFTH_WHEEL]) {
            previousAccessories[config.ACCESSORY_TYPES.FIFTH_WHEEL] = true;
            return getFifthWheel();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HITCH) === 1 && !previousAccessories[config.ACCESSORY_TYPES.HITCH]) {
            previousAccessories[config.ACCESSORY_TYPES.HITCH] = true;
            return getHitch();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.HOOKLIFT) === 1 && !previousAccessories[config.ACCESSORY_TYPES.HOOKLIFT]) {
            previousAccessories[config.ACCESSORY_TYPES.HOOKLIFT] = true;
            return getHooklift();
        } else if (getAccessoryCount(config.ACCESSORY_TYPES.OTHER) >= 1 && (!previousAccessories[config.ACCESSORY_TYPES.OTHER] || previousAccessories[config.ACCESSORY_TYPES.OTHER] < getAccessoryCount(config.ACCESSORY_TYPES.OTHER))) {
            if (!previousAccessories[config.ACCESSORY_TYPES.OTHER]) {
                previousAccessories[config.ACCESSORY_TYPES.OTHER] = 1;
                return getOther(1);
            } else {
                var curOtherNum = previousAccessories[config.ACCESSORY_TYPES.OTHER];
                previousAccessories[config.ACCESSORY_TYPES.OTHER] = previousAccessories[config.ACCESSORY_TYPES.OTHER] + 1;
                return getOther(curOtherNum + 1);
            }
        } else {
            return null;
        }
    }

    function getAccessoryCount(accessoryType, optionalAccessoryToExcludeFromCount) {
        var count = 0;

        _accessories.forEach(function (accessory) {
            if ((accessory !== optionalAccessoryToExcludeFromCount)) {
                if (accessory.getAccessoryType() === accessoryType || (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessory.getAccessorySubType() === accessoryType)) {
                    count += 1;
                }
            }

        });

        return count;
    }

    function getBody() {
        return _accessories.filter(function (accessory) {
            return accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY;
        })[0] || null;
    }

    function getCrane() {
        return _accessories.filter(function (accessory) {
            return accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE;
        })[0] || null;
    }

    function getBullbar() {
        return getAccessory(config.ACCESSORY_TYPES.BULLBAR);
    }

    function getSnowplough() {
        return getAccessory(config.ACCESSORY_TYPES.SNOWPLOW);
    }

    function getRollover() {
        return getAccessory(config.ACCESSORY_TYPES.ROLLOVER);
    }

    function getHeadboard() {
        return getAccessory(config.ACCESSORY_TYPES.HEADBOARD);
    }

    function getFridge() {
        return getAccessory(config.ACCESSORY_TYPES.FRIDGE);
    }

    function getTaillift() {
        return getAccessory(config.ACCESSORY_TYPES.TAILLIFT);
    }

    function getFairing() {
        return getAccessory(config.ACCESSORY_TYPES.FAIRING);
    }

    function getCrewCab() {
        return getAccessory(config.ACCESSORY_TYPES.CREWCAB);
    }

    function getFifthWheel() {
        return getAccessory(config.ACCESSORY_TYPES.FIFTH_WHEEL);
    }

    function getHitch() {
        return getAccessory(config.ACCESSORY_TYPES.HITCH);
    }

    function getHooklift() {
        return getAccessory(config.ACCESSORY_TYPES.HOOKLIFT);
    }

    function getRearmostRearBumper() {
        var rearMostRearBumper = null;

        var temp = _accessories.filter(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessory.getAccessorySubType() === config.ACCESSORY_TYPES.REAR_BUMPER) {
                if (rearMostRearBumper) {
                    if (rearMostRearBumper.getHorizontalGap() + rearMostRearBumper.getLength() < accessory.getHorizontalGap() + accessory.getLength()) {
                        rearMostRearBumper = accessory;
                    }
                } else {
                    rearMostRearBumper = accessory;
                }
                
            } 
        });


        return rearMostRearBumper;
    }

    function getAccessory(accessoryType) {
        return _accessories.filter(function (accessory) {
            return accessory.getAccessoryType() === accessoryType || (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessory.getAccessorySubType() === accessoryType);
        })[0] || null;
    }

    function getOther(otherNumber) {
        return getAccessoryByNumber(config.ACCESSORY_TYPES.OTHER, otherNumber);
    }

    function getFuelTank(fuelTankNumber) {
        return getAccessoryByNumber(config.ACCESSORY_TYPES.FUEL_TANK, fuelTankNumber);
    }

    function getAccessoryByNumber(accessoryType, numberToGet) {
        var number = 1;
        var accessoryToReturn = null;

        //_accessories.forEach(function (accessory) {
        //    if (accessory.getAccessoryType() === accessoryType) {
        //        if (number === numberToGet) {
        //            accessoryToReturn = accessory;
        //        } else {
        //            number += 1;
        //        }
        //    }
        //});
        for (var i = 0; i < _accessories.length; i++) {
            var tempAccessory = _accessories[i];
            if (tempAccessory.getAccessoryType() === accessoryType) {
                if (number === numberToGet) {
                    accessoryToReturn = tempAccessory;
                    break;
                } else {
                    number += 1;
                }
            }
        }

        return accessoryToReturn;
    }

    function getOtherUsingId(id) {
        var accessoryToReturn = null;

        var temp = _accessories.filter(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                return accessory.getId() === id;
            } else {
                return false;
            }
        });

        accessoryToReturn = temp.length > 0 ? temp[0] : null;

        return accessoryToReturn
    }

    function getOtherBySourceDatabaseId(id) {
        var accessoryToReturn = null;

        var temp = _accessories.filter(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                return accessory.getSourceDatabaseId() === id;
            } else {
                return false;
            }
        });

        accessoryToReturn = temp.length > 0 ? temp[0] : null;

        return accessoryToReturn
    }

    function hasAccessory(accessoryType) {
        return _accessories.filter(function (accessory) {
            return accessory.getAccessoryType() === accessoryType;
        })[0] !== undefined;
    }
    /**
     * Get accessory objects to be saved to database
     * @param {boolean} removeBlobs - Do not include blobs in object
     * @param {boolean} includePositionValues - Keep position values or set to 0
     */
    function getAccessorySaveObjects(removeBlobs, includePositionValues) {
        //var tempArray = [];
        //_accessories.forEach(function (accessory) {
        //    tempArray.push(accessory.returnObjectToBeSaved());
        //});
        //return tempArray;
        var tempObj = {};

        if (getCrane() !== null) {
            tempObj.Cranes = [getCrane().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Cranes[0].GraphicBlob = '';
            }
        }

        if (getBody() !== null) {
            tempObj.Bodies = [getBody().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Bodies[0].Graphic = '';
            }
        }
        var tempOthers = [];
        _accessories.forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                var otherSaveObject = accessory.returnObjectToBeSaved(includePositionValues);
                if (removeBlobs === true) {
                    otherSaveObject.GraphicBlob = '';
                }
                tempOthers.push(otherSaveObject);
            }
        });

        if (tempOthers.length > 0) {
            tempObj.Others = tempOthers;
        }

        if (getFridge() !== null) {
            tempObj.Fridges = [getFridge().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Fridges[0].GraphicBlob = '';
            }
        }

        if (getTaillift() !== null) {
            tempObj.Taillifts = [getTaillift().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Taillifts[0].GraphicBlob = '';
            }
        }

        if (getFifthWheel() !== null) {
            tempObj.FifthWheels = [getFifthWheel().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.FifthWheels[0].GraphicBlob = '';
            }
        }

        if (getHitch() !== null) {
            tempObj.Hitches = [getHitch().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Hitches[0].GraphicBlob = '';
            }
        }

        if (getHooklift() !== null) {
            tempObj.Hooklifts = [getHooklift().returnObjectToBeSaved(includePositionValues)];
            if (removeBlobs === true) {
                tempObj.Hooklifts[0].GraphicBlob = '';
            }
        }

        return tempObj;
    }

    function getCount(includeBodyInCount) {
        if (includeBodyInCount && includeBodyInCount === true) {
            return _accessories.length;
        } else {
            if (getBody() !== null) {
                return _accessories.length - 1;
            } else {
                return _accessories.length;
            }
        }
    }

    function cleanUp() {
        _accessories.forEach(function (accessory) {
            accessory.setGraphicBlob(null);
            accessory.setTopViewGraphicBlob(null);
        });
        _accessories = null;
    }

    self.getAccessories = getAccessories;
    self.setAccessories = setAccessories;
    self.add = add;
    self.remove = remove;
    self.getAccessoryById = getAccessoryById;
    self.getAccessory = getAccessory;
    self.removeAccessoryById = removeAccessoryById;
    self.addAccessories = addAccessories;
    self.getAccessorySaveObjects = getAccessorySaveObjects;
    self.getBody = getBody;
    self.getCrane = getCrane;
    self.getFridge = getFridge;
    self.getFairing = getFairing;
    self.getTaillift = getTaillift;
    self.getFifthWheel = getFifthWheel;
    self.getHitch = getHitch;
    self.getHooklift = getHooklift;
    self.getAccessoryTopMostPoint = getAccessoryTopMostPoint;
    self.getAccessoryLeftMostPoint = getAccessoryLeftMostPoint;
    self.getAccessoryRightMostPoint = getAccessoryRightMostPoint;
    self.hasAccessory = hasAccessory;
    self.getAccessoryHeight = getAccessoryHeight;
    self.getAccessoryWidth = getAccessoryWidth;
    self.getDistanceFromWheelbaseTheoreticalStartForVehicle = getDistanceFromWheelbaseTheoreticalStartForVehicle;
    self.getDistanceFromWheelbaseTheoreticalEndForVehicle = getDistanceFromWheelbaseTheoreticalEndForVehicle;
    self.getCount = getCount;
    self.getBodyGap = getBodyGap;
    self.getAccessory1 = getAccessory1;
    self.getAccessory2 = getAccessory2;
    self.getDistanceFromGroundForChassis = getDistanceFromGroundForChassis;
    self.getVehicleRearAxleTotalAccessories = getVehicleRearAxleTotalAccessories;
    self.getVehicleFrontAxleTotalAccessories = getVehicleFrontAxleTotalAccessories;
    self.getTotalTotalAccessories = getTotalTotalAccessories;
    self.getVehicleFrontAxleOtherAccessories = getVehicleFrontAxleOtherAccessories;
    self.getVehicleRearAxleOtherAccessories = getVehicleRearAxleOtherAccessories;
    self.getVehicleTotalOtherAccessories = getVehicleTotalOtherAccessories;
    self.getOther = getOther;
    self.getOtherUsingId = getOtherUsingId;
    self.item = item;
    self.count = count;
    self.getAccessoryBySourceDatabaseId = getAccessoryBySourceDatabaseId;

    self.getTrailerFrontAxleTotalAccessories = getTrailerFrontAxleTotalAccessories;
    self.getTrailerRearAxleTotalAccessories = getTrailerRearAxleTotalAccessories;
    self.getDistanceFromWheelbaseTheoreticalEndForTrailer = getDistanceFromWheelbaseTheoreticalEndForTrailer;
    self.getRightMostAccessory = getRightMostAccessory;
    self.getAccessoryCount = getAccessoryCount;
    self.getTrailerFrontAxleTotalRemainingAccessories = getTrailerFrontAxleTotalRemainingAccessories;
    self.getTrailerRearAxleTotalRemainingAccessories = getTrailerRearAxleTotalRemainingAccessories;
    self.getTrailerTotalTotalRemainingAccessories = getTrailerTotalTotalRemainingAccessories;
    self.getAccessoryBottomMostPointDistanceFromTopViewCentreLine = getAccessoryBottomMostPointDistanceFromTopViewCentreLine;
    self.getAccessoryTopMostPointDistanceFromTopViewCentreLine = getAccessoryTopMostPointDistanceFromTopViewCentreLine;
    self.getDistanceFromCentreOfChassis = getDistanceFromCentreOfChassis;

    self.getDistanceFromWheelbaseTheoreticalStartForTrailer = getDistanceFromWheelbaseTheoreticalStartForTrailer;

    self.getBullbar = getBullbar;

    self.getNextAccessory = getNextAccessory;
    self.getVehicleFrontAxleTotalOtherAccessoriesCombined = getVehicleFrontAxleTotalOtherAccessoriesCombined;
    self.getVehicleRearAxleTotalOtherAccessoriesCombined = getVehicleRearAxleTotalOtherAccessoriesCombined;

    self.getEndOfEquipmentToEndOfChassis = getEndOfEquipmentToEndOfChassis;
    self.getRearmostRearBumper = getRearmostRearBumper;

    self.getSnowplough = getSnowplough;

    //2025.1
    self.getDistanceFromRightMostOfGivenTrackWidthForVehicle = getDistanceFromRightMostOfGivenTrackWidthForVehicle;
    self.getVehicleFrontLeftAxleTotalOtherAccessoriesCombined = getVehicleFrontLeftAxleTotalOtherAccessoriesCombined;
    self.getVehicleFrontRightAxleTotalOtherAccessoriesCombined = getVehicleFrontRightAxleTotalOtherAccessoriesCombined;
    self.getVehicleRearLeftAxleTotalOtherAccessoriesCombined = getVehicleRearLeftAxleTotalOtherAccessoriesCombined;
    self.getVehicleRearRightAxleTotalOtherAccessoriesCombined = getVehicleRearRightAxleTotalOtherAccessoriesCombined;
    self.getVehicleFrontLeftAxleTotalAccessories = getVehicleFrontLeftAxleTotalAccessories;
    self.getVehicleFrontRightAxleTotalAccessories = getVehicleFrontRightAxleTotalAccessories;
    self.getVehicleRearLeftAxleTotalAccessories = getVehicleRearLeftAxleTotalAccessories;
    self.getVehicleRearRightAxleTotalAccessories = getVehicleRearRightAxleTotalAccessories;
    self.getDistanceFromRightMostOfGivenTrackWidthForTrailer = getDistanceFromRightMostOfGivenTrackWidthForTrailer;
    self.getTrailerFrontLeftAxleTotalAccessories = getTrailerFrontLeftAxleTotalAccessories;
    self.getTrailerFrontRightAxleTotalAccessories = getTrailerFrontRightAxleTotalAccessories;
    self.getTrailerRearLeftAxleTotalAccessories = getTrailerRearLeftAxleTotalAccessories;
    self.getTrailerRearRightAxleTotalAccessories = getTrailerRearRightAxleTotalAccessories;
    self.getDistanceFromRightMostOfGivenTrackWidthForChassis = getDistanceFromRightMostOfGivenTrackWidthForChassis;

    self.cleanUp = cleanUp;
}

export default AccessoryHolder;



