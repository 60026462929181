
import config from '../config';
import globals from '../globals';
import dataManager from '@/services/dataManager';
// import offerManager from '../offerManager';
import Legislation from '@/services/legislation/model.legislation';
import integrations from '@/services/settings/integrations';
import InternalStandard from '@/model/user/dashboardConfiguration/internalStandard';
import ReportView from '@/model/user/reportView';

const $ = window.$;

let changedSettingNames = '';
let offerManager = null;



/**
         *
         * @param {*} measurementSystemId
         * @param {*} lengthIncrement
         * @param {*} massIncrement
         * @param {*} percentageIncrement
         * @param {*} legislationId
         * @param {*} applicationTypeId
         * @param {*} colourDrawings
         * @param {*} showComplianceScorecard
         * @param {*} specifyWheelbaseAs
         * @param {*} integrationsParam
         * @param {*} specifyCabDimensionsAs
         * @param {*} specifyChassisLengthAs
         * @param {*} specifyFuelConsumptionAs
         * @param {*} specifyLicencingRegionAs
         * @param {*} specifyAxleRatingAs
         * @param {*} defaultReportPdfPageSize
         * @param {*} reportLogo
         * @param {*} dashboardDataPointIds
         * @param {*} dashboardLegislationIds
         * @param {*} internalStandards
         * @param {*} companyAdditionalNote
         * @param {*} specifyBodyPositionAs
         * @param {*} specifyEquipmentPositionAs
         * @param {*} showTips
         * @param {string} reportViewLayout - The layout of the report view. Either 'simplified' or 'advanced'
         * @param {ReportView[]} reportViews - The report views to be used in the report
         * @param {string} nteaUserValidationType - The type of NTEA user validation to use
         * @param {string} reportPdfSignatures - The signatures saved by the user on the PDF
         * @param {string} reportPdfStamps - The rubber stamps saved by the user on the PDF
         * @returns {SettingsObject}
         */
function SettingsObject(measurementSystemId, lengthIncrement, massIncrement, percentageIncrement, legislationId, applicationTypeId, colourDrawings, showComplianceScorecard, specifyWheelbaseAs, integrationsParam, specifyCabDimensionsAs, specifyChassisLengthAs, specifyFuelConsumptionAs, specifyLicencingRegionAs, specifyAxleRatingAs, defaultReportPdfPageSize, reportLogo, dashboardDataPointIds, dashboardLegislationIds, internalStandards, companyAdditionalNote, specifyBodyPositionAs, specifyEquipmentPositionAs, showTips, reportViewLayout, reportViews, nteaUserValidationType, reportPdfSignatures, reportPdfStamps) {
    //#region internal properties
    var _activeLegislations,
        _availableLicenceCategories,
        _measurementSystem,
        _measurementSystemId,
        _lengthIncrement,
        _massIncrement,
        _percentageIncrement,
        _legislation,
        _legislationId,
        _applicationType,
        _applicationTypeId,
        _colourDrawings,
        _showComplianceScorecard,
        _specifyWheelbaseAs,
        _integrations,
        _specifyCabDimensionsAs,
        _specifyChassisLengthAs,
        _specifyFuelConsumptionAs,
        _specifyLicencingRegionAs,
        _specifyAxleRatingAs,
        _defaultReportPdfPageSize,
        _reportLogo,
        _dashboardDataPointIds,
        _dashboardLegislationIds,
        _internalStandards,
        _companyAdditionalNote,
        _specifyBodyPositionAs,
        _specifyEquipmentPositionAs,
        _showTips,
        _reportViewLayout,
        _reportViews,
        _nteaUserValidationType,
        _reportPdfSignatures,
        _reportPdfStamps;
    //#endregion

    

    setMeasurementSystemId(measurementSystemId);
    setPercentageIncrement(percentageIncrement);
    setLegislationId(legislationId);
    setApplicationTypeId(applicationTypeId);
    setColourDrawings(colourDrawings);
    setShowComplianceScorecard(showComplianceScorecard);
    setSpecifyWheelbaseAs(specifyWheelbaseAs);
    setIntegrations(integrationsParam);
    setSpecifyCabDimensionsAs(specifyCabDimensionsAs);
    setSpecifyChassisLengthAs(specifyChassisLengthAs);
    setSpecifyFuelConsumptionAs(specifyFuelConsumptionAs);
    setSpecifyLicencingRegionAs(specifyLicencingRegionAs);
    setSpecifyAxleRatingAs(specifyAxleRatingAs);
    setDefaultReportPdfPageSize(defaultReportPdfPageSize);
    setReportLogo(reportLogo);
    setDashboardDataPointIds(dashboardDataPointIds);
    setDashboardLegislationIds(dashboardLegislationIds);
    setCompanyAdditionalNote(companyAdditionalNote);
    setSpecifyBodyPositionAs(specifyBodyPositionAs);
    setSpecifyEquipmentPositionAs(specifyEquipmentPositionAs);
    setShowTips(showTips);
    setReportViewLayout(reportViewLayout);
    setNteatUserValidationType(nteaUserValidationType);
    setReportPdfSignatures(reportPdfSignatures);
    setReportPdfStamps(reportPdfStamps);
    initializeInternalStandards(internalStandards);
    initializeReportViews(this, reportViews);

    _lengthIncrement = lengthIncrement;
    _massIncrement = massIncrement;

    function getMeasurementSystem() {
        return _measurementSystem;
    }
    function setMeasurementSystem(id) {
        var newMeasurementSystem = globals.getMeasurementSystemUsingType(id);
        _measurementSystem = newMeasurementSystem;
    }
    function getMeasurementSystemId() {
        return _measurementSystemId;
    }
    function setMeasurementSystemId(value) {
        _measurementSystemId = value;
        setMeasurementSystem(value);
    }
    function getLengthIncrement() {
        return _lengthIncrement;
    }
    function setLengthIncrement(value) {
        _lengthIncrement = value * getMeasurementSystem().lengthConversion;
    }
    function getMassIncrement() {
        return _massIncrement;
    }
    function setMassIncrement(value) {
        _massIncrement = value / getMeasurementSystem().massConversion;
    }
    function getPercentageIncrement() {
        return _percentageIncrement;
    }
    function setPercentageIncrement(value) {
        _percentageIncrement = value;
    }
    function getLegislation() {
        return _legislation;
    }
    function setLegislation(value) {
        _legislation = value;
    }
    function getLegislationId() {
        return _legislationId;
    }
    function setLegislationId(value) {
        _legislationId = value;
    }
    function getApplicationType() {
        return _applicationType;
    }
    function setApplicationType(value) {
        _applicationType = value;
    }
    function getApplicationTypeId() {
        return _applicationTypeId;
    }
    function setApplicationTypeId(value) {
        _applicationTypeId = value;
    }
    function getColourDrawings() {
        return _colourDrawings;
    }
    function setColourDrawings(value) {
        _colourDrawings = value;
    }
    function getShowComplianceScorecard() {
        return _showComplianceScorecard;
    }
    function setShowComplianceScorecard(value) {
        _showComplianceScorecard = value;
    }
    function getSpecifyWheelbaseAs() {
        return _specifyWheelbaseAs;
    }
    function setSpecifyWheelbaseAs(value) {
        _specifyWheelbaseAs = value;
    }
    function getIntegrations() {
        return _integrations;
    }
    function setIntegrations(value) {
        _integrations = Array.isArray(value) ? value : [];
    }
    function getSpecifyCabDimensionsAs() {
        return _specifyCabDimensionsAs;
    }
    function setSpecifyCabDimensionsAs(value) {
        _specifyCabDimensionsAs = value;
    }
    function getSpecifyChassisLengthAs() {
        return _specifyChassisLengthAs;
    }
    function setSpecifyChassisLengthAs(value) {
        _specifyChassisLengthAs = value;
    }
    function getSpecifyFuelConsumptionAs() {
        return _specifyFuelConsumptionAs;
    }
    function setSpecifyFuelConsumptionAs(value) {
        _specifyFuelConsumptionAs = value;
    }
    function getSpecifyLicencingRegionAs() {
        return _specifyLicencingRegionAs;
    }
    function setSpecifyLicencingRegionAs(newValue) {
        _specifyLicencingRegionAs = newValue;
    }
    function getSpecifyAxleRatingAs() {
        return _specifyAxleRatingAs;
    }
    function setSpecifyAxleRatingAs(value) {
        _specifyAxleRatingAs = value;
    }
    function getDefaultReportPdfPageSize() {
        return _defaultReportPdfPageSize;
    }
    function setDefaultReportPdfPageSize(value) {
        _defaultReportPdfPageSize = value;
    }
    function getReportLogo() {
        return _reportLogo;
    }
    function setReportLogo(newValue) {
        _reportLogo = newValue;
    }
    function getCompanyAdditionalNote() {
        return _companyAdditionalNote;
    }
    function setCompanyAdditionalNote(newValue) {
        _companyAdditionalNote = newValue;
    }
    function getSpecifyBodyPositionAs() {
        return _specifyBodyPositionAs;
    }
    function setSpecifyBodyPositionAs(newValue) {
        _specifyBodyPositionAs = newValue;
    }
    function getSpecifyEquipmentPositionAs() {
        return _specifyEquipmentPositionAs;
    }
    function setSpecifyEquipmentPositionAs(newValue) {
        _specifyEquipmentPositionAs = newValue;
    }
    function getShowTips() {
        return _showTips;
    }
    function setShowTips(value) {
        _showTips = value;
    }
    function getReportViewLayout() {
        return _reportViewLayout;
    }
    function setReportViewLayout(value) {
        _reportViewLayout = value;
    }
    function getNteaUserValidationType() {
        return _nteaUserValidationType;
    }
    function setNteatUserValidationType(value) {
        _nteaUserValidationType = value;
    }
    function getDashboardDataPointIds() {
        return _dashboardDataPointIds;
    }
    function setDashboardDataPointIds(newValue) {
        _dashboardDataPointIds = Array.isArray(newValue) ? newValue : [];
    }
    function getDashboardLegislationIds() {
        return _dashboardLegislationIds;
    }
    function setDashboardLegislationIds(newValue) {
        _dashboardLegislationIds = Array.isArray(newValue) ? newValue : [];
    }
    function getInternalStandards() {
        return _internalStandards;
    }
    function setInternalStandards(newValue) {
        _internalStandards = Array.isArray(newValue) ? newValue : [];
    }
    function getInternalStandardsToBeSaved() {
        var returnArray = [];

        _internalStandards.forEach(function (internalStandard) {
            if (typeof internalStandard.getDataPointId() === 'number') {
                returnArray.push(internalStandard.getObjectToBeSaved());
            }
        });

        return returnArray;
    }
    function getDashboardLegislationsToBeSaved() {
        var returnArray = [];

        _dashboardLegislationIds.forEach(function (legId) {
            var legislation = getActiveLegislations().find(function (al) {
                return al.id === legId;
            });

            if (legislation !== undefined) {
                returnArray.push({
                    LegislationId: legislation.legislationId,
                    CountryId: legislation.countryId
                });
            }
        });

        return returnArray;
    }

    function getIntegrationUsingType(typeToGet) {
        var returnValue = getIntegrations().find(function (element) {
            return element.Type = typeToGet;
        });
        return returnValue !== undefined ? returnValue : null;
    }
    function setIntegrationValue(value, valueToSet, typeToGet) {
        var obj = getIntegrationUsingType(typeToGet);
        if (obj !== null) {
            obj[valueToSet] = value;
        }
    }
    function setNteaAttribute1Value(value) {
        var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        if (obj !== null) {
            obj.setAttribute1(value);
        }
    }
    function setNteaAttribute2Value(value) {
        var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        if (obj !== null) {
            obj.setAttribute2(value);
        }
    }
    function setNteaPasswordValue(value) {
        var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        if (obj !== null) {
            obj.setPassword(value);
        }
    }

    /**
     *
     * @param {object[]} newInternalStandards - Internal standard objects to add
     */
    function initializeInternalStandards(newInternalStandards) {
        if (Array.isArray(newInternalStandards)) {
            var updateArray = [];

            newInternalStandards.forEach(function (is, index) {
                if (typeof is.getIsBlankRow === 'function') {
                    updateArray.push(is);
                } else {
                    is.Id = globals.nextId();
                    if (is.RangeType !== config.RANGE_TYPES.BETWEEN) {
                        if (is.RangeMin === 0) {
                            is.RangeMin = '';
                        }
                        if (is.RangeMax === 0) {
                            is.RangeMax = '';
                        }
                    }
                    //is.IsBlankRow =
                    updateArray.push(new SettingsInternalStandard(is));
                }
            });
            if (updateArray.length < 5) {
                updateArray.push(createEmptyInternalStandard(updateArray));
            }

            setInternalStandards(updateArray);
        }
    }

    function createEmptyInternalStandard(arr) {
        var emptyInternalStandard = {
            Id: getIdForInternalStandardBlankRow(arr),
            IsBlankRow: true,
            DataPointId: '',
            RangeMin: '',
            RangeMax: '',
            RangeType: config.RANGE_TYPES.BETWEEN,
            IsActive: false
        };

        return new SettingsInternalStandard(emptyInternalStandard);
    }

    function getSettingsInternalStandardUsingId(id) {
        return getInternalStandards().find(function (ia) {
            return ia.getId() === id;
        });
    }

    function getIdForInternalStandardBlankRow(arr) {
        var arrayToCheck = Array.isArray(arr) === true ? arr : getInternalStandards(),
            newId = 0;

        arrayToCheck.forEach(function (item) {
            if (item.getId() > newId) {
                newId = item.getId()
            }
        });

        return newId + 1;
    }

    /**
     *
     * @param {any} newInternalStandard
     */
    function addInternalStandard(newInternalStandard, indexToReplace) {
        if (typeof indexToReplace === 'number') {
            if (_internalStandards.length === indexToReplace) {
                if (typeof newInternalStandard.getId === 'function') {
                    _internalStandards.push(newInternalStandard);
                } else {
                    _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
                }
            } else {
                var tempArray = [];

                _internalStandards.forEach(function (is, index) {
                    if (index === indexToReplace) {
                        if (typeof newInternalStandard.getId === 'function') {
                            tempArray.push(newInternalStandard);
                        } else {
                            tempArray.push(new SettingsInternalStandard(newInternalStandard));
                        }
                    }
                    tempArray.push(is);
                });
                _internalStandards = tempArray;
            }
        } else {
            if (typeof newInternalStandard.getId === 'function') {
                _internalStandards.push(newInternalStandard);
            } else {
                _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
            }
        }
    }

    function removeInternalStandard(isToRemove) {
        var indexToRemove = _internalStandards.map(function (e) { return e.getId(); }).indexOf(isToRemove.getId());
        _internalStandards.splice(indexToRemove, 1);
    }

    
    

    // #region Report Views Section
    function getReportViews() {
        return _reportViews;
    }
    function setReportViews(newValue) {
        _reportViews = Array.isArray(newValue) ? newValue : [];
    }
    function getReportViewObjectsToBeSaved() {
        var returnArray = [];

        _reportViews.forEach(function (rv) {
            if(rv.getIsBlankRow() !== true) {
                returnArray.push(rv.getObjectToBeSaved());
            }
        });

        return returnArray;
    }
    function areSelectedReportViewsValid() {
        var returnVal = true;
        // If 2 or more rows then there should be at least 1 view selected which is valid
        if (getReportViews().length > 1) {
            returnVal = true;
        } else {
            // If row is empty then this is invalid
            if(getReportViews().length === 0) {
                returnVal = false;
            } else {
                // if there is 1 row and it is blank then it is invalid
                if (getReportViews()[0].getIsBlankRow() === true) {
                    returnVal = false;
                } else {
                    returnVal = true;
                }
            }
        }

        return returnVal;
    }
    function getReportViewUsingId(id) {
        return getReportViews().find(function (rv) {
            // return rv.getOrder() === id;
            return rv.getId() === id;
        });
    }
    function getReportViewUsingOrder(order) {
        return getReportViews().find(function (rv) {
            return rv.getOrder() === order;
        });
    }
    
    function getSettingsReportViewUsingId(id) {
        return getReportViews().find(function (rv) {
            return rv.getId() === id;
        });
    }
    // /**
    //  * Function to create an empty object to create a row for the report view table
    //  * @param {number} id - ID to use for the report view object
    //  * @returns {Object} ReportView object
    //  */
    // function createEmptyReportView(arr) {
    //     var emptyReportView = {
    //             ViewType: '',
    //             ViewDetail: '',
    //             Elevation: '',
    //             Display: '',
    //             Order: getOrderForReportViewBlankRow(arr),
    //             IsActive: false,
    //             IsBlankRow: true
    //         };
    //     return new SettingsReportView(emptyReportView);
    // }

    // function getOrderForReportViewBlankRow(arr) {
    //     var arrayToCheck = Array.isArray(arr) === true ? arr : getReportViews(),
    //         newId = 0;
    //     arrayToCheck.forEach(function (item) {
    //         if (item.getOrder() > newId) {
    //             newId = item.getOrder()
    //         }
    //     });
    //     return newId + 1;
    // }
    function removeReportView(rvToRemove) {
        var indexToRemove = _reportViews.map(function (e) { return e.getId(); }).indexOf(rvToRemove.getId());
        _reportViews.splice(indexToRemove, 1);
    }
    /**
     *
     * @param {object} newReportView
     */
     function addReportView(newReportView) {
        if (typeof newReportView.getId === 'function') {
            _reportViews.push(newReportView);
        } else {
            _reportViews.push(new SettingsReportView(newReportView));
        }
        // if (typeof indexToReplace === 'number') {
        //     if (_internalStandards.length === indexToReplace) {
        //         if (typeof newInternalStandard.getId === 'function') {
        //             _internalStandards.push(newInternalStandard);
        //         } else {
        //             _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //         }
        //     } else {
        //         var tempArray = [];
        //         _internalStandards.forEach(function (is, index) {
        //             if (index === indexToReplace) {
        //                 if (typeof newInternalStandard.getId === 'function') {
        //                     tempArray.push(newInternalStandard);
        //                 } else {
        //                     tempArray.push(new SettingsInternalStandard(newInternalStandard));
        //                 }
        //             }
        //             tempArray.push(is);
        //         });
        //         _internalStandards = tempArray;
        //     }
        // } else {
        //     if (typeof newInternalStandard.getId === 'function') {
        //         _internalStandards.push(newInternalStandard);
        //     } else {
        //         _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //     }
        // }
    }
    /**
     * Take in array of objects and creates an array of SettingsReportView objects
     * and sets the report views to the array
     * @param {object[]} newReportViews - Array of report view objects
     */
    function initializeReportViews(settingsObject, newReportViews) {
        if (Array.isArray(newReportViews)) {
            var updateArray = [];

            newReportViews.forEach(function (rv, index) {
                updateArray.push(new SettingsReportView(rv));
                // if (typeof is.getIsBlankRow === 'function') {
                //     updateArray.push(is);
                // } else {
                //     is.Id = globals.nextId();
                //     if (is.RangeType !== config.RANGE_TYPES.BETWEEN) {
                //         if (is.RangeMin === 0) {
                //             is.RangeMin = '';
                //         }
                //         if (is.RangeMax === 0) {
                //             is.RangeMax = '';
                //         }
                //     }
                //     //is.IsBlankRow =
                //     updateArray.push(new SettingsInternalStandard(is));
                // }
            });

            updateArray.push(createEmptyReportView(settingsObject, updateArray));

            setReportViews(updateArray);
        }
    }

    function updateReportViewOrderValues() {
        var reportViews = getReportViews();
        reportViews.forEach(function (rv, index) {
            rv.setOrder(index + 1);
        });
        setReportViews(reportViews);
    }
    // #endregion Report Views Section

    function setActiveLegislations(valueArray) {
        var tempLegislationsArray = [];
    
        if (Array.isArray(valueArray)) {
            valueArray.forEach(function (value) {
                var newLegislationObject = null;
                if (value.evaluate !== undefined) {
                    newLegislationObject = value;
                } else {
                    newLegislationObject = new Legislation(value);
                }
                if (newLegislationObject !== null) {
                    tempLegislationsArray.push(newLegislationObject);
                }
            });
            _activeLegislations = tempLegislationsArray;
        }
    }
    
    function getActiveLegislations() {
        return _activeLegislations;
    }

    
    function getAvailableLicenceCategories() {
        return _availableLicenceCategories;
    }
    
    function setUpAvailableLicenceCategories(licenceCategories) {
        _availableLicenceCategories = [];
        var tempArray = globals.getFilteredLicenceCategoriesArray(licenceCategories, 'VEHICLE');
        tempArray.forEach(function (lc) {
            _availableLicenceCategories.push({
                id: lc.RegionId,
                description: lc.RegionName
            });
        });
    }

    function getReportPdfSignatures() {
        return _reportPdfSignatures;
    }

    function setReportPdfSignatures(value) {
        _reportPdfSignatures = value;
    }

    function getReportPdfStamps() {
        return _reportPdfStamps;
    }

    function setReportPdfStamps(value) {
        _reportPdfStamps = value;
    }

    this.getMeasurementSystem = getMeasurementSystem;
    this.setMeasurementSystem = setMeasurementSystem;
    this.getMeasurementSystemId = getMeasurementSystemId;
    this.setMeasurementSystemId = setMeasurementSystemId;
    this.getLengthIncrement = getLengthIncrement;
    this.setLengthIncrement = setLengthIncrement;
    this.getMassIncrement = getMassIncrement;
    this.setMassIncrement = setMassIncrement;
    this.getPercentageIncrement = getPercentageIncrement;
    this.setPercentageIncrement = setPercentageIncrement;
    this.getLegislation = getLegislation;
    this.setLegislation = setLegislation;
    this.getLegislationId = getLegislationId;
    this.setLegislationId = setLegislationId;
    this.getApplicationType = getApplicationType;
    this.setApplicationType = setApplicationType;
    this.getApplicationTypeId = getApplicationTypeId;
    this.setApplicationTypeId = setApplicationTypeId;
    this.getColourDrawings = getColourDrawings;
    this.setColourDrawings = setColourDrawings;
    this.getShowComplianceScorecard = getShowComplianceScorecard;
    this.setShowComplianceScorecard = setShowComplianceScorecard;
    this.getSpecifyWheelbaseAs = getSpecifyWheelbaseAs;
    this.setSpecifyWheelbaseAs = setSpecifyWheelbaseAs;
    // this.changesApplied = changesApplied;
    this.getIntegrations = getIntegrations;
    this.setIntegrations = setIntegrations;
    this.getSpecifyCabDimensionsAs = getSpecifyCabDimensionsAs;
    this.setSpecifyCabDimensionsAs = setSpecifyCabDimensionsAs;
    this.getSpecifyChassisLengthAs = getSpecifyChassisLengthAs;
    this.setSpecifyChassisLengthAs = setSpecifyChassisLengthAs;
    this.getIntegrationUsingType = getIntegrationUsingType;
    this.changesAppliedToNTEAIntegration = changesAppliedToNTEAIntegration;
    this.setIntegrationValue = setIntegrationValue;
    this.setNteaAttribute1Value = setNteaAttribute1Value;
    this.setNteaAttribute2Value = setNteaAttribute2Value;
    this.setNteaPasswordValue = setNteaPasswordValue;
    this.getSpecifyFuelConsumptionAs = getSpecifyFuelConsumptionAs;
    this.setSpecifyFuelConsumptionAs = setSpecifyFuelConsumptionAs;
    this.getSpecifyLicencingRegionAs = getSpecifyLicencingRegionAs;
    this.setSpecifyLicencingRegionAs = setSpecifyLicencingRegionAs;
    this.getSpecifyAxleRatingAs = getSpecifyAxleRatingAs;
    this.setSpecifyAxleRatingAs = setSpecifyAxleRatingAs;
    this.getDefaultReportPdfPageSize = getDefaultReportPdfPageSize;
    this.setDefaultReportPdfPageSize = setDefaultReportPdfPageSize;
    this.getReportLogo = getReportLogo;
    this.setReportLogo = setReportLogo;
    this.getDashboardDataPointIds = getDashboardDataPointIds;
    this.setDashboardDataPointIds = setDashboardDataPointIds;
    this.getDashboardLegislationIds = getDashboardLegislationIds;
    this.setDashboardLegislationIds = setDashboardLegislationIds;
    this.getInternalStandards = getInternalStandards;
    this.setInternalStandards = setInternalStandards;
    this.addInternalStandard = addInternalStandard;
    this.removeInternalStandard = removeInternalStandard;
    this.createEmptyInternalStandard = createEmptyInternalStandard;
    this.getSettingsInternalStandardUsingId = getSettingsInternalStandardUsingId;
    this.getCompanyAdditionalNote = getCompanyAdditionalNote;
    this.setCompanyAdditionalNote = setCompanyAdditionalNote;
    this.getSpecifyBodyPositionAs = getSpecifyBodyPositionAs;
    this.setSpecifyBodyPositionAs = setSpecifyBodyPositionAs;
    this.getSpecifyEquipmentPositionAs = getSpecifyEquipmentPositionAs;
    this.setSpecifyEquipmentPositionAs = setSpecifyEquipmentPositionAs;
    this.getShowTips = getShowTips;
    this.setShowTips = setShowTips;
    this.getReportViewLayout = getReportViewLayout;
    this.setReportViewLayout = setReportViewLayout;
    this.getReportViews = getReportViews;
    this.setReportViews = setReportViews;
    this.getSettingsReportViewUsingId = getSettingsReportViewUsingId;
    this.addReportView = addReportView;
    this.removeReportView = removeReportView;
    this.getReportViewObjectsToBeSaved = getReportViewObjectsToBeSaved;
    this.areSelectedReportViewsValid = areSelectedReportViewsValid;
    this.getReportViewUsingId = getReportViewUsingId;
    this.getReportViewUsingOrder = getReportViewUsingOrder;
    this.updateReportViewOrderValues = updateReportViewOrderValues;
    this.getNteaUserValidationType = getNteaUserValidationType;
    this.getAvailableLicenceCategories = getAvailableLicenceCategories;
    this.setUpAvailableLicenceCategories = setUpAvailableLicenceCategories;
    this.setActiveLegislations = setActiveLegislations;
    this.getDashboardLegislationsToBeSaved = getDashboardLegislationsToBeSaved;
    this.getActiveLegislations = getActiveLegislations;
    this.getInternalStandardsToBeSaved = getInternalStandardsToBeSaved;
    this.getReportPdfSignatures = getReportPdfSignatures;
    this.setReportPdfSignatures = setReportPdfSignatures;
    this.getReportPdfStamps = getReportPdfStamps;
    this.setReportPdfStamps = setReportPdfStamps;
}

function applyAnyChanges(newSettings, originalSettings) {

    var changesAppliedDfd = $.Deferred();

    changedSettingNames = '';



    let settingsChanged = false, refreshMenu = false, attrObvGroup, higherMassLimitValue, updateAxleMenuStructures = false;


    function addSettingNameToChangedSettingNamesList(settingName) {
        if (changedSettingNames === '') {
            changedSettingNames = settingName;
        } else {
            changedSettingNames += ',' + settingName;
        }
    }

    async function updateValuesOtherThanLegislation() {
        if (newSettings.getMeasurementSystem().id !== originalSettings.getMeasurementSystem().id) {
            attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMEASUREMENTSYSTEM);
            await attrObvGroup.extraUpdate(newSettings.getMeasurementSystemId());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.MEASUREMENT_SYSTEM);
            offerManager.setPreventCostingTotalRevenueUpdate(false);
            settingsChanged = true;
        }
        if (newSettings.getLengthIncrement() !== originalSettings.getLengthIncrement()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).formatted.value = offerManager.getUIFormattedLengthValue(newSettings.getLengthIncrement());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.LENGTH_INCREMENT);
            settingsChanged = true;
        }
        if (newSettings.getMassIncrement() !== originalSettings.getMassIncrement()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value = offerManager.getUIFormattedMassValue(newSettings.getMassIncrement());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.MASS_INCREMENT);
            settingsChanged = true;
        }
        if (newSettings.getPercentageIncrement() !== originalSettings.getPercentageIncrement()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).formatted.value = offerManager.getUIFormattedPercentageIncrementValue(newSettings.getPercentageIncrement(), 1);
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.PERCENTAGE_INCREMENT);
            settingsChanged = true;
        }
        if (newSettings.getApplicationTypeId() !== originalSettings.getApplicationTypeId()) {
            attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSVEHICLEAPPLICATION);
            attrObvGroup.extraUpdate(newSettings.getApplicationTypeId());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.VEHICLE_APPLICATION);

            higherMassLimitValue = newSettings.getApplicationTypeId() === config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT ? true : false;
            attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSAPPLYHIGHERMASSLIMITS);
            attrObvGroup.value.value = higherMassLimitValue;

            settingsChanged = true;
        }
        if (newSettings.getColourDrawings() !== originalSettings.getColourDrawings()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSCOLOURDRAWINGS).value.value = newSettings.getColourDrawings();
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.COLOUR_DRAWINGS);
            settingsChanged = true;
        }
        if (newSettings.getShowComplianceScorecard() !== originalSettings.getShowComplianceScorecard()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSHOWCOMPLIANCESCORECARD).value.value = newSettings.getShowComplianceScorecard();
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SHOW_COMPLIANCE_SCORECARD);
            settingsChanged = true;
        }
        if (newSettings.getSpecifyWheelbaseAs() !== originalSettings.getSpecifyWheelbaseAs()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS).optionId.value = newSettings.getSpecifyWheelbaseAs();
            var specifyWheelbaseAsOption = offerManager.getSpecifyWheelbaseAsOptions()
            .filter(function (option) {
                if (option.id === newSettings.getSpecifyWheelbaseAs()) {
                    return option;
                } else if (globals.user.getSpecifyWheelbaseAs() === '' || globals.user.getSpecifyWheelbaseAs() === undefined) {
                    return option;
                }
            })[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS).option.value = specifyWheelbaseAsOption;
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_WHEELBASE_AS);
            settingsChanged = true;
        }
        if (newSettings.getSpecifyCabDimensionsAs() !== originalSettings.getSpecifyCabDimensionsAs()) {
            var specifyCabDimensionsAsOption = offerManager.getSpecifyCabDimensionsAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyCabDimensionsAs();
                });
            var specifyCabDimensionsAsOptionToUse = specifyCabDimensionsAsOption !== undefined ? specifyCabDimensionsAsOption : offerManager.getSpecifyCabDimensionsAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYCABDIMENSIONSAS).setVal(specifyCabDimensionsAsOptionToUse);

            globals.user.updateUser({
                specifyCabDimensionsAs: specifyCabDimensionsAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_CAB_DIMENSIONS_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getSpecifyChassisLengthAs() !== originalSettings.getSpecifyChassisLengthAs()) {
            var specifyChassisLengthAsOption = offerManager.getSpecifyChassisLengthAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyChassisLengthAs();
                });
            var specifyChassisLengthAsOptionToUse = specifyChassisLengthAsOption !== undefined ? specifyChassisLengthAsOption : offerManager.getSpecifyChassisLengthAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYCHASSISLENGTHAS).setVal(specifyChassisLengthAsOptionToUse);

            globals.user.updateUser({
                specifyChassisLengthAs: specifyChassisLengthAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_CHASSIS_LENGTH_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getSpecifyFuelConsumptionAs() !== originalSettings.getSpecifyFuelConsumptionAs()) {
            var specifyFuelConsumptionAsOption = offerManager.getSpecifyFuelConsumptionAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyFuelConsumptionAs();
                });
            var specifyFuelConsumptionAsOptionToUse = specifyFuelConsumptionAsOption !== undefined ? specifyFuelConsumptionAsOption : offerManager.getSpecifyFuelConsumptionAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS).setVal(specifyFuelConsumptionAsOptionToUse);

            globals.user.updateUser({
                specifyFuelConsumptionAs: specifyFuelConsumptionAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_FUEL_CONSUMPTION_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getSpecifyLicencingRegionAs() !== originalSettings.getSpecifyLicencingRegionAs()) {
            var specifyLicencingRegionAsOption = newSettings.getAvailableLicenceCategories()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyLicencingRegionAs();
                });
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYLICENCINGREGIONAS).setVal(specifyLicencingRegionAsOption);

            globals.user.updateUser({
                specifyLicencingRegionAs: specifyLicencingRegionAsOption.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_LICENCING_REGION_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getSpecifyAxleRatingAs() !== originalSettings.getSpecifyAxleRatingAs()) {
            var specifyAxleRatingAsOption = offerManager.getSpecifyAxleRatingAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyAxleRatingAs();
                });
            var specifyAxleRatingAsOptionToUse = specifyAxleRatingAsOption !== undefined ? specifyAxleRatingAsOption : offerManager.getSpecifyAxleRatingAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYAXLERATINGAS).setVal(specifyAxleRatingAsOptionToUse);

            globals.user.updateUser({
                specifyAxleRatingAs: specifyAxleRatingAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_AXLE_RATING_AS);
            settingsChanged = true;
            refreshMenu = true;
            updateAxleMenuStructures = true;
        }
        if (newSettings.getDefaultReportPdfPageSize() !== originalSettings.getDefaultReportPdfPageSize()) {
            var defaultReportPdfPageSizeOption = offerManager.getDefaultReportPdfPageSizeOptions()
                .find(function (option) {
                    return option.id === newSettings.getDefaultReportPdfPageSize();
                });
            var defaultReportPdfPageSizeOptionToUse = defaultReportPdfPageSizeOption !== undefined ? defaultReportPdfPageSizeOption : offerManager.getDefaultReportPdfPageSizeOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSDEFAULTREPORTPDFPAGESIZE).setVal(defaultReportPdfPageSizeOptionToUse);

            globals.user.updateUser({
                defaultReportPdfPageSize: defaultReportPdfPageSizeOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.DEFAULT_PDF_PAGE_SIZE);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getReportLogo() !== originalSettings.getReportLogo()) {
            settingsChanged = true;
            // globals.user.setReportLogo(newSettings.getReportLogo() !== globals.user.getReportGraphics().landscapeReportHeaderLeftURL ? newSettings.getReportLogo() : '');
            globals.user.setReportLogo(newSettings.getReportLogo());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_LOGO);
        }
        if (newSettings.getSpecifyBodyPositionAs() !== originalSettings.getSpecifyBodyPositionAs()) {
            var specifyBodyPositionAsOption = offerManager.getSpecifyBodyPositionAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyBodyPositionAs();
                });
            var specifyBodyPositionAsOptionToUse = specifyBodyPositionAsOption !== undefined ? specifyBodyPositionAsOption : offerManager.getSpecifyBodyPositionAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYBODYPOSITIONAS).setVal(specifyBodyPositionAsOptionToUse);

            globals.user.updateUser({
                specifyBodyPositionAs: specifyBodyPositionAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_BODY_POSITION_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getSpecifyEquipmentPositionAs() !== originalSettings.getSpecifyEquipmentPositionAs()) {
            var specifyEquipmentPositionAsOption = offerManager.getSpecifyEquipmentPositionAsOptions()
                .find(function (option) {
                    return option.id === newSettings.getSpecifyEquipmentPositionAs();
                });
            var specifyEquipmentPositionAsOptionToUse = specifyEquipmentPositionAsOption !== undefined ? specifyEquipmentPositionAsOption : offerManager.getSpecifyEquipmentPositionAsOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYEQUIPMENTPOSITIONAS).setVal(specifyEquipmentPositionAsOptionToUse);

            globals.user.updateUser({
                specifyEquipmentPositionAs: specifyEquipmentPositionAsOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_EQUIPMENT_POSITION_AS);
            settingsChanged = true;
            refreshMenu = true;
        }
        if (newSettings.getShowTips() !== originalSettings.getShowTips()) {
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSHOWTIPS).value.value = newSettings.getShowTips();
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SHOW_TIPS);
            settingsChanged = true;
        }
        if (newSettings.getReportViewLayout() !== originalSettings.getReportViewLayout()) {
            var reportViewLayoutOption = offerManager.getReportViewLayoutOptions()
                .find(function (option) {
                    return option.id === newSettings.getReportViewLayout();
                });
            var reportViewLayoutOptionToUse = reportViewLayoutOption !== undefined ? reportViewLayoutOption : offerManager.getReportViewLayoutOptions()[0];
            offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSREPORTVIEWLAYOUT).setVal(reportViewLayoutOptionToUse);

            globals.user.updateUser({
                reportViewLayout: reportViewLayoutOptionToUse.id
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_VIEW_LAYOUT);
            settingsChanged = true;
            refreshMenu = true;
        }

        if (areThereChangesToDashboardDataPoints(newSettings, originalSettings)) {
            settingsChanged = true;
            globals.user.replaceDashboardDataPointsUsingArrayOfIds(newSettings.getDashboardDataPointIds());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.DASHBOARD_DATA_POINTS);
        }

        if (areThereChangesToInternalStandards(newSettings, originalSettings)) {
            settingsChanged = true;
            globals.user.replaceInternalStandardsUsingArray(newSettings.getInternalStandardsToBeSaved());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.INTERNAL_STANDARDS);
        }

        if (areThereChangesToReportViews(newSettings, originalSettings)) {
            settingsChanged = true;
            globals.user.replaceReportViewsUsingArray(newSettings.getReportViewObjectsToBeSaved());
            globals.user.updateUser({
                reportViews: newSettings.getReportViewObjectsToBeSaved()
            });
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_VIEWS);
        }

        if (newSettings.getCompanyAdditionalNote() !== originalSettings.getCompanyAdditionalNote()) {
            settingsChanged = true;
            globals.user.updateUser({
                companyAdditionalNote: newSettings.getCompanyAdditionalNote()
            });
            globals.user.setCompanyAdditionalNote(newSettings.getCompanyAdditionalNote());
            addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.COMPANY_ADDITIONAL_NOTE);
        }

        if (refreshMenu && offerManager.getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback() !== null) {
            offerManager.getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback()(updateAxleMenuStructures);
        }
    }

    if (areThereChangesToDashboardLegislations(newSettings, originalSettings)) {
        globals.user.replaceDashboardLegislationsUsingArray(newSettings.getDashboardLegislationsToBeSaved());
        addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.LEGISLATION);
        settingsChanged = true;
        offerManager.setLegislations(newSettings.getActiveLegislations());
        offerManager.notifyGraphicsManager()('Legislations_Changed');
        updateValuesOtherThanLegislation().then(function (){
            changesAppliedDfd.resolve(settingsChanged);
        });

    } else {
        updateValuesOtherThanLegislation().then(function (){
            changesAppliedDfd.resolve(settingsChanged);
        });
    }

    return changesAppliedDfd.promise();
}

function changesAppliedToIntegrations(objectToCompare) {
    var returnValue = false;

    return returnValue;
}

function changesAppliedToNTEAIntegration(newSettings, originalSettings) {
    var returnValue = false,
        activeNteaObject = newSettings.getIntegrationUsingType('NTEA'),
        originalNteaObject = originalSettings.getIntegrationUsingType('NTEA'),
        isMembershipNumberEmpty = activeNteaObject.getAttribute1() === '' ? true : false,
        isUsernameEmpty = activeNteaObject.getAttribute2() === '' ? true : false,
        isPasswordEmpty = activeNteaObject.getPassword() === '' ? true : false;
    if (activeNteaObject !== null && originalNteaObject !== null) {
        Object.keys(activeNteaObject).forEach(function (keyName) {
            if (activeNteaObject[keyName] !== originalNteaObject[keyName]) {
                if (keyName === 'OperationType') {
                    if(newSettings.getNteaUserValidationType() === config.NTEA_USER_VALIDATION_TYPES.NTEA) {
                        if (!isUsernameEmpty || !isPasswordEmpty) {
                            returnValue = true;
                        }
                    } else {
                        if (!isUsernameEmpty || !isMembershipNumberEmpty) {
                            returnValue = true;
                        }
                    }
                } else {
                    returnValue = true;
                }
            }
        });
    }
    return returnValue;
    //return true;
}

function areThereChangesToDashboardLegislations(newSettings, originalSettings) {
    if (newSettings.getDashboardLegislationIds().length !== originalSettings.getDashboardLegislationIds().length) {
        return true;
    }

    for (var i = 0; i < newSettings.getDashboardLegislationIds().length; i++) {
        if (newSettings.getDashboardLegislationIds()[i] !== originalSettings.getDashboardLegislationIds()[i]) {
            return true;
        }
    }

    return false;
}

function areThereChangesToDashboardDataPoints(newSettings, originalSettings) {
    if (newSettings.getDashboardDataPointIds().length !== originalSettings.getDashboardDataPointIds().length) {
        return true;
    }

    for (var i = 0; i < newSettings.getDashboardDataPointIds().length; i++) {
        if (newSettings.getDashboardDataPointIds()[i] !== originalSettings.getDashboardDataPointIds()[i]) {
            return true;
        }
    }

    return false;
}

function areThereChangesToInternalStandards(newSettings, originalSettings) {
    if (newSettings.getInternalStandards().length !== originalSettings.getInternalStandards().length) {
        return true;
    }

    for (var i = 0; i < newSettings.getInternalStandards().length; i++) {
        var activeInternalStandard = newSettings.getInternalStandards()[i],
            originalInternalStandard = originalSettings.getInternalStandards()[i];

        if (activeInternalStandard.getIsActive() !== originalInternalStandard.getIsActive()) {
            return true;
        }
        if (activeInternalStandard.getDataPointId() !== originalInternalStandard.getDataPointId()) {
            return true;
        }
        if (activeInternalStandard.getRangeMin() !== originalInternalStandard.getRangeMin()) {
            return true;
        }
        if (activeInternalStandard.getRangeMax() !== originalInternalStandard.getRangeMax()) {
            return true;
        }
        if (activeInternalStandard.getRangeType() !== originalInternalStandard.getRangeType()) {
            return true;
        }
    }

    return false;
}

/**
     * Checks if any changes have been made to the report views
     * @param {object} objectToCompare - The original SettingsObject created when the modal was opened
     * @returns {boolean} - True if there are changes to the report views, false if not
     */
function areThereChangesToReportViews(newSettings, originalSettings) {
    if (newSettings.getReportViews().length !== originalSettings.getReportViews().length) {
        return true;
    }

    for (var i = 0; i < newSettings.getReportViews(0).length; i++) {
        var activeReportView = newSettings.getReportViews()[i],
            originalReportView = originalSettings.getReportViews()[i];

        if(activeReportView.getIsBlankRow() === true) {
            return false;
        }
        if (activeReportView.getIsActive() !== originalReportView.getIsActive()) {
            return true;
        }
        if (activeReportView.getViewType() !== originalReportView.getViewType()) {
            return true;
        }
        if (activeReportView.getViewDetail() !== originalReportView.getViewDetail()) {
            return true;
        }
        if (activeReportView.getElevation() !== originalReportView.getElevation()) {
            return true;
        }
        if (activeReportView.getDisplay() !== originalReportView.getDisplay()) {
            return true;
        }
        if (activeReportView.getOrder() !== originalReportView.getOrder()) {
            return true;
        }
    }

    return false;
}



function newSettingsObject(offerOpen) {
    return new SettingsObject(globals.user.getActiveMeasurementSystemId(),
    globals.user.getLengthIncrement(),
    globals.user.getMassIncrement(),
    globals.user.getPercentageIncrement(),
    globals.user.getActiveLegislationId(),
    offerOpen ? offerManager.activeApplication.value.id : globals.user.getDefaultApplication(),
    globals.user.getColourDrawings(),
    globals.user.getShowComplianceScorecard(),
    globals.user.getSpecifyWheelbaseAs(),
    integrations.getAvailableIntegrations(),
    globals.user.getSpecifyCabDimensionsAs(),
    globals.user.getSpecifyChassisLengthAs(),
    globals.user.getSpecifyFuelConsumptionAs(),
    globals.user.getSpecifyLicencingRegionAs(),
    globals.user.getSpecifyAxleRatingAs(),
    globals.user.getDefaultReportPdfPageSize(),
    globals.user.getReportLogo(),
    globals.user.getDashboardConfiguration().getDashboardDataPointIds(),
    globals.user.getDashboardConfiguration().getDashboardLegislationIds(),
    globals.user.getDashboardConfiguration().getInternalStandardObjects(),
    globals.user.getCompanyAdditionalNote(),
    globals.user.getSpecifyBodyPositionAs(),
    globals.user.getSpecifyEquipmentPositionAs(),
    globals.user.getShowTips(),
    globals.user.getReportViewLayout(),
    globals.user.getReportViewsObjects(),
    globals.user.getNteaUserValidationType());
}

// #region Settings
function SettingsInternalStandard(init) {
    InternalStandard.call(this, init);
    var settingsInternalStandards = this;
    var _id = typeof init.Id === 'number' ? init.Id : 0,
        _isBlankRow = typeof init.IsBlankRow === 'boolean' ? init.IsBlankRow : false;

    function getId() {
        return _id;
    }

    function setId(newValue) {
        _id = newValue;
    }

    function getIsBlankRow() {
        return _isBlankRow;
    }

    function setIsBlankRow(newValue) {
        _isBlankRow = newValue;
    }

    function clone() {
        var initObj = {
            Id: globals.nextId(),
            IsBlankRow: this.getIsBlankRow(),
            DataPointId: this.getDataPointId(),
            RangeMin: this.getRangeMin(),
            RangeMax: this.getRangeMax(),
            RangeType: this.getRangeType(),
            IsActive: this.getIsActive()
        };
        return new SettingsInternalStandard(initObj);
    }

    settingsInternalStandards.getId = getId;
    settingsInternalStandards.setId = setId;
    settingsInternalStandards.getIsBlankRow = getIsBlankRow;
    settingsInternalStandards.setIsBlankRow = setIsBlankRow;
    settingsInternalStandards.clone = clone;
}

function SettingsReportView(init) {
    ReportView.call(this, init);
    var settingsReportView = this;
    // var _id = globals.nextId(),
    var _id = init.Id !== undefined ? init.Id : init.Order,
        _isBlankRow = typeof init.IsBlankRow === 'boolean' ? init.IsBlankRow : false;

    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getIsBlankRow() {
        return _isBlankRow;
    }
    function setIsBlankRow(newValue) {
        _isBlankRow = newValue;
    }
    function clone() {
        var initObj = {
            Id: globals.nextId(),
            IsBlankRow: this.getIsBlankRow(),
            ViewType: this.getViewType(),
            ViewDetail: this.getViewDetail(),
            Elevation: this.getElevation(),
            Display: this.getDisplay(),
            Order: this.getOrder(),
            IsActive: this.getIsActive()
        };
        return new SettingsReportView(initObj);
    }

    settingsReportView.getId = getId;
    settingsReportView.setId = setId;
    settingsReportView.getIsBlankRow = getIsBlankRow;
    settingsReportView.setIsBlankRow = setIsBlankRow;
    settingsReportView.clone = clone;
}

/**
         * Function to create an empty object to create a row for the report view table
         * @param {number} id - ID to use for the report view object
         * @returns {Object} ReportView object
         */
function createEmptyReportView(settingsObject, arr) {
    var emptyReportView = {
            ViewType: '',
            ViewDetail: '',
            Elevation: '',
            Display: '',
            Order: getOrderForReportViewBlankRow(settingsObject, arr),
            IsActive: false,
            IsBlankRow: true
        };
    return new SettingsReportView(emptyReportView);
}

function getOrderForReportViewBlankRow(settingsObject, arr) {
    var arrayToCheck = Array.isArray(arr) === true ? arr : settingsObject.getReportViews(),
        newId = 0;

    arrayToCheck.forEach(function (item) {
        if (item.getOrder() > newId) {
            newId = item.getOrder()
        }
    });

    return newId + 1;
}
// #endregion Settings

async function saveSettings(settingsObject, listOfIntegrationsToBeSaved) {
    $.when(dataManager.saveUserSettings(settingsObject.getLegislationId(), 
                                    settingsObject.getMeasurementSystem().id, 
                                    settingsObject.getLengthIncrement(), 
                                    settingsObject.getMassIncrement(), 
                                    settingsObject.getPercentageIncrement(), 
                                    settingsObject.getColourDrawings(), 
                                    settingsObject.getShowComplianceScorecard(), 
                                    settingsObject.getSpecifyWheelbaseAs(), 
                                    listOfIntegrationsToBeSaved, 
                                    settingsObject.getSpecifyCabDimensionsAs(), 
                                    settingsObject.getSpecifyChassisLengthAs(), 
                                    settingsObject.getSpecifyFuelConsumptionAs(), 
                                    settingsObject.getSpecifyLicencingRegionAs(), 
                                    settingsObject.getSpecifyAxleRatingAs(), 
                                    settingsObject.getDefaultReportPdfPageSize(), 
                                    settingsObject.getReportLogo(), 
                                    globals.user.getDashboardConfiguration().getObjectToBeSaved(), 
                                    settingsObject.getCompanyAdditionalNote(), 
                                    settingsObject.getSpecifyBodyPositionAs(), 
                                    settingsObject.getSpecifyEquipmentPositionAs(), 
                                    settingsObject.getShowTips(), 
                                    settingsObject.getReportViewLayout(), 
                                    settingsObject.getReportViewObjectsToBeSaved(),
                                    settingsObject.getReportPdfSignatures(),
                                    settingsObject.getReportPdfStamps()))
                                .then(
                                function (success) {
                                    if (changedSettingNames !== '') {
                                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_SETTINGS, {
                                            SettingName: changedSettingNames
                                        }));
                                    }
                                    return success;
                                },
                                function (error) {
                                    throw error;
                                });
}

function setOfferManager(oM) {
    offerManager = oM;
}

export default {
    newSettingsObject,
    getAvailableIntegrations: integrations.getAvailableIntegrations,
    // getAvailableIntegrations: getAvailableIntegrations,
    applyAnyChanges,
    saveSettings,
    setOfferManager,
    createEmptyReportView,
}
