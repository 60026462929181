import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';
import FourCornerDistributionMixin from '../configuration/mixin.fourCornerDistribution';


    
function Hitch(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel,
                type, status, offset, height, heightFromGround, couplingHeight, width, mass, massOverride, mountingLength, couplingOffset, funnelLength,
                cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, fromLeftOfVehicleRearWidth,
                verticalCOGType, lateralCOGType,
                verticalCOGConfirmed, lateralCOGConfirmed,
    sourceDatabaseId, updateCounter, cached,
    costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, isPinned) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);
    
    this._type = type;
    this._status = status;
    this._offset = offset;
    this._height = height;
    this._heightFromGround = heightFromGround;
    this._couplingHeight = couplingHeight;
    this._width = width;
    this._mass = mass;
    //this._massOverride = massOverride;
    this._mountingLength = mountingLength;
    this._couplingOffset = couplingOffset;
    this._funnelLength = funnelLength;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    this._fromLeftOfVehicleRearWidth = fromLeftOfVehicleRearWidth || 0;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;
}
    
Hitch.prototype.getType = function () {
    return this._type;
}
Hitch.prototype.setType = function (newValue) {
    this._type = newValue;
}
Hitch.prototype.getStatus = function () {
    return this._status;
}
Hitch.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Hitch.prototype.getOffset = function () {
    return this._offset;
}
Hitch.prototype.setOffset = function (newValue) {
    this._offset = newValue;
}
Hitch.prototype.getHeight = function () {
    return this._height;
}
Hitch.prototype.setHeight = function (newValue) {
    this._height = newValue;
}
Hitch.prototype.getHeightFromGround = function () {
    return this._heightFromGround;
}
Hitch.prototype.setHeightFromGround = function (newValue) {
    this._heightFromGround = newValue;
}
Hitch.prototype.getCouplingHeight = function () {
    return this._couplingHeight;
}
Hitch.prototype.setCouplingHeight = function (newValue) {
    this._couplingHeight = newValue;
}
Hitch.prototype.getWidth = function () {
    return this._width;
}
Hitch.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
Hitch.prototype.getMass = function () {
    return this._mass;
}
Hitch.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
Hitch.prototype.getMassOverride = function () {
    return this._massOverride;
}
Hitch.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Hitch.prototype.getMountingLength = function () {
    return this._mountingLength;
}
Hitch.prototype.setMountingLength = function (newValue) {
    this._mountingLength = newValue;
}
Hitch.prototype.getCouplingOffset = function () {
    return this._couplingOffset;
}
Hitch.prototype.setCouplingOffset = function (newValue) {
    this._couplingOffset = newValue;
}
Hitch.prototype.getFunnelLength = function () {
    return this._funnelLength;
}
Hitch.prototype.setFunnelLength = function (newValue) {
    this._funnelLength = newValue;
}
Hitch.prototype.getLength = function () {
    return this._mountingLength + this._couplingOffset + this._funnelLength;
}
Hitch.prototype.getCOGType = function () {
    return this._cOGType;
}
Hitch.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
Hitch.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
Hitch.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
Hitch.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
Hitch.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
Hitch.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Hitch.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Hitch.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Hitch.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Hitch.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Hitch.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Hitch.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Hitch.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Hitch.prototype.getFromLeftOfVehicleRearWidth = function () {
    return this._fromLeftOfVehicleRearWidth;
}
Hitch.prototype.setFromLeftOfVehicleRearWidth = function (newValue) {
    this._fromLeftOfVehicleRearWidth = newValue;
}
Hitch.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Hitch.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Hitch.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Hitch.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Hitch.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Hitch.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Hitch.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Hitch.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Hitch.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Hitch.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Hitch.prototype.getTotalLoad = function () {
    return this._mass;
}
Hitch.prototype.getIsPinned = function () {
    return this._isPinned;
}
Hitch.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
}
Hitch.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    }else {
        return this._horizontalCOG * (this._mountingLength + this._couplingOffset + this._funnelLength) / 100;
    }
}
Hitch.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * (this._height) / 100;
    }
}
Hitch.prototype.getActualLateralCOG = function () {
    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this._lateralCOG * this._width / 100;
    }
}
Hitch.prototype.getActualHeight = function () {
    return this._height
}

/**
 * Get the hitch JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Hitch.prototype.getHitchObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        "Type": this._type,
        "Status": this._status,
        //"Offset": includePositionValues === true ? this._offset : 0,
        "Height": this._height,
        //"HeightFromGround": includePositionValues === true ? this._heightFromGround : 0,
        "CouplingHeight": this._couplingHeight,
        "Width": this._width,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "MountingLength": this._mountingLength,
        "CouplingOffset": this._couplingOffset,
        "FunnelLength" : this._funnelLength,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType
        //"FromLeftOfVehicleRearWidth": includePositionValues === true ? this._fromLeftOfVehicleRearWidth : 0
    };

    if (includePositionValues === true) {
        saveObject["Offset"] = this._offset;
        saveObject["HeightFromGround"] = this._heightFromGround;
        saveObject["FromLeftOfVehicleRearWidth"] = this._fromLeftOfVehicleRearWidth;
    }

    return saveObject;
}

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Hitch.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getHitchObjectToBeSaved(includePositionValues), includePositionValues);
}

Hitch.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newHitch = new Hitch(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._type,
        this._status,
        this._offset,
        this._height,
        this._heightFromGround,
        this._couplingHeight,
        this._width,
        this._mass,
        this._massOverride,
        this._mountingLength,
        this._couplingOffset,
        this._funnelLength,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfVehicleRearWidth,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType());

    newHitch.setAccessLevel(this.getAccessLevel());
    newHitch.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newHitch.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());

    return newHitch;
}

Hitch.prototype.update = function (hitch) {
    this.updateBase(hitch);
    this.setType(hitch.getType());
    this.setStatus(hitch.getStatus());
    this.setOffset(hitch.getOffset());
    this.setHeight(hitch.getHeight());
    this.setHeightFromGround(hitch.getHeightFromGround());
    this.setCouplingHeight(hitch.getCouplingHeight());
    this.setWidth(hitch.getWidth());
    this.setMass(hitch.getMass());
    this.setMassOverride(hitch.getMassOverride());
    this.setMountingLength(hitch.getMountingLength());
    this.setCouplingOffset(hitch.getCouplingOffset());
    this.setFunnelLength(hitch.getFunnelLength());
    this.setCOGType(hitch.getCOGType());
    this.setHorizontalCOG(hitch.getHorizontalCOG());
    this.setHorizontalCOGOverride(hitch.getHorizontalCOGOverride());
    this.setVerticalCOG(hitch.getVerticalCOG());
    this.setVerticalCOGOverride(hitch.getVerticalCOGOverride());
    this.setLateralCOG(hitch.getLateralCOG());
    this.setLateralCOGOverride(hitch.getLateralCOGOverride());
    this.setFromLeftOfVehicleRearWidth(hitch.getFromLeftOfVehicleRearWidth());
    this.setVerticalCOGType(hitch.getVerticalCOGType());
    this.setLateralCOGType(hitch.getLateralCOGType());
    this.setVerticalCOGConfirmed(hitch.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(hitch.getLateralCOGConfirmed());
}

utils.extend(Accessory, Hitch);
FourCornerDistributionMixin.call(Hitch.prototype);

export default Hitch;
