import utils from '../../../services/utils';


var FourCornerDistributionMixin = function () {
    

    this.getFrontLeftAxleLoad = function(frontAxleLoad, cogToRightMostOfTrackWidthFront, trackWidthFront) {
        return utils.getTurningMomentsLeft(frontAxleLoad, cogToRightMostOfTrackWidthFront, trackWidthFront);
        // return frontAxleLoad - this.getFrontRightAxleLoad(frontAxleLoad, cogToRightMostOfTrackWidthFront, trackWidthFront);
    }
    this.getRearLeftAxleLoad = function(rearAxleLoad, cogToRightMostOfTrackWidthRear, trackWidthRear) {
        return utils.getTurningMomentsLeft(rearAxleLoad, cogToRightMostOfTrackWidthRear, trackWidthRear);
        // return rearAxleLoad - this.getRearRightAxleLoad(rearAxleLoad, cogToRightMostOfTrackWidthRear, trackWidthRear);
    }
    this.getFrontRightAxleLoad = function(frontAxleLoad, cogToRightMostOfTrackWidthFront, trackWidthFront) {
        return frontAxleLoad - this.getFrontLeftAxleLoad(frontAxleLoad, cogToRightMostOfTrackWidthFront, trackWidthFront);
    }
    this.getRearRightAxleLoad = function(rearAxleLoad, cogToRightMostOfTrackWidthRear, trackWidthRear) {
        return rearAxleLoad - this.getRearLeftAxleLoad(rearAxleLoad, cogToRightMostOfTrackWidthRear, trackWidthRear);
    }
    
}

export default FourCornerDistributionMixin;
