import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';
import FourCornerDistributionMixin from '../configuration/mixin.fourCornerDistribution';


    
function Other(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel, length, width, height, mass, massOverride, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, status,
                lateralCOG, lateralCOGOverride, fromLeftOfVehicleRearWidth, verticalCOGType, lateralCOGType,
    verticalCOGConfirmed, lateralCOGConfirmed, type, sourceDatabaseId, updateCounter, cached, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, isPinned) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, type, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    
    this._length = length;
    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    this._fromLeftOfVehicleRearWidth = fromLeftOfVehicleRearWidth || 0;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._status = status;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;
    this._fromAxle1Front = 0;
    this._fromCentreOfVehicle = 0;
    // this._fromCentreOfVehicleOverride = false;
    
    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;


}
    

Other.prototype.getLength = function() {
    return this._length;
}
Other.prototype.setLength = function (newValue) {
    this._length = newValue;
}
Other.prototype.getWidth = function () {
    return this._width;
}
Other.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
Other.prototype.getHeight = function () {
    return this._height;
}
Other.prototype.setHeight = function (newValue) {
    this._height = newValue;
}
Other.prototype.getMass = function () {
    return this._mass;
}
Other.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
Other.prototype.getMassOverride = function () {
    return this._massOverride;
}
Other.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Other.prototype.getCOGType = function () {
    return this._cOGType;
}
Other.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
Other.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
Other.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
Other.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
Other.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
Other.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Other.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Other.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Other.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Other.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Other.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Other.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Other.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Other.prototype.getFromLeftOfVehicleRearWidth = function () {
    return this._fromLeftOfVehicleRearWidth;
}
Other.prototype.setFromLeftOfVehicleRearWidth = function (newValue) {
    this._fromLeftOfVehicleRearWidth = newValue;
}
Other.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Other.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Other.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Other.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Other.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Other.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Other.prototype.getTotalLoad = function () {
    return this._mass;
}
Other.prototype.getStatus = function () {
    return this._status;
}
Other.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Other.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Other.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Other.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Other.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Other.prototype.getIsPinned = function () {
    return this._isPinned;
}
Other.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
}
Other.prototype.getFromAxle1Front = function () {
    return this._fromAxle1Front;
}
Other.prototype.setFromAxle1Front = function (newValue) {
    this._fromAxle1Front = newValue;
}
Other.prototype.getFromCentreOfVehicle = function () {
    return this._fromCentreOfVehicle;
}
Other.prototype.setFromCentreOfVehicle = function (newValue) {
    this._fromCentreOfVehicle = newValue;
}
// Other.prototype.getFromCentreOfVehicleOverride = function () {
//     return this._fromCentreOfVehicleOverride;
// }
// Other.prototype.setFromCentreOfVehicleOverride = function (newValue) {
//     this._fromCentreOfVehicleOverride = newValue;
// }
Other.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    }else {
        return this._horizontalCOG * this._length / 100;
    }
}
Other.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * this._height / 100;
    }
}
Other.prototype.getActualLateralCOG = function () {

    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return (this._lateralCOG * this._width / 100);
    }
}
Other.prototype.getActualHeight = function () {
    return this._height
}

/**
 * Get the hitch JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Other.prototype.getOtherObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        "Length": this._length,
        "Width": this._width,
        "Height": this._height,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "Status": this._status,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "Type": this._accessorySubType.toUpperCase(),
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType
        //"FromLeftOfVehicleRearWidth": includePositionValues === true ? this._fromLeftOfVehicleRearWidth : 0
    };

    if (includePositionValues === true) {
        saveObject["FromLeftOfVehicleRearWidth"] = this._fromLeftOfVehicleRearWidth;
    }

    return saveObject;
}

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Other.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getOtherObjectToBeSaved(includePositionValues), includePositionValues);
}

Other.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newOther = new Other(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._length,
        this._width,
        this._height,
        this._mass,
        this._massOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._status,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfVehicleRearWidth,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this._type,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType());

    newOther.setAccessLevel(this.getAccessLevel());
    newOther.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newOther.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    newOther.setFromCentreOfVehicle(this.getFromCentreOfVehicle());

    return newOther;
}

Other.prototype.update = function (other) {
    this.updateBase(other);
    this.setLength(other.getLength());
    this.setWidth(other.getWidth());
    this.setHeight(other.getHeight());
    this.setMass(other.getMass());
    this.setMassOverride(other.getMassOverride());
    this.setCOGType(other.getCOGType());
    this.setHorizontalCOG(other.getHorizontalCOG());
    this.setHorizontalCOGOverride(other.getHorizontalCOGOverride());
    this.setVerticalCOG(other.getVerticalCOG());
    this.setVerticalCOGOverride(other.getVerticalCOGOverride());
    this.setStatus(other.getStatus());
    this.setLateralCOG(other.getLateralCOG());
    this.setLateralCOGOverride(other.getLateralCOGOverride());
    this.setFromLeftOfVehicleRearWidth(other.getFromLeftOfVehicleRearWidth());
    this.setVerticalCOGType(other.getVerticalCOGType());
    this.setLateralCOGType(other.getLateralCOGType());
    this.setVerticalCOGConfirmed(other.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(other.getLateralCOGConfirmed());
    this.setAccessorySubType(other.getAccessorySubType());
    this.setFromCentreOfVehicle(other.getFromCentreOfVehicle());
}

utils.extend(Accessory, Other);
FourCornerDistributionMixin.call(Other.prototype);

export default Other;

