import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import PayloadObjectsHolder from './model.payloadObjectsHolder';
import Accessory from './model.accessory';
import AxlesHolder from './model.axlesHolder';
import ChassisObjectMixin from './mixin.chassisObject';
import AccessoryHolder from './model.accessoryHolder';
// import { PayloadObjectsHolder, Accessory, AxlesHolder, ChassisObjectMixin, AccessoryHolder } from '../../offer';


var Trailer = function (id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, sourceDatabaseId, savedFromSourceDatabaseId, editLevel,
                    type, numberOfAxlesFront, numberOfAxlesRear, gvm, overallWidth, kingpinHeight, chassisHeight, overallHeight, fifthWheelOffset, fifthWheelHeight, drawbarType, drawbarLength, drawbarHeight,
                    turntableHeight, tyreRadius, frontOverhang, kingpinToLandingLegs, hitchPointToLandingLegs, axle1FrontToAxle2Front, axle2FrontToAxle3Front, rearMostFrontAxleToAxle1Rear, hitchPointToAxle1Rear,
                    kingpinToAxle1Rear, axle1RearToAxle2Rear, axle2RearToAxle3Rear, axle3RearToAxle4Rear, rearOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, frontToStartOfTaper, payloadCOGType,
                    payloadHorizontalCOG, materialOfChassis, tareType, tareTotal, tareFront, tareFrontOverride, tareRear, tareRearOverride, manufacturersFrontAxlesMass, manufacturersRearAxlesMass, numberOfTyresOnAxle1Front, numberOfTyresOnAxle2Front, numberOfTyresOnAxle3Front,
                    frontRoadFriendlySuspension, numberOfTyresOnAxle1Rear, numberOfTyresOnAxle2Rear, numberOfTyresOnAxle3Rear, numberOfTyresOnAxle4Rear, rearRoadFriendlySuspension, hitchIncluded, hitchOffset, hitchHeight,
                    tyreWidth, status, overallHeightBreakpoint, overallHeightMaxCut, drawbarProtrusionBreakpoint, drawbarProtrusionMaxCut, frontOverhangBreakpoint, frontOverhangMaxCut, wheelbaseTheoreticalBreakpoint,
                    wheelbaseTheoreticalMaxCut, trailerLengthBreakpoint, trailerLengthMaxCut, bodyType, axles, permissibleFront, permissibleFrontOverride, permissibleRear, permissibleRearOverride,
                    wheelbaseTheoreticalChangeIncrement, lengthChangeIncrement, frontOverhangChangeIncrement, overallHeightChangeIncrement, drawbarProtrusionChangeIncrememt,
                    rigPosition, manufacturersFrontAxlesMassOverride, manufacturersRearAxlesMassOverride,
                    verticalCOG, verticalCOGOverride, verticalCOGConfirmed, payloadVerticalCOG, payloadVerticalCOGOverride, payloadVerticalCOGConfirmed,
                    payloadType, trailerFrontAxleAverageTyreLife, trailerRearAxleAverageTyreLife,
                    lateralCOG, lateralCOGOverride, lateralCOGConfirmed, payloadLateralCOG, payloadLateralCOGOverride, payloadLateralCOGConfirmed, tyrePriceRemainingSets,
                    licenceFees, licenceFeesOverride, licenceFeesRegionId, updateCounter, cached, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType,
                    payloadVerticalCOGType, payloadLateralCOGType, fuelAndAdblueTotalCostPerMonth, fuelAndAdblueTotalCostPerMonthOverride, fuelAndAdblueEquipmentFuelConsumption,
                    fuelAndAdblueEquipmentFuelCostPerVolume, fuelAndAdblueEquipmentFuelCostPerVolumeOverride, fuelAndAdblueEquipmentUtilisation,
                    gVMOverride) {

    //var accessoryType = config.ACCESSORY_TYPES.BODY;
    //#region Private members

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);
    //AxlesMixin.call(this);
    

    this._accessoryHolder = new AccessoryHolder(this);
    this._axlesHolder = new AxlesHolder(parent, trailerFrontAxleAverageTyreLife, trailerRearAxleAverageTyreLife, null, null, null, tyrePriceRemainingSets, tyrePriceRemainingSets);
    this._axlesHolder.createAxles(axles);
    this._payloadHolder = new PayloadObjectsHolder(parent, payloadType);

    this._type = type;
    this._numberOfAxlesFront = numberOfAxlesFront;
    this._numberOfAxlesRear = numberOfAxlesRear;
    this._gvm = gvm;
    
    this._overallWidth = overallWidth;
    this._chassisHeight = chassisHeight;
    this._overallHeight = overallHeight;
    this._fifthWheelOffset = fifthWheelOffset;
    this._fifthWheelHeight = fifthWheelHeight;
    this._drawbarType = drawbarType;
    this._drawbarLength = drawbarLength;
    this._drawbarHeight = drawbarHeight;
    this._turntableHeight = turntableHeight;
    this._tyreRadius = tyreRadius;
    this._hitchPointToLandingLegs = hitchPointToLandingLegs;
    this._axle1FrontToAxle2Front = axle1FrontToAxle2Front;
    this._axle2FrontToAxle3Front = axle2FrontToAxle3Front;
    this._rearMostFrontAxleToAxle1Rear = rearMostFrontAxleToAxle1Rear;
    this._hitchPointToAxle1Rear = hitchPointToAxle1Rear;
    //this._kingpinToAxle1Rear = kingpinToAxle1Rear;
    this._axle1RearToAxle2Rear = axle1RearToAxle2Rear;
    this._axle2RearToAxle3Rear = axle2RearToAxle3Rear;
    this._axle3RearToAxle4Rear = axle3RearToAxle4Rear;
    this._axle1FrontToWheelbaseTheoreticalStart = axle1FrontToWheelbaseTheoreticalStart;
    this._wheelbaseTheoretical = wheelbaseTheoretical;
    this._frontToStartOfTaper = frontToStartOfTaper;
    this._payloadCOGType = payloadCOGType;
    this._payloadHorizontalCOG = payloadHorizontalCOG;
    this._materialOfChassis = materialOfChassis;
    this._tareType = tareType;
    this._tareTotal = tareTotal;
    this._tareFront = tareFront;
    this._tareFrontOverride = tareFrontOverride;
    this._tareRear = tareRear;
    this._tareRearOverride = tareRearOverride;
    this._manufacturersFrontAxlesMass = manufacturersFrontAxlesMass;
    this._manufacturersRearAxlesMass = manufacturersRearAxlesMass;
    this._numberOfTyresOnAxle1Front = numberOfTyresOnAxle1Front;
    this._numberOfTyresOnAxle2Front = numberOfTyresOnAxle2Front;
    this._numberOfTyresOnAxle3Front = numberOfTyresOnAxle3Front;
    this._frontRoadFriendlySuspension = frontRoadFriendlySuspension;
    this._numberOfTyresOnAxle1Rear = numberOfTyresOnAxle1Rear;
    this._numberOfTyresOnAxle2Rear = numberOfTyresOnAxle2Rear;
    this._numberOfTyresOnAxle3Rear = numberOfTyresOnAxle3Rear;
    this._numberOfTyresOnAxle4Rear = numberOfTyresOnAxle4Rear;
    this._rearRoadFriendlySuspension = rearRoadFriendlySuspension;
    this._tyreWidth = tyreWidth;
    this._status = status;
    this._overallHeightBreakpoint = overallHeightBreakpoint;
    this._overallHeightMaxCut = overallHeightMaxCut;
    this._drawbarProtrusionBreakpoint = drawbarProtrusionBreakpoint;
    this._drawbarProtrusionMaxCut = drawbarProtrusionMaxCut;
    this._wheelbaseTheoreticalBreakpoint = wheelbaseTheoreticalBreakpoint;
    this._wheelbaseTheoreticalMaxCut = wheelbaseTheoreticalMaxCut;
    this._trailerLengthBreakpoint = trailerLengthBreakpoint;
    this._trailerLengthMaxCut = trailerLengthMaxCut;
    this._bodyType = bodyType;
    this._permissibleFront = permissibleFront;
    this._permissibleFrontOverride = permissibleFrontOverride;
    this._permissibleRear = permissibleRear;
    this._permissibleRearOverride = permissibleRearOverride;
    this._manufacturersRearAxlesMassOverride = manufacturersRearAxlesMassOverride || false;
    this._manufacturersFrontAxlesMassOverride = manufacturersFrontAxlesMassOverride || false;

    this._overallLengthExclAccessories = 0;
    this._wheelbaseTheoreticalChangeIncrement = wheelbaseTheoreticalChangeIncrement;
    this._lengthChangeIncrement = lengthChangeIncrement;
    this._frontOverhangChangeIncrement = frontOverhangChangeIncrement;
    this._overallHeightChangeIncrement = overallHeightChangeIncrement;
    this._drawbarProtrusionChangeIncrement = drawbarProtrusionChangeIncrememt;
    
    this._rigPosition = rigPosition;

    this._verticalCOG = verticalCOG;
    
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._payloadVerticalCOG = payloadVerticalCOG;
    this._payloadVerticalCOGOverride = payloadVerticalCOGOverride;
    this._payloadVerticalCOGConfirmed = payloadVerticalCOGConfirmed;
    this._lateralCOG = lateralCOG;
    
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._payloadLateralCOG = payloadLateralCOG;
    this._payloadLateralCOGOverride = payloadLateralCOGOverride;
    this._payloadLateralCOGConfirmed = payloadLateralCOGConfirmed;

    if (this.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        this._verticalCOGOverride = true;
        this._lateralCOGOverride = true;
        this._gVMOverride = true;
    } else {
        this._verticalCOGOverride = verticalCOGOverride;
        this._lateralCOGOverride = lateralCOGOverride;
        this._gVMOverride = gVMOverride;
    }

    this._calculateKingpinToAxle1Rear = true;
    this._calculateHitchPointToAxle1Rear = true;

    this._kingpinHeight = kingpinHeight;
    this._frontOverhang = frontOverhang;
    this._kingpinToLandingLegs = kingpinToLandingLegs;
    this._kingpinToAxle1Rear = kingpinToAxle1Rear;
    this._rearOverhang = rearOverhang;
    this._hitchIncluded = hitchIncluded;
    this._hitchOffset = hitchOffset;
    this._hitchHeight = hitchHeight;
    this._frontOverhangBreakpoint = frontOverhangBreakpoint;
    this._frontOverhangMaxCut = frontOverhangMaxCut;
    this._tyrePriceRemainingSets = tyrePriceRemainingSets;
    this._licenceFees = licenceFees || 0;
    this._licenceFeesOverride = licenceFeesOverride || false;
    this._licenceFeesRegionId = licenceFeesRegionId || 1;
    //note these are used for standard trailers coming from the AppData or for standard trailers in the user's personal library after a TIM user does a 'copy to personal'. For OFFER_TRAILER payload info is stored in OFFER_PAYLOAD which also has these attributes. 
    this._payloadVerticalCOGType = payloadVerticalCOGType;
    this._payloadLateralCOGType = payloadLateralCOGType;
    //this._licenceFeesRegionId = 1;

    this._fuelAndAdblueTotalCostPerMonth = fuelAndAdblueTotalCostPerMonth; 
    this._fuelAndAdblueTotalCostPerMonthOverride = fuelAndAdblueTotalCostPerMonthOverride || false;
    this._fuelAndAdblueEquipmentFuelConsumption = fuelAndAdblueEquipmentFuelConsumption || 0;
    this._fuelAndAdblueEquipmentFuelCostPerVolume = fuelAndAdblueEquipmentFuelCostPerVolume || 0;
    this._fuelAndAdblueEquipmentFuelCostPerVolumeOverride = fuelAndAdblueEquipmentFuelCostPerVolumeOverride || false;
    this._fuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase = fuelAndAdblueEquipmentFuelCostPerVolume || 0;
    this._fuelAndAdblueEquipmentUtilisation = fuelAndAdblueEquipmentUtilisation || 0;
    

    this.getBodyFrontAxle = () => {
        var bodyFrontAxle = 0;
        var tempBody = this.getAccessoryHolder().getBody();

        if (tempBody !== null) {
            
            if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
                bodyFrontAxle = tempBody.getFrontAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle()), this.getWheelbaseTheoretical());
            } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
                bodyFrontAxle = tempBody.getFrontAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar()), this.getWheelbaseTheoretical());
            }
        }
        return bodyFrontAxle;
    }

    this.getBodyFrontLeftAxle = () => {
        let bodyFrontAxle = this.getBodyFrontAxle();
        
        return bodyFrontAxle - this.getBodyFrontRightAxle();
    }

    this.getBodyFrontRightAxle = () => {
        let bodyFrontAxle = this.getBodyFrontAxle();
        let bodyFrontRightAxle = 0;
        if (bodyFrontAxle > 0) {
            let trackWidth = this.getTrackWidth();
            var body = this._accessoryHolder.getBody();
            bodyFrontRightAxle = body.getFrontRightAxleLoad(bodyFrontAxle, this._accessoryHolder.getDistanceFromRightMostOfGivenTrackWidthForTrailer(body, trackWidth),
                                trackWidth);
        }
        return bodyFrontRightAxle;
    }

    this.getBodyRearAxle = () => {
        var bodyRearAxle = 0;

        var tempBody = this._accessoryHolder.getBody();
    
        if (tempBody !== null) {
            
            if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
                bodyRearAxle = tempBody.getRearAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle()), this.getWheelbaseTheoretical());
            } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
                bodyRearAxle = tempBody.getRearAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar()), this.getWheelbaseTheoretical());
            }
        }
        return bodyRearAxle
    }

    this.getBodyRearLeftAxle = () => {
        let bodyRearAxle = this.getBodyRearAxle();
        
        return bodyRearAxle - this.getBodyRearRightAxle();
    }

    this.getBodyRearRightAxle = () => {
        let bodyRearAxle = this.getBodyRearAxle();
        let bodyRearRightAxle = 0;
        if (bodyRearAxle > 0) {
            let trackWidth = this.getTrackWidth();
            var body = this._accessoryHolder.getBody();
            bodyRearRightAxle = body.getRearRightAxleLoad(bodyRearAxle, this._accessoryHolder.getDistanceFromRightMostOfGivenTrackWidthForTrailer(body, trackWidth),
                                trackWidth);
        }
        return bodyRearRightAxle;
    }
    


    // this.getBodyFrontAxle = getBodyFrontAxle;
    // this.getBodyRearAxle = getBodyRearAxle;
    // this.getBodyFrontLeftAxle = getBodyFrontLeftAxle;
    // this.getBodyFrontRightAxle = getBodyFrontRightAxle;
    // this.getBodyRearLeftAxle = getBodyRearLeftAxle;
    // this.getBodyRaerRightAxle = getBodyRearRightAxle;

    return this;
    
}
    //#endregion Private members
    
//#region Getters and setters
//Body.prototype.getBodyId = function() {
//    return this._bodyId;
//}
//Body.prototype.setBodyId = function(newValue) {
//    this._bodyId = newValue;
//}

Trailer.prototype.getType = function() {
    return this._type;
}
Trailer.prototype.setType = function(newValue) {
    this._type = newValue;
}
Trailer.prototype.getNumberOfAxlesFront = function () {
    return this._numberOfAxlesFront;
}
Trailer.prototype.setNumberOfAxlesFront = function (newValue) {
    this._numberOfAxlesFront = newValue;
}
Trailer.prototype.getNumberOfAxlesRear = function () {
    return this._numberOfAxlesRear;
}
Trailer.prototype.setNumberOfAxlesRear = function (newValue) {
    this._numberOfAxlesRear = newValue;
}
Trailer.prototype.getGVM = function () {
    return this._gvm;
}
Trailer.prototype.setGVM = function (newValue) {
    this._gvm = newValue;
}
Trailer.prototype.getGVMOverride = function () {
    return this._gVMOverride;
}
Trailer.prototype.setGVMOverride = function (newValue) {
    this._gVMOverride = newValue;
}
Trailer.prototype.getOverallWidth = function () {
    return this._overallWidth;
}
Trailer.prototype.setOverallWidth = function (newValue) {
    this._overallWidth = newValue;
}
Trailer.prototype.getChassisHeight = function () {
    return this._chassisHeight;
}
Trailer.prototype.setChassisHeight = function (newValue) {
    this._chassisHeight = newValue;
}
Trailer.prototype.getOverallHeight = function () {
    return this._overallHeight;
}
Trailer.prototype.setOverallHeight = function (newValue) {
    this._overallHeight = newValue;
}
Trailer.prototype.getFifthWheelOffset = function () {
    return this._fifthWheelOffset;
}
Trailer.prototype.setFifthWheelOffset = function (newValue) {
    this._fifthWheelOffset = newValue;
}
Trailer.prototype.getFifthWheelHeight = function () {
    return this._fifthWheelHeight;
}
Trailer.prototype.getFifthWheelHeightFromGround = function () {
    return this.getFifthWheelHeight();
}
Trailer.prototype.setFifthWheelHeight = function (newValue) {
    this._fifthWheelHeight = newValue;
}
Trailer.prototype.getDrawbarType = function () {
    return this._drawbarType;
}
Trailer.prototype.setDrawbarType = function (newValue) {
    this._drawbarType = newValue;
}
Trailer.prototype.getDrawbarLength = function () {
    return this._drawbarLength;
}
Trailer.prototype.setDrawbarLength = function (newValue) {
    this._drawbarLength = newValue;
}
Trailer.prototype.getDrawbarHeight = function () {
    return this._drawbarHeight;
}
Trailer.prototype.setDrawbarHeight = function (newValue) {
    this._drawbarHeight = newValue;
}
Trailer.prototype.getTurntableHeight = function () {
    return this._turntableHeight;
}
Trailer.prototype.setTurntableHeight = function (newValue) {
    this._turntableHeight = newValue;
}
Trailer.prototype.getTurntableLength = function () {
    return this.getFrontOverhang() + this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() + 500;
}
Trailer.prototype.getTyreRadius = function () {
    return this._tyreRadius;
}
Trailer.prototype.setTyreRadius = function (newValue) {
    this._tyreRadius = newValue;
}
Trailer.prototype.getHitchPointToLandingLegs = function () {
    return this._hitchPointToLandingLegs;
}
Trailer.prototype.setHitchPointToLandingLegs = function (newValue) {
    this._hitchPointToLandingLegs = newValue;
}
Trailer.prototype.getAxle1FrontToAxle2Front = function () {
    return this._axle1FrontToAxle2Front;
}
Trailer.prototype.setAxle1FrontToAxle2Front = function (newValue) {
    this._axle1FrontToAxle2Front = newValue;
}
Trailer.prototype.getAxle2FrontToAxle3Front = function () {
    return this._axle2FrontToAxle3Front;
}
Trailer.prototype.setAxle2FrontToAxle3Front = function (newValue) {
    this._axle2FrontToAxle3Front = newValue;
}
Trailer.prototype.getRearMostFrontAxleToAxle1Rear = function () {

    if (this.getNumberOfAxlesRear() === 4) {
        this._rearMostFrontAxleToAxle1Rear = this.getWheelbaseTheoretical() - 0.5 * this.getAxle2RearToAxle3Rear() - this.getAxle1RearToAxle2Rear() -
                                                (this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() - this.getAxle1FrontToWheelbaseTheoreticalStart());
    } else if (this.getNumberOfAxlesRear() === 3) {
        this._rearMostFrontAxleToAxle1Rear = this.getWheelbaseTheoretical() - this.getAxle1RearToAxle2Rear() -
                                                (this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() - this.getAxle1FrontToWheelbaseTheoreticalStart());
    } else if (this.getNumberOfAxlesRear() === 2) {
        this._rearMostFrontAxleToAxle1Rear = this.getWheelbaseTheoretical() - 0.5 * this.getAxle1RearToAxle2Rear() -
                                                (this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() - this.getAxle1FrontToWheelbaseTheoreticalStart())
    } else {
        this._rearMostFrontAxleToAxle1Rear = this.getWheelbaseTheoretical() - (this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() - this.getAxle1FrontToWheelbaseTheoreticalStart());
    }

    return this._rearMostFrontAxleToAxle1Rear;

    /*
    If NoOfAxlesRear = 1 Then
                SL version
                _RearMostFrontAxleToAxle1Rear = WheelbaseTheoretical

                VS version
                Wheelbase Theoretical - (Axle 1 Front to Axle 2 Front + Axle 2 Front to Axle 3 Front – Wheelbase Theoretical Start from Axle 1 Front)
            ElseIf NoOfAxlesRear = 2 Then
                _RearMostFrontAxleToAxle1Rear = WheelbaseTheoretical - 0.5 * Axle1RearToAxle2Rear -
                                                (Axle1FrontToAxle2Front + Axle2FrontToAxle3Front - Axle1FrontToWheelbaseTheoreticalStart)
            ElseIf NoOfAxlesRear = 3 Then
                _RearMostFrontAxleToAxle1Rear = WheelbaseTheoretical - Axle1RearToAxle2Rear -
                                                (Axle1FrontToAxle2Front + Axle2FrontToAxle3Front - Axle1FrontToWheelbaseTheoreticalStart)
            ElseIf NoOfAxlesRear = 4 Then
                _RearMostFrontAxleToAxle1Rear = WheelbaseTheoretical - 0.5 * Axle2RearToAxle3Rear - Axle1RearToAxle2Rear -
                                                (Axle1FrontToAxle2Front + Axle2FrontToAxle3Front - Axle1FrontToWheelbaseTheoreticalStart)
            End If
    */
}
Trailer.prototype.setRearMostFrontAxleToAxle1Rear = function (newValue) {
    this._rearMostFrontAxleToAxle1Rear = newValue;
}
Trailer.prototype.getHitchPointToAxle1Rear = function () {

    if (this._calculateHitchPointToAxle1Rear) {
        var noOfAxlesRear = this.getNumberOfAxlesRear();
        
        if (noOfAxlesRear === 1) {
            this._hitchPointToAxle1Rear = this.getWheelbaseTheoretical();
        } else if(noOfAxlesRear === 2) {
            this._hitchPointToAxle1Rear = this.getWheelbaseTheoretical() - this.getAxle1RearToAxle2Rear() / 2;
        } else if(noOfAxlesRear === 3) {
            this._hitchPointToAxle1Rear = this.getWheelbaseTheoretical() - this.getAxle1RearToAxle2Rear();
        } else if (noOfAxlesRear === 4) {
            this._hitchPointToAxle1Rear = this.getWheelbaseTheoretical() - 0.5 * this.getAxle2RearToAxle3Rear() - this.getAxle1RearToAxle2Rear();
        }
    }

    return this._hitchPointToAxle1Rear;
}
Trailer.prototype.setHitchPointToAxle1Rear = function (newValue) {
    this._hitchPointToAxle1Rear = newValue;
}
Trailer.prototype.getAxle1RearToAxle2Rear = function () {
    return this._axle1RearToAxle2Rear;
}
Trailer.prototype.setAxle1RearToAxle2Rear = function (newValue) {
    this._axle1RearToAxle2Rear = newValue;
}
Trailer.prototype.getAxle2RearToAxle3Rear = function () {
    return this._axle2RearToAxle3Rear;
}
Trailer.prototype.setAxle2RearToAxle3Rear = function (newValue) {
    this._axle2RearToAxle3Rear = newValue;
}
Trailer.prototype.getAxle3RearToAxle4Rear = function () {
    return this._axle3RearToAxle4Rear;
}
Trailer.prototype.setAxle3RearToAxle4Rear = function (newValue) {
    this._axle3RearToAxle4Rear = newValue;
}
Trailer.prototype.getAxle1FrontToWheelbaseTheoreticalStart = function () {
    return this._axle1FrontToWheelbaseTheoreticalStart;
}
Trailer.prototype.setAxle1FrontToWheelbaseTheoreticalStart = function (newValue) {
    this._axle1FrontToWheelbaseTheoreticalStart = newValue;
}
Trailer.prototype.getWheelbaseTheoretical = function () {
    return this._wheelbaseTheoretical;
}
Trailer.prototype.setWheelbaseTheoretical = function (newValue) {
    this._wheelbaseTheoretical = newValue;
}
Trailer.prototype.getFrontToStartOfTaper = function () {
    return this._frontToStartOfTaper;
}
Trailer.prototype.setFrontToStartOfTaper = function (newValue) {
    this._frontToStartOfTaper = newValue;
}
Trailer.prototype.getWheelbaseTheoreticalEndToStartOfTaper = function () {
    return (this._frontOverhang + this._axle1FrontToWheelbaseTheoreticalStart + this._wheelbaseTheoretical) - this._frontToStartOfTaper;
}
Trailer.prototype.getPayloadCOGType = function () {
    return this._payloadCOGType;
}
Trailer.prototype.setPayloadCOGType = function (newValue) {
    this._payloadCOGType = newValue;
}
Trailer.prototype.getPayloadVerticalCOGType = function () {
    return this._payloadVerticalCOGType;
}
Trailer.prototype.setPayloadVerticalCOGType = function (newValue) {
    this._payloadVerticalCOGType = newValue;
}
Trailer.prototype.getPayloadLateralCOGType = function () {
    return this._payloadLateralCOGType;
}
Trailer.prototype.setPayloadLateralCOGType = function (newValue) {
    this._payloadLateralCOGType = newValue;
}
Trailer.prototype.getPayloadHorizontalCOG = function () {
    return this._payloadHorizontalCOG;
}
Trailer.prototype.setPayloadHorizontalCOG = function (newValue) {
    this._payloadHorizontalCOG = newValue;
}
Trailer.prototype.getMaterialOfChassis = function () {
    return this._materialOfChassis;
}
Trailer.prototype.setMaterialOfChassis = function (newValue) {
    this._materialOfChassis = newValue;
}
Trailer.prototype.getTareType = function () {
    return this._tareType;
}
Trailer.prototype.setTareType = function (newValue) {
    this._tareType = newValue;
}
Trailer.prototype.getTareTotal = function () {
    if ((this._tareFrontOverride == true && this._tareRearOverride === true) || this._source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        return this._tareFront + this._tareRear;
    } else {
        if (this.getWheelbaseTheoretical() !== 0) {
            switch (this._tareType) {
                case config.TRAILER_TARE_TYPES.AUTO_CALCULATED:
                    if (this._type === config.TRAILER_TYPES.SEMI) {
                        this._tareTotal = 0.07 * this.getOverallLengthExclAccessories() + 1300 * this.getNumberOfAxlesRear();
                    } else if (this._type === config.TRAILER_TYPES.INTERLINK) {
                        this._tareTotal = 0.14 * this.getOverallLengthExclAccessories() + 1300 * this.getNumberOfAxlesRear();
                    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
                        this._tareTotal = 0.07 * this.getOverallLengthExclAccessoriesAndDrawbar() + 1300 * this.getNumberOfAxlesFront() + 1300 * this.getNumberOfAxlesRear();
                    } else if (this._type === config.TRAILER_TYPES.PUP) {
                        this._tareTotal = 0.07 * this.getOverallLengthExclAccessories() + 1300 * this.getNumberOfAxlesRear();
                    }
                    break;
                case config.TRAILER_TARE_TYPES.SPECIFY_CHASSIS_SPLITS:
                case config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS:
                    // do nothing, leave value as is
                    break;
                default:
                    this._tareTotal = 0;
                    break;
            }
        } else {
            this._tareTotal = 0;
        }
        return this._tareTotal;
    }
    

    
    /*
If _WheelbaseTheoretical <> 0 Then

            Select Case _TareType

                Case TrailerTareTypes.AutoCalculated

                    If TypeOf (Me) Is FileLoadingTrailerSemi Then
                        _TotalTare = 0.07 * OverallLengthExclAccessories + 1300 * _NoOfAxlesRear
                    ElseIf TypeOf (Me) Is FileLoadingTrailerInterlink Then
                        _TotalTare = 0.14 * OverallLengthExclAccessories + 1300 * _NoOfAxlesRear
                    ElseIf TypeOf (Me) Is FileLoadingTrailerDrawbar Then
                        Dim x As FileLoadingTrailerDrawbar = Me
                        _TotalTare = 0.07 * x.OverallLengthExclAccessoriesAndDrawbar + 1300 * _NoOfAxlesFront + 1300 * _NoOfAxlesRear
                    ElseIf TypeOf (Me) Is FileLoadingTrailerPup Then
                        _TotalTare = 0.07 * OverallLengthExclAccessories + 1300 * _NoOfAxlesRear
                    End If

                Case TrailerTareTypes.SpecifyChassisSplits, TrailerTareTypes.SpecifyCompleteTrailerSplits
                    _TotalTare = _FrontTare + _RearTare
                    Return _TotalTare

                Case Else
                    _TotalTare = 0

            End Select

        Else
            _TotalTare = 0
        End If

        Return _TotalTare
*/
}
Trailer.prototype.setTareTotal = function (newValue) {
    this._tareTotal = newValue;
}
Trailer.prototype.getTareFront = function () {
    if (this._tareFrontOverride === false  && this._source !== config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        if (this.getWheelbaseTheoretical() !== 0) {
            switch (this._tareType) {
                case config.TRAILER_TARE_TYPES.AUTO_CALCULATED:
                    this._tareFront = this.getTareTotal() - this.getTareRear();
                    break;
                case config.TRAILER_TARE_TYPES.SPECIFY_CHASSIS_SPLITS:
                case config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS:
                    // do nothing, leave value as is
                    break;
                default:
                    this._tareFront = 0;
                    break;
            }
        } else {
            this._tareFront = 0;
        }
    }
    
    return this._tareFront;
    /*
    Get
        Select Case _TareType

            Case TrailerTareTypes.AutoCalculated

                _FrontTare = TotalTare - RearTare

            Case TrailerTareTypes.SpecifyChassisSplits, _
                    TrailerTareTypes.SpecifyCompleteTrailerSplits

                'leave as is

            Case Else
                _FrontTare = 0

        End Select

        Return _FrontTare

    End Get
*/
}
Trailer.prototype.setTareFront = function (newValue) {
    this._tareFront = newValue;
}
Trailer.prototype.getTareFrontOverride = function () {
    return this._tareFrontOverride;
}
Trailer.prototype.setTareFrontOverride = function (newValue) {
    this._tareFrontOverride = newValue;
}
Trailer.prototype.getTareRear = function () {
    if (this._tareRearOverride === false  && this._source !== config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        if (this.getWheelbaseTheoretical() !== 0) {
            switch (this._tareType) {
                case config.TRAILER_TARE_TYPES.AUTO_CALCULATED:
                    if (this._type === config.TRAILER_TYPES.SEMI) {
                        this._tareRear = (0.07 * this.getOverallLengthExclAccessories()) * (this.getOverallLengthExclAccessories() / 2 - this.getFrontOverhang()) / this.getWheelbaseTheoretical() + (1300 * this.getNumberOfAxlesRear());
                    } else if (this._type === config.TRAILER_TYPES.INTERLINK) {
                        this._tareRear = (0.14 * this.getOverallLengthExclAccessories()) * (this.getOverallLengthExclAccessories() / 2 - this.getFrontOverhang()) / this.getWheelbaseTheoretical() + (1300 * this.getNumberOfAxlesRear());
                    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
                        this._tareRear = (0.07 * this.getOverallLengthExclAccessoriesAndDrawbar()) * (this.getOverallLengthExclAccessoriesAndDrawbar() / 2 - (this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart())) / this.getWheelbaseTheoretical() + (1300 * this.getNumberOfAxlesRear());
                    } else if (this._type === config.TRAILER_TYPES.PUP) {
                        this._tareRear = (0.07 * this.getOverallLengthExclAccessories()) * (this.getOverallLengthExclAccessories() / 2) / this.getWheelbaseTheoretical() + (1300 * this.getNumberOfAxlesRear());
                    }
                    break;
                case config.TRAILER_TARE_TYPES.SPECIFY_CHASSIS_SPLITS:
                case config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS:
                    // do nothing, leave value as is
                    break;
                default:
                    this._tareRear = 0;
                    break;
            }
        } else {
            this._tareRear = 0;
        }
    }
    
    return this._tareRear;
    /*
    If _WheelbaseTheoretical <> 0 Then

            Select Case _TareType

                Case TrailerTareTypes.AutoCalculated

                    If TypeOf (Me) Is FileLoadingTrailerSemi Then

                        Dim x As FileLoadingTrailerSemi = Me

                        _RearTare = (0.07 * OverallLengthExclAccessories) * _
                                    (OverallLengthExclAccessories / 2 - x.FrontOverhang) / _
                                    _WheelbaseTheoretical + (1300 * _NoOfAxlesRear)

                    ElseIf TypeOf (Me) Is FileLoadingTrailerInterlink Then

                        Dim x As FileLoadingTrailerInterlink = Me

                        _RearTare = (0.14 * OverallLengthExclAccessories) * _
                                    (OverallLengthExclAccessories / 2 - x.FrontOverhang) / _
                                    _WheelbaseTheoretical + (1300 * _NoOfAxlesRear)

                    ElseIf TypeOf (Me) Is FileLoadingTrailerDrawbar Then

                        Dim x As FileLoadingTrailerDrawbar = Me

                        _RearTare = (0.07 * x.OverallLengthExclAccessoriesAndDrawbar) * _
                                    (x.OverallLengthExclAccessoriesAndDrawbar / 2 - x.FrontOverhang) / _
                                    _WheelbaseTheoretical + (1300 * _NoOfAxlesRear)

                    ElseIf TypeOf (Me) Is FileLoadingTrailerPup Then

                        _RearTare = (0.07 * OverallLengthExclAccessories) * _
                                    (OverallLengthExclAccessories / 2) / _
                                    _WheelbaseTheoretical + (1300 * _NoOfAxlesRear)

                    End If

                Case TrailerTareTypes.SpecifyChassisSplits, _
                        TrailerTareTypes.SpecifyCompleteTrailerSplits

                    'leave as is

                Case Else
                    _RearTare = 0

            End Select

        Else
            _RearTare = 0
        End If

        Return _RearTare
    
    */
}
Trailer.prototype.setTareRear = function (newValue) {
    this._tareRear = newValue;
}
Trailer.prototype.getTareRearOverride = function () {
    return this._tareRearOverride;
}
Trailer.prototype.setTareRearOverride = function (newValue) {
    this._tareRearOverride = newValue;
}
Trailer.prototype.getManufacturersFrontAxlesMass = function () {
    return this._manufacturersFrontAxlesMass;
}
Trailer.prototype.setManufacturersFrontAxlesMass = function (newValue) {
    this._manufacturersFrontAxlesMass = newValue;
}
Trailer.prototype.getManufacturersRearAxlesMass = function () {
    return this._manufacturersRearAxlesMass;
}
Trailer.prototype.setManufacturersRearAxlesMass = function (newValue) {
    this._manufacturersRearAxlesMass = newValue;
}
Trailer.prototype.getManufacturersRearAxlesMassOverride = function () {
    return this._manufacturersRearAxlesMassOverride;
}
Trailer.prototype.setManufacturersRearAxlesMassOverride = function (newValue) {
    this._manufacturersRearAxlesMassOverride = newValue;
}
Trailer.prototype.getManufacturersFrontAxlesMassOverride = function () {
    return this._manufacturersFrontAxlesMassOverride;
}
Trailer.prototype.setManufacturersFrontAxlesMassOverride = function (newValue) {
    this._manufacturersFrontAxlesMassOverride = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle1Front = function () {
    return this._numberOfTyresOnAxle1Front;
}
Trailer.prototype.setNumberOfTyresOnAxle1Front = function (newValue) {
    this._numberOfTyresOnAxle1Front = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle2Front = function () {
    return this._numberOfTyresOnAxle2Front;
}
Trailer.prototype.setNumberOfTyresOnAxle2Front = function (newValue) {
    this._numberOfTyresOnAxle2Front = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle3Front = function () {
    return this._numberOfTyresOnAxle3Front;
}
Trailer.prototype.setNumberOfTyresOnAxle3Front = function (newValue) {
    this._numberOfTyresOnAxle3Front = newValue;
}
Trailer.prototype.getFrontRoadFriendlySuspension = function () {
    return this._frontRoadFriendlySuspension;
}
Trailer.prototype.setFrontRoadFriendlySuspension = function (newValue) {
    this._frontRoadFriendlySuspension = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle1Rear = function () {
    return this._numberOfTyresOnAxle1Rear;
}
Trailer.prototype.setNumberOfTyresOnAxle1Rear = function (newValue) {
    this._numberOfTyresOnAxle1Rear = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle2Rear = function () {
    return this._numberOfTyresOnAxle2Rear;
}
Trailer.prototype.setNumberOfTyresOnAxle2Rear = function (newValue) {
    this._numberOfTyresOnAxle2Rear = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle3Rear = function () {
    return this._numberOfTyresOnAxle3Rear;
}
Trailer.prototype.setNumberOfTyresOnAxle3Rear = function (newValue) {
    this._numberOfTyresOnAxle3Rear = newValue;
}
Trailer.prototype.getNumberOfTyresOnAxle4Rear = function () {
    return this._numberOfTyresOnAxle4Rear;
}
Trailer.prototype.setNumberOfTyresOnAxle4Rear = function (newValue) {
    this._numberOfTyresOnAxle4Rear = newValue;
}
Trailer.prototype.getRearRoadFriendlySuspension = function () {
    return this._rearRoadFriendlySuspension;
}
Trailer.prototype.setRearRoadFriendlySuspension = function (newValue) {
    this._rearRoadFriendlySuspension = newValue;
}
Trailer.prototype.getTyreWidth = function () {
    return this._tyreWidth;
}
Trailer.prototype.setTyreWidth = function (newValue) {
    this._tyreWidth = newValue;
}
Trailer.prototype.getStatus = function () {
    return this._status;
}
Trailer.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Trailer.prototype.getOverallHeightBreakpoint = function () {
    return this._overallHeightBreakpoint;
}
Trailer.prototype.setOverallHeightBreakpoint = function (newValue) {
    this._overallHeightBreakpoint = newValue;
}
Trailer.prototype.getOverallHeightMaxCut = function () {
    return this._overallHeightMaxCut;
}
Trailer.prototype.setOverallHeightMaxCut = function (newValue) {
    this._overallHeightMaxCut = newValue;
}
Trailer.prototype.getDrawbarProtrusionBreakpoint = function () {
    return this._drawbarProtrusionBreakpoint;
}
Trailer.prototype.setDrawbarProtrusionBreakpoint = function (newValue) {
    this._drawbarProtrusionBreakpoint = newValue;
}
Trailer.prototype.getDrawbarProtrusionMaxCut = function () {
    return this._drawbarProtrusionMaxCut;
}
Trailer.prototype.setDrawbarProtrusionMaxCut = function (newValue) {
    this._drawbarProtrusionMaxCut = newValue;
}
Trailer.prototype.getDrawbarProtrusionChangeIncrement = function () {
    return this._drawbarProtrusionChangeIncrement;
}
Trailer.prototype.setDrawbarProtrusionChangeIncrement = function (newValue) {
    this._drawbarProtrusionChangeIncrement = newValue;
}
Trailer.prototype.getWheelbaseTheoreticalBreakpoint = function () {
    return this._wheelbaseTheoreticalBreakpoint;
}
Trailer.prototype.setWheelbaseTheoreticalBreakpoint = function (newValue) {
    this._wheelbaseTheoreticalBreakpoint = newValue;
}
Trailer.prototype.getWheelbaseTheoreticalMaxCut = function () {
    return this._wheelbaseTheoreticalMaxCut;
}
Trailer.prototype.setWheelbaseTheoreticalMaxCut = function (newValue) {
    this._wheelbaseTheoreticalMaxCut = newValue;
}
Trailer.prototype.getTrailerLengthBreakpoint = function () {
    return this._trailerLengthBreakpoint;
}
Trailer.prototype.setTrailerLengthBreakpoint = function (newValue) {
    this._trailerLengthBreakpoint = newValue;
}
Trailer.prototype.getTrailerLengthMaxCut = function () {
    return this._trailerLengthMaxCut;
}
Trailer.prototype.setTrailerLengthMaxCut = function (newValue) {
    this._trailerLengthMaxCut = newValue;
}
Trailer.prototype.getBodyType = function () {
    return this._bodyType;
}
Trailer.prototype.setBodyType = function (newValue) {
    this._bodyType = newValue;
}
Trailer.prototype.getPermissibleFront = function () {
    return this._permissibleFront;
}
Trailer.prototype.setPermissibleFront = function (newValue) {
    this._permissibleFront = newValue;
}
Trailer.prototype.getPermissibleFrontOverride = function () {
    return this._permissibleFrontOverride;
}
Trailer.prototype.setPermissibleFrontOverride = function (newValue) {
    this._permissibleFrontOverride = newValue;
}
Trailer.prototype.getPermissibleRear = function () {
    return this._permissibleRear;
}
Trailer.prototype.setPermissibleRear = function (newValue) {
    this._permissibleRear = newValue;
}
Trailer.prototype.getPermissibleRearOverride = function () {
    return this._permissibleRearOverride;
}
Trailer.prototype.setPermissibleRearOverride = function (newValue) {
    this._permissibleRearOverride = newValue;
}
Trailer.prototype.getAccessoryHolder = function () {
    return this._accessoryHolder;
}
Trailer.prototype.getWheelbaseTheoreticalChangeIncrement = function () {
    return this._wheelbaseTheoreticalChangeIncrement;
}
Trailer.prototype.setWheelbaseTheoreticalChangeIncrement = function (newValue) {
    this._wheelbaseTheoreticalChangeIncrement = newValue;
}
Trailer.prototype.getLengthChangeIncrement = function () {
    return this._lengthChangeIncrement;
}
Trailer.prototype.setLengthChangeIncrement = function (newValue) {
    this._lengthChangeIncrement = newValue;
}
Trailer.prototype.getFrontOverhangChangeIncrement = function () {
    return this._frontOverhangChangeIncrement;
}
Trailer.prototype.setFrontOverhangChangeIncrement = function (newValue) {
    this._frontOverhangChangeIncrement = newValue;
}
Trailer.prototype.getOverallHeightChangeIncrement = function () {
    return this._overallHeightChangeIncrement;
}
Trailer.prototype.setOverallHeightChangeIncrement = function (newValue) {
    this._overallHeightChangeIncrement = newValue;
}
Trailer.prototype.getRigPosition = function () {
    return this._rigPosition;
}
Trailer.prototype.setRigPosition = function (newValue) {
    this._rigPosition = newValue;
}
Trailer.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Trailer.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Trailer.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Trailer.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Trailer.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Trailer.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Trailer.prototype.getPayloadVerticalCOG = function () {
    return this._payloadVerticalCOG;
}
Trailer.prototype.setPayloadVerticalCOG = function (newValue) {
    this._payloadVerticalCOG = newValue;
}
Trailer.prototype.getPayloadVerticalCOGOverride = function () {
    return this._payloadVerticalCOGOverride;
}
Trailer.prototype.setPayloadVerticalCOGOverride = function (newValue) {
    this._payloadVerticalCOGOverride = newValue;
}
Trailer.prototype.getPayloadVerticalCOGConfirmed = function () {
    return this._payloadVerticalCOGConfirmed;
}
Trailer.prototype.setPayloadVerticalCOGConfirmed = function (newValue) {
    this._payloadVerticalCOGConfirmed = newValue;
}
Trailer.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Trailer.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Trailer.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Trailer.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Trailer.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Trailer.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Trailer.prototype.getPayloadLateralCOG = function () {
    return this._payloadLateralCOG;
}
Trailer.prototype.setPayloadLateralCOG = function (newValue) {
    this._payloadLateralCOG = newValue;
}
Trailer.prototype.getPayloadLateralCOGOverride = function () {
    return this._payloadLateralCOGOverride;
}
Trailer.prototype.setPayloadLateralCOGOverride = function (newValue) {
    this._payloadLateralCOGOverride = newValue;
}
Trailer.prototype.getPayloadLateralCOGConfirmed = function () {
    return this._payloadLateralCOGConfirmed;
}
Trailer.prototype.setPayloadLateralCOGConfirmed = function (newValue) {
    this._payloadLateralCOGConfirmed = newValue;
}
Trailer.prototype.getTyrePriceRemainingSets = function () {
    return this._tyrePriceRemainingSets;
}
Trailer.prototype.setTyrePriceRemainingSets = function (newValue) {
    this._tyrePriceRemainingSets = newValue;
}
Trailer.prototype.getLicenceFees = function () {
    return this._licenceFees;
}
Trailer.prototype.setLicenceFees = function (newValue) {
    this._licenceFees = newValue;
}
Trailer.prototype.getLicenceFeesOverride = function () {
    return this._licenceFeesOverride;
}
Trailer.prototype.setLicenceFeesOverride = function (newValue) {
    this._licenceFeesOverride = newValue;
}
Trailer.prototype.getLicenceFeesRegionId = function () {
    return this._licenceFeesRegionId;
}
Trailer.prototype.setLicenceFeesRegionId = function (newValue) {
    this._licenceFeesRegionId = newValue;
}
//Trailer.prototype.get = function () {
//    return this._;
//}
//Trailer.prototype.set = function (newValue) {
//    this._ = newValue;
//}

Trailer.prototype.getFuelAndAdblueTotalCostPerMonth = function () {
    return this._fuelAndAdblueTotalCostPerMonth;
}
Trailer.prototype.setFuelAndAdblueTotalCostPerMonth = function (newValue) {
    this._fuelAndAdblueTotalCostPerMonth = newValue;
}
Trailer.prototype.getFuelAndAdblueTotalCostPerMonthOverride = function () {
    return this._fuelAndAdblueTotalCostPerMonthOverride;
}
Trailer.prototype.setFuelAndAdblueTotalCostPerMonthOverride = function (newValue) {
    this._fuelAndAdblueTotalCostPerMonthOverride = newValue;
}
Trailer.prototype.getFuelAndAdblueEquipmentFuelConsumption = function () {
    return this._fuelAndAdblueEquipmentFuelConsumption;
}
Trailer.prototype.setFuelAndAdblueEquipmentFuelConsumption = function (newValue) {
    this._fuelAndAdblueEquipmentFuelConsumption = newValue;
}
Trailer.prototype.getFuelAndAdblueEquipmentFuelCostPerVolume = function () {
    return this._fuelAndAdblueEquipmentFuelCostPerVolume;
}
Trailer.prototype.setFuelAndAdblueEquipmentFuelCostPerVolume = function (newValue) {
    this._fuelAndAdblueEquipmentFuelCostPerVolume = newValue;
}
Trailer.prototype.getFuelAndAdblueEquipmentFuelCostPerVolumeOverride = function () {
    return this._fuelAndAdblueEquipmentFuelCostPerVolumeOverride;
}
Trailer.prototype.setFuelAndAdblueEquipmentFuelCostPerVolumeOverride = function (newValue) {
    this._fuelAndAdblueEquipmentFuelCostPerVolumeOverride = newValue;
}
Trailer.prototype.getFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase = function () {
    return this._fuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase;
}
Trailer.prototype.getFuelAndAdblueEquipmentUtilisation = function () {
    return this._fuelAndAdblueEquipmentUtilisation;
}
Trailer.prototype.setFuelAndAdblueEquipmentUtilisation = function (newValue) {
    this._fuelAndAdblueEquipmentUtilisation = newValue;
}
//#endregion Getters and setters

Trailer.prototype.getWheelbaseTurning = function () {
    return this.getWheelbaseTheoretical();
}

Trailer.prototype.getUnladenTotal = function () {
    var bodyTotalAxle = 0, unladen;
    

    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        unladen = this.getTareTotal();
    } else {
        unladen = this.getTareFront() + this.getTareRear() + this.getBodyTotalAxle() + this._accessoryHolder.getTotalTotalAccessories();
    }

    return unladen;
}

Trailer.prototype.getUnladenTotalLeft = function () {
    return this.getUnladenFrontLeft() + this.getUnladenRearLeft();
}

Trailer.prototype.getUnladenTotalRight = function () {
    return this.getUnladenFrontRight() + this.getUnladenRearRight();
}
Trailer.prototype.getPayloadTotalLeft = function () {
    return this.getPayloadFrontLeft() + this.getPayloadRearLeft();
}

Trailer.prototype.getPayloadTotalRight = function () {
    return this.getPayloadFrontRight() + this.getPayloadRearRight();
}
Trailer.prototype.getGrossTotal = function () {
    return this.getUnladenTotal() + this.getPayloadTotal();
}
Trailer.prototype.getGrossFrontLeft = function() {
    return this.getUnladenFrontLeft() + this.getPayloadFrontLeft();
}
Trailer.prototype.getGrossFrontRight = function() {
    return this.getUnladenFrontRight() + this.getPayloadFrontRight();
}
Trailer.prototype.getGrossRearLeft = function() {
    return this.getUnladenRearLeft() + this.getPayloadRearLeft();
}
Trailer.prototype.getGrossRearRight = function() {
    return this.getUnladenRearRight() + this.getPayloadRearRight();
}
Trailer.prototype.getBodyTotalAxle = function() {
    var bodyTotalAxle = 0;
    if(this._accessoryHolder.getBody() !== null) {
        bodyTotalAxle = this._accessoryHolder.getBody().getTotalLoad();
    }
    return bodyTotalAxle;
}

Trailer.prototype.getUnladenFront = function () {
    
    // var bodyFrontAxle = 0;
    // var tempBody = this._accessoryHolder.getBody();

    // if (tempBody !== null) {
        
    //     if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
    //         bodyFrontAxle = tempBody.getFrontAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle()), this.getWheelbaseTheoretical());
    //     } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
    //         bodyFrontAxle = tempBody.getFrontAxleLoad(this._accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(tempBody, this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar()), this.getWheelbaseTheoretical());
    //     }
    // }

    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        return this.getTareFront();
    } else {
        if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
            return this.getTareFront() + this.getBodyFrontAxle() + this._accessoryHolder.getTrailerFrontAxleTotalAccessories(this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
        } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
            return this.getTareFront() + this.getBodyFrontAxle() + this._accessoryHolder.getTrailerFrontAxleTotalAccessories(this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
        }
        
    }
    return 0;


}



Trailer.prototype.getUnladenFrontLeft = function () {
   if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        return this.getTareFront()/2;
    } else {
        if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
            return this.getTareFront()/2 + this.getBodyFrontLeftAxle() + this._accessoryHolder.getTrailerFrontLeftAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
        } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
            return this.getTareFront()/2 + this.getBodyFrontLeftAxle() + this._accessoryHolder.getTrailerFrontLeftAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
        }
        
    }
    return 0;
}

Trailer.prototype.getUnladenFrontRight = function () {
    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
         return this.getTareFront()/2;
     } else {
         if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
             return this.getTareFront()/2 + this.getBodyFrontRightAxle() + this._accessoryHolder.getTrailerFrontRightAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
         } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
             return this.getTareFront()/2 + this.getBodyFrontRightAxle() + this._accessoryHolder.getTrailerFrontRightAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
         }
         
     }
     return 0;
 }

Trailer.prototype.getUnladenRear = function () {

    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        return this.getTareRear();
    } else {
        if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
            return this.getTareRear() + this.getBodyRearAxle() + this._accessoryHolder.getTrailerRearAxleTotalAccessories(this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
        } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
            return this.getTareRear() + this.getBodyRearAxle() + this._accessoryHolder.getTrailerRearAxleTotalAccessories(this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
        }
        
    }
    return 0;
    
}

Trailer.prototype.getUnladenRearLeft = function () {

    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        return this.getTareRear()/2;
    } else {
        if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
            return this.getTareRear()/2 + this.getBodyRearLeftAxle() + this._accessoryHolder.getTrailerRearLeftAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
        } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
            return this.getTareRear()/2 + this.getBodyRearLeftAxle() + this._accessoryHolder.getTrailerRearLeftAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
        }
        
    }
    return 0;
    
}

Trailer.prototype.getUnladenRearRight = function () {

    if (this._tareType === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
        return this.getTareRear()/2;
    } else {
        if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
            return this.getTareRear()/2 + this.getBodyRearRightAxle() + this._accessoryHolder.getTrailerRearRightAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToLastAxle(), this.getWheelbaseTheoretical());
        } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
            return this.getTareRear()/2 + this.getBodyRearRightAxle() + this._accessoryHolder.getTrailerRearRightAxleTotalAccessories(this.getTrackWidth(), this.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), this.getFrontOfTrailerToLastAxleExclDrawbar(), this.getWheelbaseTheoretical());
        }
        
    }
    return 0;
    
}

//Trailer.prototype.getFrontOfTrailerToLastAxle = function () {
//    return 0;
//}


Trailer.prototype.getPayloadTotal = function() {
    if (this._accessoryHolder.getBody() !== null || this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        return this._payloadHolder.getTotalLoad();
    } else {
        return 0;
    }
}


Trailer.prototype.getPayloadFront = function () {
    /*
    If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

            Return _Accessories.Body.PayloadObjects.FrontAxleLoad(FrontOfTrailerToWheelbaseTheoreticalEnd, _
                                                                    FrontOfTrailerToPayloadDeckStart, _
                                                                    WheelbaseTheoretical)

        ElseIf _Source = SourceTypes.Standard Then

            Return _PayloadObjects.FrontAxleLoad(FrontOfTrailerToWheelbaseTheoreticalEnd, _
                                                    FrontOfTrailerToPayloadDeckStart, _
                                                    WheelbaseTheoretical)

        Else
            Return 0
        End If
    */
    if (this._accessoryHolder.getBody() !== null || this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        return this._payloadHolder.getFrontAxleLoad(this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToPayloadDeckStart(), this.getWheelbaseTheoretical());
    } else {
        return 0;
    }
}

Trailer.prototype.getPayloadFrontLeft = function () {
    return this.getPayloadFront() - this.getPayloadFrontRight();
}
Trailer.prototype.getPayloadFrontRight = function() {
    
    let payloadFrontAxle = this.getPayloadFront();
    let payloadFrontRightAxle = 0;
    var body = this._accessoryHolder.getBody();
    let trackWidth = this.getTrackWidth();
    if (payloadFrontAxle > 0 && body) {
        let payloadToRightMostOfTrackWidth = (((body.getActualInternalWidth() - trackWidth)/2)) + trackWidth - this._payloadHolder.getAveragePayloadLateralCOG();
        payloadFrontRightAxle = this._payloadHolder.getFrontRightAxleLoad(payloadFrontAxle, payloadToRightMostOfTrackWidth, trackWidth);
    } else if(payloadFrontAxle > 0 && this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        let payloadToRightMostOfTrackWidth = ((this.getPayloadWidth() - trackWidth)/2) + trackWidth - this._payloadHolder.getAveragePayloadLateralCOG();
        payloadFrontRightAxle = this._payloadHolder.getFrontRightAxleLoad(payloadFrontAxle, payloadToRightMostOfTrackWidth, trackWidth);
    }
    return payloadFrontRightAxle;
}

Trailer.prototype.getPayloadRear = function () {
    /*
    If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

            Return _Accessories.Body.PayloadObjects.RearAxleLoad(FrontOfTrailerToWheelbaseTheoreticalEnd, _
                                                                    FrontOfTrailerToPayloadDeckStart, _
                                                                    WheelbaseTheoretical)

        ElseIf _Source = SourceTypes.Standard Then

            Return _PayloadObjects.RearAxleLoad(FrontOfTrailerToWheelbaseTheoreticalEnd, _
                                                FrontOfTrailerToPayloadDeckStart, _
                                                WheelbaseTheoretical)

        Else
            Return 0
        End If
    */
    if (this._accessoryHolder.getBody() !== null || this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        return this._payloadHolder.getRearAxleLoad(this.getFrontOfTrailerToWheelbaseTheoreticalEnd(), this.getFrontOfTrailerToPayloadDeckStart(), this.getWheelbaseTheoretical());
    } else {
        return 0;
    }
}

Trailer.prototype.getPayloadRearLeft = function () {
    return this.getPayloadRear() - this.getPayloadRearRight();
}
Trailer.prototype.getPayloadRearRight = function() {
    
    let payloadRearAxle = this.getPayloadRear();
    let payloadRearRightAxle = 0;
    var body = this._accessoryHolder.getBody();
    let trackWidth = this.getTrackWidth();
    if (payloadRearAxle > 0 && body) {
        let payloadToRightMostOfTrackWidth = (((body.getActualInternalWidth() - trackWidth)/2)) + trackWidth - this._payloadHolder.getAveragePayloadLateralCOG();
        payloadRearRightAxle = this._payloadHolder.getRearRightAxleLoad(payloadRearAxle, payloadToRightMostOfTrackWidth, trackWidth);
    } else if(payloadRearAxle > 0 && this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        let payloadToRightMostOfTrackWidth = ((this.getPayloadWidth() - trackWidth)/2) + trackWidth - this._payloadHolder.getAveragePayloadLateralCOG();
        payloadRearRightAxle = this._payloadHolder.getRearRightAxleLoad(payloadRearAxle, payloadToRightMostOfTrackWidth, trackWidth);
    }
    return payloadRearRightAxle;
}

Trailer.prototype.getOverallLengthExclAccessories = function () {
    
    return this._overallLengthExclAccessories;
    
    //return this.getDrawbarLength() + this.getFrontOverhang() + this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() + this.getRearMostFrontAxleAxleAxle1Rear() +
    //                            this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() +
    //                            this.getRearOverhang();
    
}

Trailer.prototype.getOverallLengthExclAccessoriesAndDrawbar = function () {
    return this.getOverallLengthExclAccessories() - this.getDrawbarLength();
}

Trailer.prototype.setOverallLengthExclAccessories = function (newValue) {
    this._overallLengthExclAccessories = newValue;
}

Trailer.prototype.getOverallLengthInclAccessories = function () {
    return this.getOverallLengthExclAccessories() + this.getPortionOfOverallLengthOverhangingFront() + this.getPortionOfOverallLengthOverhangingRear();
}

Trailer.prototype.getOverallLengthInclAccessoriesAndPayload = function (excludeDrawbar) {
    var maxLength;
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        maxLength = this.getOverallLengthInclAccessories();
        var maxPayloadLength = this.getPortionOfOverallLengthOverhangingFront() + this.getFrontOfTrailerToPayloadDeckStart() + this._payloadHolder.getMaxLengthOfPayloadObjects();
        return Math.max(maxLength, maxPayloadLength);
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        if (excludeDrawbar) {
            return this.getOverallLengthInclAccessoriesAndPayloadExclDrawbar();
        } else {
            maxLength = this.getOverallLengthInclAccessories();
            return Math.max(maxLength, this.getPortionOfOverallLengthOverhangingFront() +this.getFrontOfTrailerToPayloadDeckStart() +this._payloadHolder.getMaxLengthOfPayloadObjects());
        }
        
    }
    return 0;
}

Trailer.prototype.getOverallLengthInclAccessoriesAndPayloadExclDrawbar = function () {
    var maxLength;
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        maxLength = this.getOverallLengthInclAccessories();
        return Math.max(maxLength, this.getPortionOfOverallLengthOverhangingFront() + this.getFrontOfTrailerToPayloadDeckStart() + this._payloadHolder.getMaxLengthOfPayloadObjects());
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        maxLength = this.getOverallLengthInclAccessories() - this.getDrawbarLength();
        return Math.max(maxLength, this.getPortionOfOverallLengthOverhangingFront() + this.getFrontOfTrailerToPayloadDeckStart() + this._payloadHolder.getMaxLengthOfPayloadObjects() - this.getDrawbarLength());
    }
    return 0;
}

Trailer.prototype.getPortionOfOverallLengthOverhangingFront = function () {

    if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {

        if (this._accessoryHolder.getAccessoryLeftMostPoint(this.getDrawbarLength(), 0) < 0) {
            return Math.abs(this._accessoryHolder.getAccessoryLeftMostPoint(this.getDrawbarLength(), 0));
        } else {
            return 0;
        }
        //If Accessories.AccessoryLeftMostPoint(_DrawbarLength, 0) < 0 Then
        //Return Math.Abs(Accessories.AccessoryLeftMostPoint(_DrawbarLength, 0))
        //Else
        //Return 0
        //End If
    } else {
        if (this._accessoryHolder.getAccessoryLeftMostPoint(0, 0) < 0) {
            return Math.abs(this._accessoryHolder.getAccessoryLeftMostPoint(0, 0));
        } else {
            return 0;
        }
        //If _Accessories.AccessoryLeftMostPoint(BumperToBackOfCab, FrontOverhang) < 0 Then
        //Return Math.Abs(Accessories.AccessoryLeftMostPoint(BumperToBackOfCab, FrontOverhang))
        //Else
        //Return 0
        //End If
    }

    
    
    //return 0;
}
Trailer.prototype.getPortionOfOverallLengthOverhangingRear = function () {
    
    if (this._type === config.TRAILER_TYPES.DRAWBAR) {

        if (this._accessoryHolder.getAccessoryRightMostPoint(this.getDrawbarLength(), this.getFrontOverhang(), 0) > this.getOverallLengthExclAccessories()) {
            return this._accessoryHolder.getAccessoryRightMostPoint(this.getDrawbarLength(), this.getFrontOverhang(), 0) - this.getOverallLengthExclAccessories();
        } else {
            return 0;
        }
        //If Accessories.AccessoryRightMostPoint(_DrawbarLength, _FrontOverhang) > OverallLengthExclAccessories Then
        //Return Accessories.AccessoryRightMostPoint(_DrawbarLength, _FrontOverhang) - OverallLengthExclAccessories
        //Else
        //Return 0
        //End If
    } else if (this._type === config.TRAILER_TYPES.PUP) {

        if (this._accessoryHolder.getAccessoryRightMostPoint(this.getDrawbarLength(), 0, 0) > this.getOverallLengthExclAccessories()) {
            return this._accessoryHolder.getAccessoryRightMostPoint(this.getDrawbarLength(), 0, 0) - this.getOverallLengthExclAccessories();
        } else {
            return 0;
        }
        //If Accessories.AccessoryRightMostPoint(_DrawbarLength, 0) > OverallLengthExclAccessories Then
        //Return Accessories.AccessoryRightMostPoint(_DrawbarLength, 0) - OverallLengthExclAccessories
        //Else
        //Return 0
        //End If
    } else {
        if (this._accessoryHolder.getAccessoryRightMostPoint(0, 0, 0) > this.getOverallLengthExclAccessories()) {
            return this._accessoryHolder.getAccessoryRightMostPoint(0, 0, 0) - this.getOverallLengthExclAccessories();
        } else {
            return 0;
        }
        //If _Accessories.AccessoryRightMostPoint(BumperToBackOfCab, FrontOverhang) > OverallLengthExclAccessories Then
        //Return Accessories.AccessoryRightMostPoint(BumperToBackOfCab, FrontOverhang) - OverallLengthExclAccessories
        //Else
        //Return 0
        //End If
    }
    
}
Trailer.prototype.getFrontOfTrailerToRightMostPointOfPayloadDeck = function () {
    return Math.max(this._frontToStartOfTaper, this._accessoryHolder.getAccessoryRightMostPoint(0, this._frontOverhang));
}

Trailer.prototype.getPayloadCOGToWheelbaseTheoreticalEnd = function() {
    //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
    //Return PayloadDeckStartToWheelbaseTheoreticalEnd - _Accessories.Body.PayloadObjects.averagepayloadhorizontalcog
    //ElseIf _Source = SourceTypes.Standard Then
    //Return PayloadDeckStartToWheelbaseTheoreticalEnd - _PayloadObjects.averagepayloadhorizontalcog
    //Else
    //Return 0
    //End If
    if (this._accessoryHolder.getBody() !== null || this._source === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && this.getBodyType() !== '' && this.getBodyType() !== 'NONE')) {
        return this.getPayloadDeckStartToWheelbaseTheoreticalEnd() - this._payloadHolder.getAveragePayloadHorizontalCOG();
    } else {
        return 0;
    }

}
//Trailer.prototype.getPayloadDeckStartToWheelbaseTheoreticalEnd = function () {
//    return 0;
//}
Trailer.prototype.getLength = function () {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getOverallLengthExclAccessories();
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) { 
            return this.getOverallLengthExclAccessoriesAndDrawbar();
    }
    return 0;
}

Trailer.prototype.setLength = function (newValue) {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        this.setOverallLengthExclAccessories(newValue);
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        this.setOverallLengthExclAccessories(newValue + this.getDrawbarLength());
    }
}

Trailer.prototype.getTrailerTypeText = function () {
    return globals.getTrailerTypeText(this._type);
}

Trailer.prototype.getAxlesHolder = function () {
    return this._axlesHolder;
}

Trailer.prototype.getPayloadHolder = function () {
    return this._payloadHolder;
}

Trailer.prototype.getChassisSectionLabel = function (numAxlesBeforeTrailer, sectionName) {
    //numAxlesBeforeTrailer -= 1;
    switch (sectionName) {
        case config.VALUE_TYPE.TRAILERFRONTOVERHANG:
            if (this.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return config.getTranslationText('4027');
            }
            return config.getTranslationText('2908');
        case config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT:
            if (this._axlesHolder.getAxle(0, 2) !== null) {
                return (numAxlesBeforeTrailer + 1) + ' - ' + (numAxlesBeforeTrailer + 2);//'C - D';
            } else {
                return '';
            }
        case config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT:
            if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(0, 3) !== null) {
                return (numAxlesBeforeTrailer + 2) + ' - ' + (numAxlesBeforeTrailer + 3);//'D - E';
            } else {
                return '';
            }
        case config.VALUE_TYPE.TRAILERREARMOSTFRONTAXLETOAXLE1REAR:
            if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(0, 3) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - ' + (numAxlesBeforeTrailer + 4);//'E - F';
            } else if (this._axlesHolder.getAxle(0, 2) !== null) {
                return (numAxlesBeforeTrailer + 2) + ' - ' + (numAxlesBeforeTrailer + 3);//'D - E';
            }  else {
                return (numAxlesBeforeTrailer + 1) + ' - ' + (numAxlesBeforeTrailer + 2);//C - D;
            }
        case config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR:
            if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - ' + (numAxlesBeforeTrailer + 5);//'F - G';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - ' + (numAxlesBeforeTrailer + 4);//'E - F';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 2) + ' - ' + (numAxlesBeforeTrailer + 3);//'D - E';
            } else if (this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 1) + ' - ' + (numAxlesBeforeTrailer + 2);//'C - D';
            } else {
                return '';
            }
        case config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR:
            if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 5) + ' - ' + (numAxlesBeforeTrailer + 6);//'G - H';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - ' + (numAxlesBeforeTrailer + 5);//'F - G';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - ' + (numAxlesBeforeTrailer + 4);//'E - F';
            } else if (this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 2) + ' - ' + (numAxlesBeforeTrailer + 3);//'C - D';
            } else {
                return '';
            }
        case config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR:
            if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 6) + ' - ' + (numAxlesBeforeTrailer + 7);//'H - I';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 5) + ' - ' + (numAxlesBeforeTrailer + 6);//'G - H';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - ' + (numAxlesBeforeTrailer + 5);//'F - G';
            } else if (this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - ' + (numAxlesBeforeTrailer + 4);//'D - E';
            } else {
                return '';
            }
        case config.VALUE_TYPE.TRAILERREAROVERHANG:
            if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                //return String.fromCharCode(65 + numAxlesBeforeTrailer + 7) + ' - End';//'I - End';
                return (numAxlesBeforeTrailer + 7) + ' - End';//'I - End';
            } else if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 6) + ' - End';//'H - End';
            } else if (this._axlesHolder.getAxle(0, 3) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 5) + ' - End';//'G - End';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 6) + ' - End';//'G - End';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 5) + ' - End';//'F - End';
            } else if (this._axlesHolder.getAxle(0, 2) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - End';//'E - End';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 5) + ' - End';//'F - End';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - End';//'E - End';
            } else if (this._axlesHolder.getAxle(0, 1) !== null && this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - End';//'D - End';
            } else if (this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null && this._axlesHolder.getAxle(1, 4) !== null) {
                return (numAxlesBeforeTrailer + 4) + ' - End';//'E - End';
            } else if (this._axlesHolder.getAxle(1, 2) !== null && this._axlesHolder.getAxle(1, 3) !== null) {
                return (numAxlesBeforeTrailer + 3) + ' - End';//'D - End';
            } else if (this._axlesHolder.getAxle(1, 2) !== null) {
                return (numAxlesBeforeTrailer + 2) + ' - End';//return 'C - End';
            } else {
                return (numAxlesBeforeTrailer + 1) + ' - End';//'B - End';
            }
        default:
            return '';
    }
}

//Trailer.prototype.getWheelbaseTheoreticalEndToRearMostAxle = function () {
//    return 0;
//}

Trailer.prototype.getOverallHeightAtRearForBody = function () {
    var tempBody = this._accessoryHolder.getBody();
    
    if (tempBody !== null) {
        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            return this.getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getActualExternalHeight();
        } else {
            return this.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight();
        }
    } else {
        return this.getChassisHeight();
    }
}

Trailer.prototype.getOverallHeightAtRearForCrane = function () {
    if (this._accessoryHolder.getCrane() !== null) {
        return this.getChassisHeight() + this._accessoryHolder.getCrane().getSubframeHeight() + this._accessoryHolder.getCrane().getHeight() + this._accessoryHolder.getCrane().getAboveBelowChassis();
    } else {
        return this.getChassisHeight();
    }
}

Trailer.prototype.getOverallHeightAtRearForTaillift = function () {
    var tempTaillift = this._accessoryHolder.getTaillift();
    if (tempTaillift !== null) {
        var tempBody = this._accessoryHolder.getBody();
        return this.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getPlatformHeight();
    } else {
        return this.getChassisHeight();
    }
}

Trailer.prototype.getOverallHeightAtRearForOtherEquipment = function () {
    var self = this;
    var maxOtherHeight = this.getChassisHeight();
    this._accessoryHolder.getAccessories().forEach(function (accessory) {
        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
            maxOtherHeight = Math.max(maxOtherHeight, self.getChassisHeight() + accessory.getVerticalGap() + accessory.getHeight());
        }
    });

    return maxOtherHeight;
}

Trailer.prototype.getOverallHeightAtRearForHooklift = function () {
    var tempHooklift = this._accessoryHolder.getHooklift();
    if (tempHooklift !== null) {
        return this.getChassisHeight() + tempHooklift.getHeight() + tempHooklift.getVerticalGap();
    } else {
        return this.getChassisHeight();
    }
}

Trailer.prototype.getOverallHeightAtRearForPayload = function () {
    return this.getBottomOfTrailerToPayloadDeckStart() + this._payloadHolder.getMaxHeightOfPayloadObjects();
}

Trailer.prototype.getOverallHeightAtRear = function () {
    return Math.max(this.getOverallHeightAtRearExcludingPayload(), this.getOverallHeightAtRearForPayload());
}
Trailer.prototype.getOverallHeightAtRearExcludingPayload = function () {
    return Math.max(this.getOverallHeightAtRearForHooklift(), this._overallHeight, this.getOverallHeightAtRearForBody(), this.getOverallHeightAtRearForCrane(), this.getOverallHeightAtRearForTaillift(), this.getOverallHeightAtRearForOtherEquipment());
}
Trailer.prototype.deleteAccessory = function(accessoryId) {

    var accessoryToDelete = this._accessoryHolder.getAccessoryById(accessoryId);
    if (accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
        this._payloadHolder.setPayloadObjects([]);
    }

    this._accessoryHolder.removeAccessoryById(accessoryId);
}

Trailer.prototype.getPayloadWidth = function () {
    return this.getOverallWidth();
}
Trailer.prototype.getChassisWidth = function () {
    return this.getOverallWidth() - Math.max(Math.max(Math.max(this.getTyreWidth() * this.getNumberOfTyresOnAxle1Rear() + (this.getNumberOfTyresOnAxle1Rear() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM,
                                    this.getTyreWidth() * this.getNumberOfTyresOnAxle2Rear() + (this.getNumberOfTyresOnAxle2Rear() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM),
                                    this.getTyreWidth() * this.getNumberOfTyresOnAxle3Rear() + (this.getNumberOfTyresOnAxle3Rear() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM),
                                    this.getTyreWidth() * this.getNumberOfTyresOnAxle4Rear() + (this.getNumberOfTyresOnAxle4Rear() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM) -
                                    config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS;
}

Trailer.prototype.getTrackWidth = function () {
    let trackWidth = 9999999999;
    
    this._axlesHolder.getAxles().forEach((axle)=> {
        if(axle.getNoOfTyresOnAxle() === 2) {
            trackWidth = Math.min(trackWidth, this.getOverallWidth() - axle.getTyreWidth());
        }else {
            trackWidth = Math.min(trackWidth, this.getOverallWidth() - axle.getTyreWidth() * 2 - config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM);
        }
    });
    return trackWidth;
}

Trailer.prototype.getOverallWidthInclAccessories = function (topviewCentreLine, leftOfChassisRearWidth, leftOfBdoyWidthExternal) {

    var maxWidth = 0;

    maxWidth = Math.max(this.getOverallWidth(), this._accessoryHolder.getAccessoryWidth(topviewCentreLine, leftOfChassisRearWidth, leftOfBdoyWidthExternal));

    return maxWidth;
    /*
        Dim maxHeight As Double

        maxHeight = Math.Max(_OverallWidth, _Accessories.AccessoryWidth)

        Return maxHeight
    */
}

Trailer.prototype.getOverallWidthInclAccessoriesAndPayload = function (topviewCentreLine, leftOfChassisRearWidth, leftOfBodyWidthExternal) {

    var maxWidth = 0;
    let tempBody = this._accessoryHolder.getBody();
    let sideWallLeftThickness = 0;
    if(tempBody !== null) {
        sideWallLeftThickness = tempBody.getSidewallLeft();
    }
    maxWidth = Math.max(this.getOverallWidth(), this._accessoryHolder.getAccessoryWidth(topviewCentreLine, leftOfChassisRearWidth, leftOfBodyWidthExternal));
    maxWidth = Math.max(maxWidth, this._payloadHolder.getPayloadWidth(topviewCentreLine, leftOfBodyWidthExternal, sideWallLeftThickness));

    return maxWidth;
    /*
        Dim maxHeight As Double

        maxHeight = Math.Max(_OverallWidth, _Accessories.AccessoryWidth)

        Return maxHeight
    */
}

Trailer.prototype.getWheelbaseTurningToRearOfBody = function () {

    var tempBody = this._accessoryHolder.getBody();

    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        if (tempBody !== null) {
            return tempBody.getHorizontalGap() + tempBody.getActualLength() - (this.getFrontOfTrailerToPayloadDeckStart() + this.getPayloadDeckStartToWheelbaseTheoreticalEnd());
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            return this.getWheelbaseTheoreticalEndToRearMostAxle() + this.getRearOverhang();
        } else {
            return 0;
        }
        /*
    If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
            Return Accessories.Body.HorizontalGap + Accessories.Body.ActualLength - (FrontOfTrailerToPayloadDeckStart + PayloadDeckStartToWheelbaseTheoreticalEnd)
        ElseIf Source = SourceTypes.Standard Then
            Return WheelbaseTheoreticalEndToRearMostAxle + RearOverhang
        Else
            Return 0
        End If
    */
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        if (tempBody !== null) {
            return this.getDrawbarLength() + tempBody.getHorizontalGap() + tempBody.getActualLength() - (this.getFrontOfTrailerToPayloadDeckStart() + this.getPayloadDeckStartToWheelbaseTheoreticalEnd());
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            return this.getWheelbaseTheoreticalEndToRearMostAxle() + this.getRearOverhang();
        } else {
            return 0;
        }
        /*
        If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
            Return DrawbarLength + Accessories.Body.HorizontalGap + Accessories.Body.ActualLength - (FrontOfTrailerToPayloadDeckStart + PayloadDeckStartToWheelbaseTheoreticalEnd)
        ElseIf Source = SourceTypes.Standard Then
            Return WheelbaseTheoreticalEndToRearMostAxle + RearOverhang
        Else
            Return 0
        End If
        */
    }

    
    

    
}

Trailer.prototype.getWheelbaseTurningToRearOfTrailer = function () {
    return this.getWheelbaseTheoreticalEndToRearMostAxle() + this.getRearOverhang();
}

Trailer.prototype.getKingpinHeight = function () {
    return this._kingpinHeight;
}
Trailer.prototype.setKingpinHeight = function (newValue) {
    this._kingpinHeight = newValue;
}
Trailer.prototype.getFrontOverhang = function () {
    return this._frontOverhang;
}
Trailer.prototype.setFrontOverhang = function (newValue) {
    this._frontOverhang = newValue;
}
Trailer.prototype.getKingpinToLandingLegs = function () {
    return this._kingpinToLandingLegs;
}
Trailer.prototype.setKingpinToLandingLegs = function (newValue) {
    this._kingpinToLandingLegs = newValue;
}
Trailer.prototype.getKingpinToAxle1Rear = function () {
    if (this._calculateKingpinToAxle1Rear === true) {
        if (this.getNumberOfAxlesRear() === 1) {
            this._kingpinToAxle1Rear = this.getWheelbaseTheoretical();
        } else if (this.getNumberOfAxlesRear() === 2) {
            this._kingpinToAxle1Rear = this.getWheelbaseTheoretical() - 0.5 * this.getAxle1RearToAxle2Rear();
        } else if (this.getNumberOfAxlesRear() === 3) {
            this._kingpinToAxle1Rear = this.getWheelbaseTheoretical() - this.getAxle1RearToAxle2Rear();
        } else if (this.getNumberOfAxlesRear() === 4) {
            this._kingpinToAxle1Rear = this.getWheelbaseTheoretical() - 0.5 * this.getAxle2RearToAxle3Rear() - this.getAxle1RearToAxle2Rear();
        }
    }
    return this._kingpinToAxle1Rear;
}
Trailer.prototype.setKingpinToAxle1Rear = function (newValue) {
    this._kingpinToAxle1Rear = newValue;
}
Trailer.prototype.getKingpinToRearMostAxleSetting = function () {
    return this.getKingpinToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
}
Trailer.prototype.getDistanceFromAxle1RearToFirstDownAxle = function() {
    if (this._axlesHolder.getHasPusherAxleDown()) {
        var frontMostDownAxlePositionAtRear = this._axlesHolder.getFrontMostDownAxlePositionAtRear();

        switch (frontMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return 0;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return this.getAxle1RearToAxle2Rear();
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear();
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
        }
    } else {
        return 0;
    }
    return 0;
}
Trailer.prototype.getRearOverhang = function () {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getOverallLengthExclAccessories() - this.getFrontOverhang() - this.getKingpinToAxle1Rear() - this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear();
    }else if(this._type === config.TRAILER_TYPES.DRAWBAR) {
        return (this.getOverallLengthExclAccessoriesAndDrawbar() - this.getFrontOverhang() - this.getAxle1FrontToAxle2Front() - this.getAxle2FrontToAxle3Front() - this.getRearMostFrontAxleToAxle1Rear() -this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear());
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return (this.getOverallLengthExclAccessories() - this.getHitchPointToAxle1Rear() - this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear());
    }
    return 0;
    //return this._rearOverhang;
}
Trailer.prototype.setRearOverhang = function (newValue) {
    this._rearOverhang = newValue;
}
Trailer.prototype.getHitchIncluded = function () {
    return this._hitchIncluded;
}
Trailer.prototype.setHitchIncluded = function (newValue) {
    this._hitchIncluded = newValue;
}
Trailer.prototype.getHitchOffset = function () {
    return this._hitchOffset;
}
Trailer.prototype.setHitchOffset = function (newValue) {
    this._hitchOffset = newValue;
}
Trailer.prototype.getHitchHeight = function () {
    return this._hitchHeight;
}
Trailer.prototype.setHitchHeight = function (newValue) {
    this._hitchHeight = newValue;
}
Trailer.prototype.getFrontOverhangBreakpoint = function () {
    return this._frontOverhangBreakpoint;
}
Trailer.prototype.setFrontOverhangBreakpoint = function (newValue) {
    this._frontOverhangBreakpoint = newValue;
}
Trailer.prototype.getFrontOverhangMaxCut = function () {
    return this._frontOverhangMaxCut;
}
Trailer.prototype.setFrontOverhangMaxCut = function (newValue) {
    this._frontOverhangMaxCut = newValue;
}

//Trailer.prototype.getChassisHeight = function () {
//    return this.getKingpinHeight() + 100;
//}

//Trailer.prototype.get = function () {
//    return this._;
//}
//Trailer.prototype.set = function (newValue) {
//    this._ = newValue;
//}




Trailer.prototype.getAxleLastToChassisEnd = function () {
    //#unfinished, need to support pup
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getLength() - this.getFrontOverhang() - this.getKingpinToAxle1Rear() - this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear();
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getLength() - this.getFrontOverhang() - this.getAxle1FrontToAxle2Front() - this.getAxle2FrontToAxle3Front() - this.getRearMostFrontAxleToAxle1Rear() - this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getDrawbarLength() + this.getLength() - this.getHitchPointToAxle1Rear() - this.getAxle1RearToAxle2Rear() - this.getAxle2RearToAxle3Rear() - this.getAxle3RearToAxle4Rear();
    }
    return 0;
}

Trailer.prototype.setRearOverhangAndCalcOverallLength = function (rearOverhang) {
    
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        this.setOverallLengthExclAccessories(this.getFrontOverhang() + this.getKingpinToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() + rearOverhang);
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        this.setOverallLengthExclAccessories(this.getDrawbarLength() + this.getFrontOverhang() + this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() + this.getRearMostFrontAxleToAxle1Rear() +
                                    this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() + rearOverhang);
    }else if(this._type === config.TRAILER_TYPES.PUP) {
        
        this.setOverallLengthExclAccessories(this.getHitchPointToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() + rearOverhang);
        /*
        HitchPointToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear + _
                                    Axle3RearToAxle4Rear + rearOverhang
        */
    }
    
}

Trailer.prototype.getFrontAxleSpan = function () {
    let frontAxleSpan = this._axle1FrontToAxle2Front;
    if(this._axle2FrontToAxle3Front !== undefined) {
        frontAxleSpan += this._axle2FrontToAxle3Front;
    }
    return frontAxleSpan;
}

Trailer.prototype.getRearAxleSpan = function () {
    return this._axle1RearToAxle2Rear + this._axle2RearToAxle3Rear + this._axle3RearToAxle4Rear;
}

Trailer.prototype.getDistanceFromWheelbaseTheoreticalEnd = function (cogFromFront) {
    if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getWheelbaseTheoretical() - (cogFromFront - (this.getFrontOfTrailerToWheelbaseTheoreticalStart()));
    } else {
        return this.getWheelbaseTheoretical() - (cogFromFront - (this.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar()));
    }
}

Trailer.prototype.getDistanceFromWheelbaseTheoreticalStart = function (cogFromFront) {
    //return (getAxle1Front() + getAxle1FrontToWheelbaseTheoreticalStart()) - COG;
    return cogFromFront - (this.getFrontOfTrailerToWheelbaseTheoreticalStart());
}

Trailer.prototype.getFrontOfTrailerToWheelbaseTheoreticalEnd = function () {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getFrontOverhang() + this.getWheelbaseTheoretical();
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getDrawbarLength() + this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart() + this.getWheelbaseTheoretical();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getWheelbaseTheoretical();
    }
    return 0;
}

Trailer.prototype.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar = function () {
    if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart() + this.getWheelbaseTheoretical();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getWheelbaseTheoretical() - this.getDrawbarLength();
    }
    return 0;
}

Trailer.prototype.getFrontOfTrailerToWheelbaseTheoreticalStart = function () {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getFrontOverhang();
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getDrawbarLength() + this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return 0;
    }
    return 0;
}

Trailer.prototype.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar = function () {
    if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return -this.getDrawbarLength();
    }
    return this.getFrontOverhang();
}

Trailer.prototype.getFrontOfTrailerToLastAxle = function () {
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getFrontOverhang() + this.getKingpinToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
    }else if(this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getDrawbarLength() + this.getFrontOverhang() + this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() +
                this.getRearMostFrontAxleToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getHitchPointToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
    }
    return 0;
}

Trailer.prototype.getFrontOfTrailerToLastAxleExclDrawbar = function () {
    if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getFrontOverhang() + this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() +
                this.getRearMostFrontAxleToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getHitchPointToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() - this.getDrawbarLength();
    }
    return 0;
}
Trailer.prototype.getFrontOfChassisToPayloadDeckStart = function () {

    var tempBody = this._accessoryHolder.getBody();
    if (tempBody !== null) {
        return tempBody.getHorizontalGap() + tempBody.getHeadboardThickness();
    } else {
        return 0;
    }
    
}
Trailer.prototype.getFrontOfTrailerToPayloadDeckStart = function () {
    
    var tempBody = this._accessoryHolder.getBody();
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        if (tempBody !== null) {
            return this._accessoryHolder.getBodyGap() + tempBody.getHeadboardThickness();
        } else {
            return 0;
        }
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        if (tempBody !== null) {
            return this.getDrawbarLength() + this._accessoryHolder.getBodyGap() + tempBody.getHeadboardThickness();
        } else {
            return this.getDrawbarLength();
        }
    }
    return 0;
}
Trailer.prototype.getFrontOfTrailerToPayloadDeckStartExclDrawbar = function () {

    var tempBody = this._accessoryHolder.getBody();
    if (tempBody !== null) {
        return this._accessoryHolder.getBodyGap() + tempBody.getHeadboardThickness();
    } else {
        return 0;
    }
    
}
Trailer.prototype.getFrontToPayloadDeckStart = function () {
    return this.getFrontOfTrailerToPayloadDeckStart();
}
Trailer.prototype.getBottomOfTrailerToPayloadDeckStart = function () {
    var hookliftEquipmentVerticalAddition;
    var tempHooklift;
    var tempBody = this._accessoryHolder.getBody();
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        if (tempBody !== null) {
            //if (tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER) {
            //    return this.getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis();
            //} else {
            //    return this.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
            //}
            hookliftEquipmentVerticalAddition = 0;
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                tempHooklift = this._accessoryHolder.getHooklift();
                hookliftEquipmentVerticalAddition = tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
            }
            return this.getChassisHeight() + hookliftEquipmentVerticalAddition + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
            return this.getChassisHeight();
        } else {
            return 0;
        }
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        if (tempBody !== null) {
            if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                return this.getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis();
            } else {
                hookliftEquipmentVerticalAddition = 0;
                if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    tempHooklift = this._accessoryHolder.getHooklift();
                    hookliftEquipmentVerticalAddition = tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
                }
                return this.getChassisHeight() + hookliftEquipmentVerticalAddition + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
            }
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
            return this.getChassisHeight();
        } else {
            return 0;
        }
    }

    return 0;

}
Trailer.prototype.getBottomToPayloadDeckStart = function () {
    return this.getBottomOfTrailerToPayloadDeckStart();
}
Trailer.prototype.getLeftOfTrailerToPayloadDeckStart = function () {

    var tempBody = this._accessoryHolder.getBody();

    if (tempBody !== null) {
        return tempBody.getSidewallLeft();
    } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        return 0;
    } else {
        return 0;
    }
}
Trailer.prototype.getLeftToPayloadDeckStart = function () {
    return this.getLeftOfTrailerToPayloadDeckStart();
}

Trailer.prototype.getWheelbaseTheoreticalEndToRearMostAxle = function () {
    
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        return this._frontOverhang + this._kingpinToAxle1Rear + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() - (this._frontOverhang + this.getWheelbaseTheoretical());
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getAxle1FrontToAxle2Front() + this.getAxle2FrontToAxle3Front() + this.getRearMostFrontAxleToAxle1Rear() +
                this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() -
                (this.getAxle1FrontToWheelbaseTheoreticalStart() + this.getWheelbaseTheoretical());
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getHitchPointToAxle1Rear() + this.getAxle1RearToAxle2Rear() + this.getAxle2RearToAxle3Rear() + this.getAxle3RearToAxle4Rear() - this.getWheelbaseTheoretical();
    }
    return 0;

}
Trailer.prototype.getPayloadDeckStartToWheelbaseTheoreticalEnd = function () {
    /*
    If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
            Return FrontOfTrailerToWheelbaseTheoreticalEnd - _
                    (Accessories.Body.HorizontalGap + _
                    Accessories.Body.HeadboardThickness)
        ElseIf Source = SourceTypes.Standard Then
            Return FrontOfTrailerToWheelbaseTheoreticalEnd
        Else
            Return 0
        End If
    */
    var tempBody = this.getAccessoryHolder().getBody();
    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        if (tempBody !== null) {
            return this.getFrontOfTrailerToWheelbaseTheoreticalEnd() - (tempBody.getHorizontalGap() + tempBody.getHeadboardThickness());
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
            return this.getFrontOfTrailerToWheelbaseTheoreticalEnd();
        } else {
            return 0;
        }
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR || this._type === config.TRAILER_TYPES.PUP) {
        if (tempBody !== null) {
            return this.getFrontOfTrailerToWheelbaseTheoreticalEnd() - (tempBody.getHorizontalGap() + tempBody.getHeadboardThickness()) - this.getDrawbarLength();
        } else if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
            return this.getFrontOfTrailerToWheelbaseTheoreticalEnd() - this.getDrawbarLength();
        } else {
            return 0;
        }
    }
    
}

Trailer.prototype.getFrontToWheelbaseTheoreticalEnd = function () {
    return this.getFrontOfTrailerToWheelbaseTheoreticalEnd();
}

Trailer.prototype.getFrontSwingClearanceRadius = function () {
    var swingRadius = 0;

    if (this._type === config.TRAILER_TYPES.SEMI || this._type === config.TRAILER_TYPES.INTERLINK) {
        if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            swingRadius = Math.sqrt(Math.pow(this._frontOverhang, 2) + Math.pow((this.getPayloadWidth() / 2), 2));
        } else {
            swingRadius = Math.sqrt(Math.pow(this.getFrontOverhang(), 2) + Math.pow((this.getChassisWidth() / 2), 2));

            if (this.getAccessoryHolder().getBody() !== null) {
                swingRadius = Math.max(Math.sqrt(Math.pow(this.getFrontOverhang() - this.getAccessoryHolder().getBody().getHorizontalGap(), 2) + Math.pow((this.getAccessoryHolder().getBody().getActualWidth() / 2), 2)), swingRadius);
            }
        }

        /*
        If Source = SourceTypes.Standard Then
            swingRadius = Math.Sqrt(FrontOverhang ^ 2 + (PayloadWidth / 2) ^ 2)
        Else

            swingRadius = Math.Sqrt(FrontOverhang ^ 2 + (ChassisWidth / 2) ^ 2)

            If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                swingRadius = Math.Max(Math.Sqrt((FrontOverhang - Accessories.Body.HorizontalGap) ^ 2 + (Accessories.Body.ActualWidth / 2) ^ 2), swingRadius)
            End If

        End If
        */
    } else if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        if (this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            swingRadius = Math.sqrt(Math.pow(this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart(), 2) + Math.pow((this.getPayloadWidth() / 2), 2));
        } else {
            swingRadius = Math.sqrt(Math.pow(this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart(), 2) + Math.pow((this.getChassisWidth() / 2), 2));

            if (this.getAccessoryHolder().getBody() !== null) {
                swingRadius = Math.max(Math.sqrt(Math.pow(this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart() - this.getAccessoryHolder().getBody().getHorizontalGap(), 2) + Math.pow((this.getAccessoryHolder().getBody().getActualWidth() / 2), 2)), swingRadius);
            }
        }
        /*
        If Source = SourceTypes.Standard Then
            swingRadius = Math.Sqrt((FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart) ^ 2 + (PayloadWidth / 2) ^ 2)
        Else

            swingRadius = Math.Sqrt((FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart) ^ 2 + (ChassisWidth / 2) ^ 2)

            If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                swingRadius = Math.Max(Math.Sqrt((FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart - Accessories.Body.HorizontalGap) ^ 2 + (Accessories.Body.ActualWidth / 2) ^ 2), swingRadius)
            End If

        End If
        */
    }

    return swingRadius;
    
}
Trailer.prototype.getPayloadLength = function () {
    if (this._type === config.TRAILER_TYPES.DRAWBAR) {
        return this.getFrontOverhang() + this.getAxle1FrontToWheelbaseTheoreticalStart() + this.getWheelbaseTheoretical() +
                this.getWheelbaseTheoreticalEndToRearMostAxle() + this.getRearOverhang();
    } else if (this._type === config.TRAILER_TYPES.PUP) {
        return this.getOverallLengthExclAccessoriesAndDrawbar();
    } else if (this._type === config.TRAILER_TYPES.SEMI) {
        return this.getOverallLengthExclAccessories();
    } else if (this._type === config.TRAILER_TYPES.INTERLINK) {
        return this.getFrontToStartOfTaper();
    }
    return 0;
    
}

Trailer.prototype.getWheelbaseTheoreticalEndToRearOfTrailer = function () {
    return this.getWheelbaseTheoreticalEndToRearMostAxle() + this.getRearOverhang();
}

Trailer.prototype.getArticulationPointToRearOfTrailer = function () {
    switch (this._type) {
        case config.TRAILER_TYPES.SEMI:
        case config.TRAILER_TYPES.INTERLINK:
            return this.getOverallLengthInclAccessories() - this.getFrontOverhang();
        case config.TRAILER_TYPES.DRAWBAR:
            return this.getOverallLengthInclAccessories() - this.getDrawbarLength() - this.getFrontOverhang() - this.getAxle1FrontToWheelbaseTheoreticalStart();
        case config.TRAILER_TYPES.PUP:
            return 0;// no articulation point for pup
            
    }
    return 0;
    //#unfinished, not sure if PUP support is correct here
}


Trailer.prototype.getTrailerPayloadCOGMM = function() {
    if(this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
        return this.getPayloadHolder().getAveragePayloadHorizontalCOG();
    } else {
        let tempBody = this.getAccessoryHolder().getBody();
        if(tempBody !== null) {
            return this.getPayloadHolder().getAveragePayloadHorizontalCOG();
        }
    }
    return 0;
}

Trailer.prototype.getTrailerPayloadCOGPercent = function() {
    if(this.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (this.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(this))) {
        return this.getPayloadHolder().getAveragePayloadHorizontalCOGPercent(this.getPayloadLength());
    } else {
        let tempBody = this.getAccessoryHolder().getBody();
        if(tempBody !== null) {
            return this.getPayloadHolder().getAveragePayloadHorizontalCOGPercent(tempBody.getActualInternalLength());
        }
    }
    return 0;
}
//#endregion Getters and setters

Trailer.prototype.clone = function (keepSameId) {

    var cloneId;
    if (keepSameId && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
}

    var newTrailer = new Trailer(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSourceDatabaseId(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._type,
        this._numberOfAxlesFront,
        this._numberOfAxlesRear,
        this._gvm,
        this._overallWidth,
        this._kingpinHeight,
        this._chassisHeight,
        this._overallHeight,
        this._fifthWheelOffset,
        this._fifthWheelHeight,
        this._drawbarType,
        this._drawbarLength,
        this._drawbarHeight,
        this._turntableHeight,
        this._tyreRadius,
        this._frontOverhang,
        this._kingpinToLandingLegs,
        this._hitchPointToLandingLegs,
        this._axle1FrontToAxle2Front,
        this._axle2FrontToAxle3Front,
        this._rearMostFrontAxleToAxle1Rear,
        this._hitchPointToAxle1Rear,
        this._kingpinToAxle1Rear,
        this._axle1RearToAxle2Rear,
        this._axle2RearToAxle3Rear,
        this._axle3RearToAxle4Rear,
        this._rearOverhang,
        this._axle1FrontToWheelbaseTheoreticalStart,
        this._wheelbaseTheoretical,
        this._frontToStartOfTaper,
        this._payloadCOGType,
        this._payloadHorizontalCOG,
        this._materialOfChassis,
        this._tareType,
        this._tareTotal,
        this._tareFront,
        this._tareFrontOverride,
        this._tareRear,
        this._tareRearOverride,
        this._manufacturersFrontAxlesMass,
        this._manufacturersRearAxlesMass,
        this._numberOfTyresOnAxle1Front,
        this._numberOfTyresOnAxle2Front,
        this._numberOfTyresOnAxle3Front,
        this._frontRoadFriendlySuspension,
        this._numberOfTyresOnAxle1Rear,
        this._numberOfTyresOnAxle2Rear,
        this._numberOfTyresOnAxle3Rear,
        this._numberOfTyresOnAxle4Rear,
        this._rearRoadFriendlySuspension,
        this._hitchIncluded,
        this._hitchOffset,
        this._hitchHeight,
        this._tyreWidth,
        this._status,
        this._overallHeightBreakpoint,
        this._overallHeightMaxCut,
        this._drawbarProtrusionBreakpoint,
        this._drawbarProtrusionMaxCut,
        this._frontOverhangBreakpoint,
        this._frontOverhangMaxCut,
        this._wheelbaseTheoreticalBreakpoint,
        this._wheelbaseTheoreticalMaxCut,
        this._trailerLengthBreakpoint,
        this._trailerLengthMaxCut,
        this._bodyType,
        this.getAxlesHolder().getAxleSaveObjects(),
        this._permissibleFront,
        this._permissibleFrontOverride,
        this._permissibleRear,
        this._permissibleRearOverride,
        this._wheelbaseTheoreticalChangeIncrement,
        this._lengthChangeIncrement,
        this._frontOverhangChangeIncrement,
        this._overallHeightChangeIncrement,
        this._drawbarProtrusionChangeIncrement,
        this._rigPosition,
        this._manufacturersFrontAxlesMassOverride,
        this._manufacturersRearAxlesMassOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._verticalCOGConfirmed,
        this._payloadVerticalCOG,
        this._payloadVerticalCOGOverride,
        this._payloadVerticalCOGConfirmed,
        this._payloadHolder.getPayloadType(),
        this._axlesHolder.getFrontAxleAverageTyreLife(),
        this._axlesHolder.getRearAxleAverageTyreLife(),
        this._lateralCOG,
        this._lateralCOGOverride,
        this._lateralCOGConfirmed,
        this._payloadLateralCOG,
        this._payloadLateralCOGOverride,
        this._payloadLateralCOGConfirmed,
        this._tyrePriceRemainingSets,
        this._licenceFees,
        this._licenceFeesOverride,
        this._licenceFeesRegionId,
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType(),
        this._payloadVerticalCOGType,
        this._payloadLateralCOGType,
        this._fuelAndAdblueTotalCostPerMonth,
        this._fuelAndAdblueTotalCostPerMonthOverride, 
        this._fuelAndAdblueEquipmentFuelConsumption,
        this._fuelAndAdblueEquipmentFuelCostPerVolume,
        this._fuelAndAdblueEquipmentFuelCostPerVolumeOverride,
        this._fuelAndAdblueEquipmentUtilisation,
        this._gVMOverride
        );

    newTrailer.setChassisObjectName(this.getChassisObjectName());
    newTrailer.setRearOverhangAndCalcOverallLength(this.getRearOverhang());

    newTrailer.setAccessLevel(this.getAccessLevel());
    newTrailer.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newTrailer.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());

    return newTrailer;
}

/**
 * Get the trailer JSON object to be saved to database
 * @returns {object}
 */
Trailer.prototype.getTrailerObjectToBeSaved = function () {
    //return {
    //subClassDetails["Type"] = this._type,
    //subClassDetails["NumberOfAxlesFront"] = this._numberOfAxlesFront,
    //subClassDetails["NumberOfAxlesRear"] = this._numberOfAxlesRear,
    //subClassDetails["GVM"] = this._gvm,
    //subClassDetails["OverallWidth"] = this._overallWidth,
    //subClassDetails["ChassisHeight"] = this._chassisHeight,
    //subClassDetails["OverallHeight"] = this._overallHeight,
    //subClassDetails["FifthWheelOffset"] = this._fifthWheelOffset,
    //subClassDetails["FifthWheelHeight"] = this._fifthWheelHeight,
    //subClassDetails["DrawbarType"] = this._drawbarType,
    //subClassDetails["DrawbarLength"] = this._drawbarLength,
    //subClassDetails["DrawbarHeight"] = this._drawbarHeight,
    //subClassDetails["TurntableHeight"] = this._turntableHeight,
    //subClassDetails["TyreRadius"] = this._tyreRadius,
    //subClassDetails["HitchPointtoLandingLegs"] = this._hitchPointToLandingLegs,
    //subClassDetails["Axle1FrontToAxle2Front"] = this._axle1FrontToAxle2Front,
    //subClassDetails["Axle2FrontToAxle3Front"] = this._axle2FrontToAxle3Front,
    //subClassDetails["RearMostFrontAxleToAxle1Rear"] = this._rearMostFrontAxleToAxle1Rear,
    //subClassDetails["HitchPointToAxle1Rear"] = this._hitchPointToAxle1Rear,
    ////subClassDetails["KingpinToAxle1Rear"] = this._kingpinToAxle1Rear,
    //subClassDetails["Axle1RearToAxle2Rear"] = this._axle1RearToAxle2Rear,
    //subClassDetails["Axle2RearToAxle3Rear"] = this._axle2RearToAxle3Rear,
    //subClassDetails["Axle3RearToAxle4Rear"] = this._axle3RearToAxle4Rear,
    ////subClassDetails["RearOverhang"] = this._rearOverhang,
    //subClassDetails["Axle1FrontToWheelbaseTheoreticalStart"] = this._axle1FrontToWheelbaseTheoreticalStart,
    //subClassDetails["WheelbaseTheoretical"] = this._wheelbaseTheoretical,
    //subClassDetails["FrontToStartOfTaper"] = this._frontToStartOfTaper,
    //subClassDetails["PayloadCOGType"] = this._payloadCOGType,
    //subClassDetails["PayloadHorizontalCOG"] = this._payloadHorizontalCOG,
    //subClassDetails["MaterialOfChassis"] = this._materialOfChassis,
    //subClassDetails["TareType"] = this._tareType,
    //subClassDetails["TareTotal"] = this._tareTotal,
    //subClassDetails["TareFront"] = parseFloat((this._tareFront).toFixed(4)),
    //subClassDetails["TareFrontOverride"] = this._tareFrontOverride,
    //subClassDetails["TareRear"] = parseFloat((this._tareRear).toFixed(4)),
    //subClassDetails["TareRearOverride"] = this._tareRearOverride,
    //subClassDetails["ManufacturersFrontAxlesMass"] = this._manufacturersFrontAxlesMass,
    //subClassDetails["ManufacturersRearAxlesMass"] = this._manufacturersRearAxlesMass,
    //subClassDetails["NumberOfTyresOnAxle1Front"] = this._numberOfTyresOnAxle1Front,
    //subClassDetails["NumberOfTyresOnAxle2Front"] = this._numberOfTyresOnAxle2Front,
    //subClassDetails["NumberOfTyresOnAxle3Front"] = this._numberOfTyresOnAxle3Front,
    //subClassDetails["FrontRoadFriendlySuspension"] = this._frontRoadFriendlySuspension,
    //subClassDetails["NumberOfTyresOnAxle1Rear"] = this._numberOfTyresOnAxle1Rear,
    //subClassDetails["NumberOfTyresOnAxle2Rear"] = this._numberOfTyresOnAxle2Rear,
    //subClassDetails["NumberOfTyresOnAxle3Rear"] = this._numberOfTyresOnAxle3Rear,
    //subClassDetails["NumberOfTyresOnAxle4Rear"] = this._numberOfTyresOnAxle4Rear,
    //subClassDetails["RearRoadFriendlySuspension"] = this._rearRoadFriendlySuspension,
    //subClassDetails["TyreWidth"] = this._tyreWidth,
    //subClassDetails["Status"] = this._status,
    //subClassDetails["OverallHeightBreakpoint"] = this._overallHeightBreakpoint,
    //subClassDetails["OverallHeightMaxCut"] = this._overallHeightMaxCut,
    //subClassDetails["DrawbarProtrusionBreakpoint"] = this._drawbarProtrusionBreakpoint,
    //subClassDetails["DrawbarProtrusionMaxCut"] = this._drawbarProtrusionMaxCut,
    //subClassDetails["WheelbaseTheoreticalBreakpoint"] = this._wheelbaseTheoreticalBreakpoint,
    //subClassDetails["WheelbaseTheoreticalMaxCut"] = this._wheelbaseTheoreticalMaxCut,
    //subClassDetails["TrailerLengthBreakpoint"] = this._trailerLengthBreakpoint,
    //subClassDetails["TrailerLengthMaxCut"] = this._trailerLengthMaxCut,
    //subClassDetails["BodyType"] = this._bodyType,
    ////subClassDetails["Axles"] = this.getAxlesHolder().getAxleSaveObjects(),
    //subClassDetails["PermissibleRear"] = this._permissibleRear,
    //subClassDetails["PermissibleRearOverride"] = this._permissibleRearOverride,
    //subClassDetails["WheelbaseTheoreticalChangeIncrement"] = this._wheelbaseTheoreticalChangeIncrement,
    //subClassDetails["LengthChangeIncrement"] = this._lengthChangeIncrement,
    //subClassDetails["FrontOverhangChangeIncrement"] = this._frontOverhangChangeIncrement,
    //subClassDetails["OverallHeightChangeIncrement"] = this._overallHeightChangeIncrement,
    //subClassDetails["RigPosition"] = this._rigPosition,
    //subClassDetails["ManufacturersRearAxlesMassOverride"] = this._manufacturersRearAxlesMassOverride,
    //subClassDetails["VerticalCOG"] = this._verticalCOG,
    //subClassDetails["VerticalCOGOverride"] = this._verticalCOGOverride,
    //subClassDetails["PayloadType"] = this._payloadHolder.getPayloadType()
    ////};



    //return subClassDetails;

    return {
        "Type": this._type,
        "NumberOfAxlesFront": this._numberOfAxlesFront,
        "NumberOfAxlesRear": this._numberOfAxlesRear,
        "GVM": this._gvm,
        "OverallWidth": this._overallWidth,
        "ChassisHeight": this._chassisHeight,
        "OverallHeight": this._overallHeight,
        "FifthWheelOffset": this._fifthWheelOffset,
        "FifthWheelHeight": this._fifthWheelHeight,
        "DrawbarType": this._drawbarType,
        "DrawbarLength": this._drawbarLength,
        "DrawbarHeight": this._drawbarHeight,
        "TurntableHeight": this._turntableHeight,
        "TyreRadius": this._tyreRadius,
        "HitchPointtoLandingLegs": this._hitchPointToLandingLegs,
        "Axle1FrontToAxle2Front": this._axle1FrontToAxle2Front,
        "Axle2FrontToAxle3Front": this._axle2FrontToAxle3Front,
        "RearMostFrontAxleToAxle1Rear": this._rearMostFrontAxleToAxle1Rear,
        "HitchPointToAxle1Rear": this._hitchPointToAxle1Rear,
        "Axle1RearToAxle2Rear": this._axle1RearToAxle2Rear,
        "Axle2RearToAxle3Rear": this._axle2RearToAxle3Rear,
        "Axle3RearToAxle4Rear": this._axle3RearToAxle4Rear,
        "Axle1FrontToWheelbaseTheoreticalStart": this._axle1FrontToWheelbaseTheoreticalStart,
        "WheelbaseTheoretical": this._wheelbaseTheoretical,
        "FrontToStartOfTaper": this._frontToStartOfTaper,
        "PayloadCOGType": this._payloadCOGType,
        "PayloadHorizontalCOG": this._payloadHorizontalCOG,
        "MaterialOfChassis": this._materialOfChassis,
        "TareType": this._tareType,
        "TareTotal": this._tareTotal,
        "TareFront": parseFloat((this._tareFront).toFixed(4)),
        "TareFrontOverride": this._tareFrontOverride,
        "TareRear": parseFloat((this._tareRear).toFixed(4)),
        "TareRearOverride": this._tareRearOverride,
        "ManufacturersFrontAxlesMass": this._manufacturersFrontAxlesMass,
        "ManufacturersRearAxlesMass": this._manufacturersRearAxlesMass,
        "NumberOfTyresOnAxle1Front": this._numberOfTyresOnAxle1Front,
        "NumberOfTyresOnAxle2Front": this._numberOfTyresOnAxle2Front,
        "NumberOfTyresOnAxle3Front": this._numberOfTyresOnAxle3Front,
        "FrontRoadFriendlySuspension": this._frontRoadFriendlySuspension,
        "NumberOfTyresOnAxle1Rear": this._numberOfTyresOnAxle1Rear,
        "NumberOfTyresOnAxle2Rear": this._numberOfTyresOnAxle2Rear,
        "NumberOfTyresOnAxle3Rear": this._numberOfTyresOnAxle3Rear,
        "NumberOfTyresOnAxle4Rear": this._numberOfTyresOnAxle4Rear,
        "RearRoadFriendlySuspension": this._rearRoadFriendlySuspension,
        "TyreWidth": this._tyreWidth,
        "Status": this._status,
        "OverallHeightBreakpoint": this._overallHeightBreakpoint,
        "OverallHeightMaxCut": this._overallHeightMaxCut,
        "DrawbarProtrusionBreakpoint": this._drawbarProtrusionBreakpoint,
        "DrawbarProtrusionMaxCut": this._drawbarProtrusionMaxCut,
        "WheelbaseTheoreticalBreakpoint": this._wheelbaseTheoreticalBreakpoint,
        "WheelbaseTheoreticalMaxCut": this._wheelbaseTheoreticalMaxCut,
        "TrailerLengthBreakpoint": this._trailerLengthBreakpoint,
        "TrailerLengthMaxCut": this._trailerLengthMaxCut,
        "BodyType": this._bodyType,
        "PermissibleFront": this._permissibleFront,
        "PermissibleFrontOverride": this._permissibleFrontOverride,
        "PermissibleRear": this._permissibleRear,
        "PermissibleRearOverride": this._permissibleRearOverride,
        "WheelbaseTheoreticalChangeIncrement": this._wheelbaseTheoreticalChangeIncrement,
        "LengthChangeIncrement": this._lengthChangeIncrement,
        "FrontOverhangChangeIncrement": this._frontOverhangChangeIncrement,
        "OverallHeightChangeIncrement": this._overallHeightChangeIncrement,
        "DrawbarProtrusionChangeIncrement" : this._drawbarProtrusionChangeIncrement,
        "RigPosition": this._rigPosition,
        "ManufacturersFrontAxlesMassOverride": this._manufacturersFrontAxlesMassOverride,
        "ManufacturersRearAxlesMassOverride": this._manufacturersRearAxlesMassOverride,
        "VerticalCOG": parseFloat((this._verticalCOG).toFixed(4)),
        "VerticalCOGOverride": this._verticalCOGOverride,
        "PayloadType": this._payloadHolder.getPayloadType(),
        "KingpinHeight": this._kingpinHeight,
        "FrontOverhang": this._frontOverhang,
        "KingpinToLandingLegs": this._kingpinToLandingLegs,
        "KingpinToAxle1Rear": this._kingpinToAxle1Rear,
        "RearOverhang": this.getRearOverhang(),
        "HitchIncluded": this._hitchIncluded,
        "HitchOffset": this._hitchOffset,
        "HitchHeight": this._hitchHeight,
        "FrontOverhangBreakpoint": this._frontOverhangBreakpoint,
        "FrontOverhangMaxCut": this._frontOverhangMaxCut,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "PayloadLateralCOG": this._payloadLateralCOG,
        "PayloadLateralCOGOverride": this._payloadLateralCOGOverride,
        "PayloadVerticalCOG": this._payloadVerticalCOG,
        "LicenceFees": this._licenceFees,
        "LicenceFeesOverride": this._licenceFeesOverride,
        "LicenceFeesRegionId": this._licenceFeesRegionId,
        "PayloadVerticalCOGType": this._payloadVerticalCOGType,
        "PayloadLateralCOGType": this._payloadLateralCOGType,
        "FuelAndAdblueTotalCostPerMonth": this._fuelAndAdblueTotalCostPerMonth,
        "FuelAndAdblueTotalCostPerMonthOverride": this._fuelAndAdblueTotalCostPerMonthOverride, 
        "FuelAndAdblueEquipmentFuelConsumption": this._fuelAndAdblueEquipmentFuelConsumption,
        "FuelAndAdblueEquipmentFuelCostPerVolume": this._fuelAndAdblueEquipmentFuelCostPerVolume,
        "FuelAndAdblueEquipmentFuelCostPerVolumeOverride": this._fuelAndAdblueEquipmentFuelCostPerVolumeOverride,
        "FuelAndAdblueEquipmentUtilisation": this._fuelAndAdblueEquipmentUtilisation,
        "GVMOverride": this._gVMOverride
        // "FuelAndAdblueEquipmentUtilisation": this._fuelAndAdblueEquipmentUtilisation
    };
}

/**
 * Get JSON object to be saved to database
 * @returns {object}
*/
Trailer.prototype.returnObjectToBeSaved = function () {
    //return {
    //    "AccessoryType": this.getAccessoryType(),
    //    "AccessoryDetail": this.returnBodyObjectToBeSaved()
    //};
    return this.getSaveObject(this.getTrailerObjectToBeSaved());
}


utils.extend(Accessory, Trailer);
ChassisObjectMixin.call(Trailer.prototype);
    

export default Trailer;

