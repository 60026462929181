import config from '@/services/config';
import globals from '@/services/globals';


let integrationsSetup = false;

function setUpAvailableIntegrations() {
    let availableIntegrations = [];
    if (globals.user.displayNteaOptionsToUser()) {
        availableIntegrations.push(createObjectUsingType(config.INTEGRATION_TYPES.NTEA));
    }
    return availableIntegrations;

    function createObjectUsingType(typeToCreate) {
        switch (typeToCreate) {
            case config.INTEGRATION_TYPES.NTEA:
                return createNTEAObject();
            default:
                return createNTEAObject();
        }
    }

    function createNTEAObject() {
        var nteaObjectOnUser = globals.user.getNTEAIntegrationObject();

        function NTEAObject() {
            var self = this;
            self.Type = config.INTEGRATION_TYPES.NTEA;
            self.LogoImage = config.cdnAddress + config.nteaLogoLocation;
            self.LogoAltText = config.getTranslationText('4952'); // User Story 17565 Translations
            self.Attribute1 = nteaObjectOnUser !== null ? nteaObjectOnUser.Attribute1 : '';
            self.Attribute2 = nteaObjectOnUser !== null ? nteaObjectOnUser.Attribute2 : '';
            self.Password = '';
            self.OperationType = config.OPERATION_TYPES.DEFAULT;

            return this;
        }
        NTEAObject.prototype.getType = function () { return this.Type; };
        NTEAObject.prototype.getAttribute1 = function () { return this.Attribute1; };
        NTEAObject.prototype.setAttribute1 = function (value) { this.Attribute1 = value; };
        NTEAObject.prototype.getAttribute2 = function () { return this.Attribute2; };
        NTEAObject.prototype.setAttribute2 = function (value) { this.Attribute2 = value; };
        NTEAObject.prototype.getPassword = function () { return this.Password; };
        NTEAObject.prototype.setPassword = function (value) { this.Password = value; };
        NTEAObject.prototype.getAttribute1InputId = function () { return 'settings-' + this.Type + '-attribute1'; };
        NTEAObject.prototype.getAttribute2InputId = function () { return 'settings-' + this.Type + '-attribute2'; };
        NTEAObject.prototype.getPasswordInputId = function () { return 'settings-' + this.Type + '-attribute3'; };
        NTEAObject.prototype.getOperationType = function () { return this.OperationType; };
        NTEAObject.prototype.setOperationType = function (value) {
            this.OperationType = value;
        };

        NTEAObject.prototype.checkAllValuesFilledIn = function (validationType) {
            if(validationType === config.NTEA_USER_VALIDATION_TYPES.NTEA) {
                return this.Attribute2 !== '' && this.Password !== '';
            } else {
                return this.Attribute1 !== '' && this.Attribute2 !== '';
            }
            
        };

        return new NTEAObject();
    }

    function SettingsBaseIntegrationObject(initObject) {
        var self = this;
        self.password = '';

        self.getPassword = function () { return self.password; };
        self.setPassword = function (value) { self.password = value; };
    }

    function SettingsNTEAIntegrationObject(initNTEAObject) {
        SettingsBaseIntegrationObject.call(this, initNTEAObject);
        var self = this;

    }

    
}

function getAvailableIntegrations() {
    // if(availableIntegrations.length === 0) {
    //     return setUpAvailableIntegrations();
    // }
    // return [availableIntegrations];
    return setUpAvailableIntegrations();
}

export default {
    getAvailableIntegrations,
    setUpAvailableIntegrations
}