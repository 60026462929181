
var AxleLetter = function (xOffset, yOffset, letter, axlePosition, highlight) {

    var self = this;
    //constructor
    self.centre = { x: xOffset, y: yOffset };
    self.letter = letter;
    self.axlePosition = axlePosition;
    self.highlight = highlight || false;

    self.drawingGroupName = 'NOT_SET';
    self.setDrawingGroupName = function (newValue) {
        self.drawingGroupName = newValue;
    }

    self.setShowAsWarning = function (showAsWarning) {
        self.showAsWarning = showAsWarning;
    }

    self.setColour = function (colour) {
        self.colour = colour;
    }

    self.addMeta = function (metaInfo) {
        if(self.meta) {
            Object.keys(metaInfo).forEach(function (key) {
                self.meta[key] = metaInfo[key];
            });
        }else {
            self.meta = metaInfo;
        }

    }
};



export default AxleLetter;

